import { ServiceRequest } from "fhir"
import { Chip } from "primereact/chip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays, faPills } from "@fortawesome/pro-solid-svg-icons"
import { classNames } from "primereact/utils"

import { ListItem } from "commons"
import { getOrderDate } from "medication-requests"

const TaskOrderListItem = ({ serviceRequest, onClick }: Props) => {
  const orderMRs = serviceRequest.basedOn?.filter((ref) => ref.resourceType === "MedicationRequest")

  const head = (
    <>
      <p title="Invoice number">{`#${serviceRequest.identifier?.[0]?.value ?? "Unspecified number"}`}</p>
      <span title="Status">
        <Chip
          label={serviceRequest.status !== "revoked" ? serviceRequest.status : "cancelled"}
          className={classNames("ml-2 custom-chip", {
            "is-danger": serviceRequest.status === "revoked",
            "is-success": serviceRequest.status === "completed",
            "is-info": serviceRequest.status === "active",
            "is-warning": serviceRequest.status === "entered-in-error",
          })}
        />
      </span>
    </>
  )

  const details = (
    <>
      {(serviceRequest.occurrence?.dateTime || serviceRequest.authoredOn) && (
        <span title="Ocurrence" className="flex items-center">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {getOrderDate(serviceRequest)}
        </span>
      )}

      <span title="Medication Requests" className="flex items-center pl-2">
        <FontAwesomeIcon icon={faPills} className="mr-1.5 text-gray-400" />
        {orderMRs?.length ?? 0}
      </span>
    </>
  )

  return (
    <>
      <ListItem header={head} subHeader={details} onContentClick={onClick} />
    </>
  )
}

type Props = {
  serviceRequest: ServiceRequest
  onClick(): void
}

export { TaskOrderListItem }
