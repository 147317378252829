import { faTrashCan, faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Identifier } from "fhir"
import { format, parseISO } from "date-fns"

import { CardListItem } from "commons"
import { formatsByTypes } from "data"

const IdentifierListItem = ({ identifier: { period, value, system }, onForm, onDelete }: Props) => {
  const actionItems = onDelete
    ? {
        label: "Delete",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" />,
        command: onDelete,
      }
    : undefined

  const Head = (
    <div className="font-bold">
      <span title="Identifier">{value}</span>
    </div>
  )
  const Details = (
    <>
      {period && (
        <>
          <span title="Period">
            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-1" />
            {period.start && format(parseISO(period?.start), formatsByTypes.LONG_DATE)}
            {period.end && <> - {period?.end && format(parseISO(period?.end), formatsByTypes.LONG_DATE)}</>}
          </span>
          <span className="mx-1">|</span>
        </>
      )}

      <span title="System" className="whitespace-nowrap text-ellipsis overflow-hidden max-w-xs">
        {system}
      </span>
    </>
  )
  return <CardListItem contentHeader={Head} contentSubheader={Details} actionItem={onForm ? actionItems : {}} />
}

type Props = {
  identifier: Identifier
  onForm: boolean
  onDelete?(): void
}

export { IdentifierListItem }
