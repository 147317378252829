import { CarePlan, CarePlanActivityArray } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { plansQueryKeys } from "../query-keys"
import { CustomError } from "../../types"

const useUpdatePlanActivity = (patientId: string, onSuccess?: () => void, onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const completeFn = async ({ planId, activity }: { planId: string; activity: CarePlanActivityArray[] }) =>
    patch("CarePlan", planId, { activity } as CarePlan)

  const { mutate: updatePlanActivity, isPending } = useMutation({
    mutationFn: completeFn,
    onError: (error: CustomError, planId) => {
      displayNotificationError(registerErrorTrace(error, { planId }))
    },
    onSuccess: async (_, { planId }) => {
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.history(patientId, planId) })
      await queryClient.invalidateQueries({
        queryKey: plansQueryKeys.details(patientId, planId),
      })
      onSuccess?.()
    },
    onSettled,
  })

  return { updatePlanActivity, isUpdating: isPending }
}

export { useUpdatePlanActivity }
