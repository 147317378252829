import { classNames } from "primereact/utils"

const BodyPoint = ({
  x,
  y,
  code,
  selectedPoint,
  onSelectPoint,
  disabled = !onSelectPoint,
  showPointOrder = false,
}: Props) => {
  const pointIndex = selectedPoint?.findIndex((point) => point === code || point === `${x}:${y}`) ?? -1
  return (
    <g
      transform={`translate(${x} ${y})`}
      className={classNames("fill-gray-400", {
        "fill-red-500": pointIndex !== -1,
        "cursor-pointer hover:fill-red-400": !disabled,
      })}
      onClick={() => !disabled && onSelectPoint?.(code as string)}
      aria-label={pointIndex.toString()}
    >
      <rect x="-8" y="-8" width="16" height="16" fill="none" />

      {pointIndex !== -1 && showPointOrder ? (
        <text x="-8" y="8" className="fill-red-500 font-extrabold z-50">
          {pointIndex + 1}
        </text>
      ) : (
        <>
          <rect x="-7" y="1" width="14" height="4" />
          <rect x="-2" y="-4" width="4" height="14" />
        </>
      )}
    </g>
  )
}

type Props = {
  x: string
  y: string
  code?: string
  selectedPoint?: string[]
  onSelectPoint?(point: string): void
  disabled?: boolean
  showPointOrder?: boolean
}

export { BodyPoint }
