import { FC, useId } from "react"

import {
  ConfirmDialog,
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  useCrudReducer,
} from "commons"
import { ReplaceFormProvider } from "commons/context"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { Laboratory } from "../../laboratory/types"
import { useDeleteLab, useLabs, useUpdateLab } from "../hooks"
import { LabListItem } from "./LabListItem"
import { LabsForm } from "./LabsForm"
import { getInitialValues, sanitize, validationSchema } from "./validations"

const LabsContainer: FC = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { reset, add, edit, isNew, setDeleteIndex, showSlide, initialValue, deleteIndex } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { labs, isLoading, hasNextPage, fetchNextPage } = useLabs(patientId)
  const { updateLab } = useUpdateLab(reset, isNew)
  const { removeLab, isDeleting } = useDeleteLab(() => setDeleteIndex(undefined))

  const onSubmit = (lab: Laboratory) => {
    updateLab(sanitize(lab))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <ReplaceFormProvider>
      <DataContainerSlideoverForm
        hasData={!!labs?.length}
        showSlide={showSlide}
        formTitle="Lab"
        formInitialValue={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onCancel={reset}
        form={<LabsForm patient={patientRef} encounter={openEncounterRef} />}
        onButtonAddClick={add}
        iconDataNotFound={appSubModules["intake"][ModulesId.LABS].getIcon()}
      >
        <div className="bg-white h-full overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <ul className="divide-y divide-gray-200">
              {labs?.map((lab, index) => (
                <LabListItem
                  key={lab.dr.id ?? index}
                  lab={lab}
                  onDelete={() => setDeleteIndex(lab.dr.id)}
                  onEdit={() => edit(lab)}
                />
              ))}
            </ul>
          </InfiniteScroll>
        </div>
        <ConfirmDialog
          confirmText={`Are you sure you want to remove this laboratory?`}
          actionName="Remove"
          visible={deleteIndex !== undefined || isDeleting}
          isLoading={isDeleting}
          onConfirm={() => removeLab(deleteIndex as string)}
          hideDialog={() => setDeleteIndex(undefined)}
        />
      </DataContainerSlideoverForm>
    </ReplaceFormProvider>
  )
}

export { LabsContainer }
