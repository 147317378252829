import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  Bundle,
  BundleEntryArray,
  CodeableConcept,
  Coding,
  DiagnosticReport,
  Observation,
  Reference,
  ServiceRequest,
  asReference,
  getResource,
} from "fhir"
import { v4 } from "uuid"

import { useClient } from "api"
import { CustomError } from "commons"
import { plansQueryKeys } from "commons/care-plans"
import { formatsByTypes, srCategoryCodes } from "data"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { mcQueryKeys } from "mc/query-keys"
import { SYSTEM_VALUES } from "system-values"
import { formatDate } from "utils"

const useSavePlanLabs = (onSuccess?: (serviceRequest: ServiceRequest) => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const saveLabs = async ({
    qrId,
    observations,
    performer,
    patientRef,
    requester,
    runAlgorithmCode,
    icd10,
    prevLabOrder,
    encounter,
    saveDraft,
    order,
    dr,
  }: {
    qrId: string
    observations?: Observation[]
    performer?: Reference
    patientRef: Reference
    requester: Reference
    runAlgorithmCode: Coding
    icd10?: CodeableConcept
    prevLabOrder?: string
    encounter?: Reference
    saveDraft?: boolean
    order?: ServiceRequest
    dr?: DiagnosticReport
  }) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [],
    }
    const drUrl = `urn:uuid:${v4()}`

    if (observations && observations.length > 0) {
      const obData = observations.reduce<{ entries: Array<BundleEntryArray>; references: Array<Reference> }>(
        (acc, ob) => {
          if (ob.id) {
            return { entries: acc.entries, references: [...acc.references, asReference(ob)] }
          } else {
            const obUrl = `urn:uuid:${v4()}`
            const obEntry: BundleEntryArray = {
              fullUrl: obUrl,
              request: {
                method: "POST",
                url: "Observation",
              },
              resource: ob,
            }
            return { entries: [...acc.entries, obEntry], references: [...acc.references, { uri: obUrl }] }
          }
        },
        { entries: [], references: [] },
      )

      bundle.entry?.push(...obData.entries)

      const newDR: DiagnosticReport = dr
        ? {
            ...dr,
            identifier: undefined,
            basedOn: undefined,
            result: obData.references,
            ...(performer ? { performer: [performer] } : {}),
            subject: patientRef,
            resourceType: "DiagnosticReport",
          }
        : {
            category: [
              {
                coding: [
                  {
                    system: SYSTEM_VALUES.V2_0074,
                    code: "LAB",
                    display: "Laboratory",
                  },
                ],
                text: "Laboratory",
              },
            ],
            subject: patientRef,
            code: {
              text: "Entered",
              coding: [
                {
                  system: SYSTEM_VALUES.TEMPORARY_CODES,
                  code: "entered",
                  display: "Entered",
                },
              ],
            },
            status: "final",
            issued: new Date(),
            result: obData.references,
            ...(performer ? { performer: [performer] } : {}),
            resourceType: "DiagnosticReport",
          }

      bundle.entry?.push({
        ...(!dr?.id ? { fullUrl: drUrl } : {}),
        request: {
          method: dr?.id ? "PATCH" : "POST",
          url: `DiagnosticReport${dr?.id ? `/${dr.id}` : ""}`,
        },
        resource: newDR,
      })
    }

    const sr: ServiceRequest = order
      ? {
          ...order,
          resourceType: "ServiceRequest",
          status: saveDraft ? "draft" : "active",
          code: { coding: [runAlgorithmCode] },
          supportingInfo: [
            {
              resourceType: "QuestionnaireResponse",
              id: qrId,
            },
            ...(observations && observations.length > 0 ? [dr ? asReference(dr) : { uri: drUrl }] : []),
            ...(prevLabOrder ? [{ id: prevLabOrder, resourceType: "ServiceRequest" }] : []),
          ],
          encounter,
        }
      : {
          resourceType: "ServiceRequest",
          status: saveDraft ? "draft" : "active",
          intent: "plan",
          category: [
            {
              coding: [srCategoryCodes["algorithm-order"]],
              text: "Algorithm Order",
            },
          ],
          code: { coding: [runAlgorithmCode] },
          subject: patientRef,
          requester,
          reasonCode: icd10 ? [icd10] : [],
          supportingInfo: [
            {
              resourceType: "QuestionnaireResponse",
              id: qrId,
            },
            ...(observations && observations.length > 0 ? [{ uri: drUrl }] : []),
            ...(prevLabOrder ? [{ id: prevLabOrder, resourceType: "ServiceRequest" }] : []),
          ],
          encounter,
          authoredOn: formatDate(new Date(), formatsByTypes.ISO_8601_DATETIME),
          ...(performer ? { performer: [performer] } : {}),
        }

    bundle.entry?.push({
      request: {
        method: order?.id ? "PATCH" : "POST",
        url: `ServiceRequest${order?.id ? `/${order.id}` : ""}`,
      },
      resource: sr,
    })

    const respBundle = await transaction<Bundle>(bundle)
    const serviceRequest = getResource<ServiceRequest>(respBundle, "ServiceRequest")

    return serviceRequest
  }

  const { mutate: savePlanLabs, isPending } = useMutation({
    mutationFn: saveLabs,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (serviceRequest, { patientRef }) => {
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === mcQueryKeys.list(patientRef.id as string)[0],
      })
      await queryClient.invalidateQueries({
        queryKey: plansQueryKeys.assessments(patientRef.id as string),
        refetchType: "all",
      })

      onSuccess?.(serviceRequest)
    },
  })

  return { savePlanLabs, isPending }
}

export { useSavePlanLabs }
