import { organizationSettingId } from "data"

import { GroupedChargeItemDefinitionsCodes } from "./types"

//uses the names of the functions there are been use to keep it `commons`
const commonsQueryKeys = {
  laboratoryTests: (
    organizationID: string,
    catalogAuthorID?: string,
    searchText?: string,
    count?: number,
    canonicals?: string[],
    billingType?: string,
    withPrice?: boolean,
  ) => ["laboratory/tests", organizationID, catalogAuthorID, searchText, count, canonicals, billingType, withPrice],
  bloodDrawnTests: (organizationID: string, billingType?: string) => [
    "laboratory/blood-drawn-tests",
    organizationID,
    billingType,
  ],
  chargeItemsDefinitions: (organizationID: string, codes?: GroupedChargeItemDefinitionsCodes) => [
    "organization/cid",
    organizationID,
    codes,
  ],
  defaultOrgData: (organizationID: string) => [organizationSettingId.defaultOrgdata, organizationID],
  signed: ["signedUrl"] as const,
  signedWithUrl: (url: string) => [...commonsQueryKeys.signed, url],
  qDataByCanonical: (patientId: string, canonicals?: string[], status?: string[]) => [
    "questionnaire/data",
    patientId,
    ...(canonicals?.length ? [...canonicals] : []),
    ...(status?.length ? [...status] : []),
  ],
}

export { commonsQueryKeys }
/*  */
