export const communicationRequestStatus = [
  { code: "active", display: "Active" },
  { code: "completed", display: "Completed" },
  { code: "draft", display: "Draft" },
  { code: "stopped", display: "Stopped" },
]

export const communicationStatusReason = [
  { code: "processed", display: "Processed" },
  { code: "dropped", display: "Dropped" },
  { code: "deferred", display: "Deferred" },
  { code: "bounce", display: "Bounce" },
  { code: "delivered", display: "Delivered" },
  { code: "open", display: "Open" },
  { code: "click", display: "Click" },
  { code: "spam", display: "Spam Report" },
  { code: "unsubscribe", display: "Unsubscribe" },
]

export const KeysNames = {
  sessionId: "sessionId",
  accessToken: "accessToken",
  applicationId: "applicationId",
} as const

export const IdToAppendVideo = {
  publisher: "publisher",
  subscriber: "subscriber",
  shareScreen: "shareScreen",
} as const

export const VonageSignalPractitioner = {
  audioMute: "audioMutePractitioner",
} as const

export const VonageSignalPatient = {
  audioMute: "audioMutePatient",
} as const

export const SettingsBtnsNames = {
  endCall: "End call",
  audio: "Audio",
  video: "Video",
  pip: "Picture in picture",
  shareScreen: "Share screen",
} as const

export const SubscriberConnection = {
  connected: "connected",
  disconnected: "disconnected",
  notInCall: "notInCall",
} as const
