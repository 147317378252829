import { createContext, useContext } from "react"

const EmailCheckContext = createContext<State>({
  isCheckingEmailInUse: false,
})
EmailCheckContext.displayName = "EmailCheckContext"

const useEmailCheckContext = () => {
  const context = useContext(EmailCheckContext)

  if (!context) throw new Error("useEmailCheckContext must be used withing EmailCheckContext")

  return context
}

type State = {
  isCheckingEmailInUse: boolean
  setIsCheckingEmailInUse?: (isChecking: boolean) => void
}

export { EmailCheckContext, useEmailCheckContext }
