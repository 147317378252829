import { DateField, InputField } from "commons"

const IdentifierForm = ({ fieldPath }: Props) => (
  <div className="relative p-fluid grid gap-4">
    <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
      <legend>Identifier Information</legend>
      <InputField
        field={`${fieldPath}.value`}
        label="Value"
        className="col-span-2"
        validation={(value) => (!value ? "Value is required" : undefined)}
      />
      <InputField
        field={`${fieldPath}.system`}
        label="System"
        className="col-span-2"
        validation={(system) => (!system ? "System is required" : undefined)}
      />
      <DateField field={`${fieldPath}.period.start`} label="Start Date"  className="col-span-2" stringFormatType="ISO_8601_DATE" />
      <DateField field={`${fieldPath}.period.end`} label="End Date"  className="col-span-2" stringFormatType="ISO_8601_DATE" />
    </fieldset>
  </div>
)

type Props = {
  fieldPath: string
}
export { IdentifierForm }
