import { FC, useId } from "react"
import { Observation } from "fhir"

import {
  ConfirmDialog,
  SkeletonLoader,
  InfiniteScroll,
  useCrudReducer,
  DataContainerSlideoverForm,
  StackedListContainer,
  ModulesId,
} from "commons"
import { usePatientContext } from "patients"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"

import { useSocialHistory, useCreateSocialHistory, useUpdateSocialHistory, useDeleteSocialHistory } from "../hooks"
import { getInitialValues, sanitize, socialHistoryValidationSchema } from "./validations"
import { SocialHistoryForm } from "./SocialHistoryForm"
import { socialModel } from "./socialHistoryModelBuilder"

const SocialHistoryContainer: FC = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()

  const { openEncounterRef } = useOpenEncounter(patientId)
  const { reset, add, edit, setDeleteIndex, showSlide, initialValue, isNew, deleteIndex } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { socialHistory, isLoading, hasNextPage, fetchNextPage } = useSocialHistory(patientId)
  const { createSocialHistory } = useCreateSocialHistory(reset)
  const { updateSocialHistory } = useUpdateSocialHistory(reset)
  const { removeSocialHistory, isDeleting } = useDeleteSocialHistory(() => setDeleteIndex(undefined))

  const onSubmit = (socialHistory: Observation) => {
    const sanitizedHistory = sanitize(socialHistory)
    isNew ? createSocialHistory(sanitizedHistory) : updateSocialHistory(sanitizedHistory)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <DataContainerSlideoverForm
      messageDataNotFound="No social history found"
      hasData={!!socialHistory?.length}
      showSlide={showSlide}
      formTitle="Social History"
      formInitialValue={initialValue}
      validationSchema={socialHistoryValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<SocialHistoryForm isEditing={!isNew} />}
      onButtonAddClick={add}
      iconDataNotFound={appSubModules["intake"][ModulesId.SOCIAL_HISTORY].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={socialHistory ?? []}
            itemModelBuilder={(item) =>
              socialModel(
                item,
                () => edit(item),
                () => setDeleteIndex(item.id),
              )
            }
          />
        </InfiniteScroll>
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to remove this social history?`}
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() => removeSocialHistory(deleteIndex as string)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </DataContainerSlideoverForm>
  )
}

export { SocialHistoryContainer }
