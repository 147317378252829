import { Message } from "primereact/message"

const RxRecomendations = () => (
  <div className="flex flex-col gap-3 py-6 grow">
    <div className="font-medium text-sm">
      <span>Recommended Rx</span>
    </div>
    <Message text="Coming soon"></Message>
  </div>
)

export { RxRecomendations }
