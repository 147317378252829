import {
  faArchive,
  faCalendarDays,
  faFile,
  faFilePdf,
  faPencil,
  faSquareArrowDown,
  faUnlock,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns/parseISO"
import { Reference, ServiceRequest } from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import { StackedListItemProps } from "commons"
import { DRData } from "commons/procedures"
import { MC_ACTIVITY_TYPE, formatsByTypes } from "data"
import { formatDate } from "utils"

import { CarePlanWithDR } from "../types"

const itemModel = ({ plan, order, results }: CarePlanWithDR, isLoading?: boolean): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          value: `Assessment${
            plan?.category?.[0]?.coding?.[0]?.code?.includes(MC_ACTIVITY_TYPE.MC_SURVEY) || !results
              ? " (Questionnaire)"
              : " (Questionnaire + Labs)"
          }`,
        },
      ],
    },
    ...((order?.status === "draft" && order.meta?.createdAt) || plan?.meta?.createdAt
      ? [
          {
            lineItems: [
              {
                name: "Date",
                value: formatDate(
                  parseISO(
                    new Date(
                      order?.status === "draft" ? (order.meta?.createdAt as Date) : (plan?.meta?.createdAt as Date),
                    ).toISOString(),
                  ),
                  formatsByTypes.LONG_DATETIME,
                ),
                icon: faCalendarDays,
              },
            ],
          },
        ]
      : []),
  ],
  isLoading,
})

const assessmentItemModel = (
  item: CarePlanWithDR,
  onClick: () => void,
  release: (drId: string) => void,
  download: (planId: string) => void,
  print: (planIQRd: string) => void,
  archive: (planId: string, resourceType: "CarePlan" | "ServiceRequest") => void,
  loading: boolean,
  edit?: (value: {
    labData: object
    qrId: string
    performer: Reference | undefined
    selectedDR: DRData
    order: ServiceRequest | undefined
  }) => void,
): StackedListItemProps => {
  const { diagnosticReport, plan, order, qrId, results } = item
  let confirmVisible = false

  return {
    ...itemModel(item, loading),
    onClick: plan?.id ? onClick : undefined,
    menu: [
      ...(plan?.id
        ? [
            ...(diagnosticReport?.id
              ? [
                  {
                    label: "Release to patient",
                    icon: (
                      <div className="relative w-4 h-2 mr-2">
                        <FontAwesomeIcon icon={faFile} size="sm" className="mr-2 absolute z-10" />
                        <FontAwesomeIcon icon={faUnlock} size="xs" className="mr-2 absolute z-20 top-1 left-2" />
                      </div>
                    ),
                    command: () => {
                      release(diagnosticReport?.id ?? "")
                    },
                    disabled: !!diagnosticReport?.issued,
                  },
                ]
              : []),
            {
              label: "Download report",
              icon: <FontAwesomeIcon icon={faSquareArrowDown} size="sm" />,
              command: () => {
                download(plan.id ?? "")
              },
            },
          ]
        : [
            ...(results && qrId && order.status === "draft" && edit
              ? [
                  {
                    label: "Edit",
                    icon: <FontAwesomeIcon icon={faPencil} size="sm" />,
                    command: () =>
                      edit({ labData: {}, qrId, performer: order.performer?.[0], selectedDR: results, order }),
                  },
                ]
              : []),
          ]),
      ...(order.status !== "active"
        ? [
            {
              label: "Download questionnaire",
              icon: <FontAwesomeIcon icon={faFilePdf} size="sm" />,
              command: () => {
                print(qrId ?? "")
              },
              disabled: !qrId,
            },
          ]
        : []),
      ...(!["entered-in-error", "revoked", "completed"].includes(plan?.status ?? "")
        ? [
            {
              label: "Archive",
              icon: <FontAwesomeIcon icon={faArchive} size="sm" />,
              command: () => {
                confirmVisible = true
                confirmDialog({
                  accept: () => archive(order.id as string, "ServiceRequest"),
                  visible: loading || confirmVisible,
                  acceptLabel: "Archive",
                  message: "Are you sure you want to archive this assessment?",
                  closable: true,
                  reject: () => (confirmVisible = false),
                  rejectLabel: "Close",
                  header: "Confirmation",
                  rejectClassName: "button-default p-button-sm",
                  acceptClassName: "button-primary p-button-sm",
                })
              },
              disabled: !order.id,
            },
          ]
        : []),
    ],
    isLoading: loading,
  }
}

export { assessmentItemModel, itemModel }
