import { Reference } from "fhir"
import { ErrorMessage, Field, FieldProps, useFormikContext } from "formik"
import { Dropdown, DropdownProps } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { FC, useEffect } from "react"

const ReferenceDropdownField: FC<Props> = ({
  field,
  options,
  label,
  className,
  horizontal,
  inputClassName,
  optionLabel = "display",
  readonly,
  disabled,
  isClearable,
  filterBy = "display",
  showFilter = true,
  placeholder,
  assignDefaultValue = true,
  labelClassName,
  validation,
  handleChange,
  ...props
}) => {
  const { setFieldValue, getFieldMeta } = useFormikContext()

  useEffect(() => {
    const value = getFieldMeta(field)?.initialValue
    if (assignDefaultValue && !value && !isClearable && !readonly && !disabled && options?.length) {
      setFieldValue(field, options?.[0])
    }
  }, [])

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
        <div
          className={classNames(
            "field relative",
            horizontal ? "inline-flex justify-between horizontal" : "flex flex-col",
            className,
          )}
        >
          {label && (
            <label
              htmlFor={name}
              className={classNames(
                "text-sm font-medium text-gray-700 mb-2",
                {
                  "mr-3 mb-0 mt-2": horizontal,
                },
                labelClassName,
              )}
            >
              {label}
            </label>
          )}
          <div className="flex flex-col flex-1 w-full">
            <Dropdown
              id={name}
              name={name}
              optionLabel={optionLabel}
              options={options}
              filter={showFilter && filterBy !== undefined && options && options.length > 0}
              filterBy={filterBy}
              resetFilterOnHide={true}
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e)
                handleChange?.(e.value)
              }}
              dataKey="id"
              value={value}
              showClear={isClearable}
              readOnly={readonly}
              disabled={disabled}
              className={classNames(
                "p-inputtext-sm",
                {
                  "p-invalid": touched && error,
                  horizontal: horizontal,
                },
                inputClassName,
              )}
              {...props}
            />
            <div className="flex items-start p-error h-2 mt-1">
              <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
            </div>
          </div>
        </div>
      )}
    </Field>
  )
}

type Props = {
  field: string
  options?: Reference[]
  label?: string
  className?: string
  filterBy?: string
  showFilter?: boolean
  horizontal?: boolean
  inputClassName?: string
  labelClassName?: string
  optionLabel?: string
  readonly?: boolean
  disabled?: boolean
  isClearable?: boolean
  placeholder?: string
  assignDefaultValue?: boolean
  validation?(value: Reference): void
  handleChange?(value: Reference): void
} & DropdownProps

export { ReferenceDropdownField }
