import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { patientsQueryKeys } from "../query-keys"

const useInvitePatient = (patientId: string, patientName: string) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const invite = async () => {
    return operationRequest({ endpoint: `Patient/${patientId}`, method: "POST", operation: "invite" })
  }

  const { mutate: invitePatient, isPending: isInviting } = useMutation({
    mutationFn: invite,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientsQueryKeys.invitations.list(patientId),
        refetchType: "all",
      })

      displayNotificationSuccess(`Invitation sent to ${patientName} via email.`)
      datadogLogs.logger.info(`Invitation sent to ${patientName} via email.`)
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { invitePatient, isInviting }
}

export { useInvitePatient }
