import { InputNumber } from "primereact/inputnumber"
import { Message } from "primereact/message"
import { FC, useCallback, useMemo, useState } from "react"

import { ModalDialog } from "commons"

import { getDoseNumberValue } from "../../utils"

const DoseHandlerModal: FC<Props> = ({
  dosages,
  doseUnit,
  maxDosage,
  modalTitle,
  onHide,
  onUpdateDosage,
  defaultDoseUnitsToAdd = 1,
}) => {
  const [currentDosage, setCurrentDosage] = useState([
    ...(dosages ?? []).reduce<number[]>((acc, doseStr) => {
      const doseNum = getDoseNumberValue(doseStr, doseUnit)
      return [...acc, doseNum]
    }, []),
  ])

  const availableDoseUnits = useMemo(
    () =>
      !currentDosage?.length
        ? maxDosage ?? 0
        : currentDosage.reduce<number>((acc, dNum) => {
            return acc > 0 ? acc - dNum : 0
          }, maxDosage ?? 0),
    [currentDosage],
  )
  const updateDoseValue = useCallback(
    (newValue: number | null | undefined, index: number) => {
      const updatedDosage = currentDosage.toSpliced(index, 1, newValue ?? 0)
      setCurrentDosage([...updatedDosage])
    },
    [currentDosage, setCurrentDosage],
  )

  const inputStep = useMemo(
    () => Math.min(availableDoseUnits ? availableDoseUnits : 1, defaultDoseUnitsToAdd),
    [defaultDoseUnitsToAdd, availableDoseUnits],
  )

  const getInputMax = useCallback(
    (val: number) => (availableDoseUnits ? val + availableDoseUnits : val),
    [availableDoseUnits],
  )

  const handleApplyUpdate = useCallback(() => {
    onUpdateDosage(currentDosage)
  }, [currentDosage])

  return (
    <ModalDialog
      onCancel={onHide}
      visible={true}
      showCancel
      cancelLabel="Close"
      title={modalTitle}
      draggable
      showButtons
      onAccept={handleApplyUpdate}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 grow p-1">
        {currentDosage?.map((dose, index) => (
          <div key={`${dose}_${index}`} className="p-fluid inline-flex flex-1 space-x-3 min-w-fit items-center">
            <label htmlFor={`${index}input`} className="whitespace-nowrap">
              Point {index + 1}:
            </label>
            <InputNumber
              inputId={`${index}_input`}
              value={dose}
              suffix={` ${doseUnit}`}
              max={getInputMax(dose)}
              min={0}
              step={inputStep}
              className="p-inputtext-sm"
              allowEmpty={false}
              onValueChange={({ value }) => updateDoseValue(value, index)}
            />
          </div>
        ))}
      </div>

      <Message
        severity={availableDoseUnits ? "info" : "warn"}
        text={
          !availableDoseUnits
            ? `The ${modalTitle} dose has been fully distributed. You can remove points or reduce the
  dose on any point.`
            : `Distributing ${(maxDosage ?? 0) - availableDoseUnits}${doseUnit} of ${modalTitle}`
        }
        className="flex flex-1 justify-center self-center"
      />
    </ModalDialog>
  )
}

type Props = {
  dosages?: string[]
  maxDosage?: number
  doseUnit?: string
  modalTitle?: string
  onHide(): void
  defaultDoseUnitsToAdd?: number
  onUpdateDosage(updatedDosage: number[]): void
}

export { DoseHandlerModal }
