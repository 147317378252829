import { Sidebar } from "primereact/sidebar"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { EmailCheckContext, EmailCheckProvider, FormContainer, SplitButton } from "commons"
import { Patient, humanNameAsString } from "fhir"
import { intercomTrackEvent } from "intercom"
import { useAppModuleContext } from "internals"
import { datadogLogs } from "logger"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { useAuth, useLoginContext } from "security"

import { useCreatePatient } from "../hooks"
import { PatientFormData } from "../types"
import { PatientForm } from "./PatientForm"
import { PatientList } from "./PatientList"
import { initialValues, newPatientValidationSchema, sanitize } from "./validations"

enum NAVIGATION_VIEW {
  PATIENT_INFO = "patient-information",
  P360 = "patient360",
}

const PatientListView = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { loggedInPractitionerName } = useLoginContext()
  const { currentOrganizationId, currentOrganization } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { organizationPractitionerRefs, organizationPractitionersInfo } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })

  const [showOverlayForm, setShowOverlayForm] = useState(false)
  const [validateAddress, setValidateAddress] = useState(false)
  const [navigationView, setNavigationView] = useState(NAVIGATION_VIEW.PATIENT_INFO)

  const closeForm = () => {
    setShowOverlayForm(false)
    setValidateAddress(false)
  }

  const onCreateSuccess = useCallback(
    (patient: Patient) => {
      const navView = `/${patient.id}?kp=patient-information${
        navigationView === "patient360" ? `&view=${navigationView}` : ``
      }`

      if (user) {
        intercomTrackEvent({
          user_id: user?.b2cUserId,
          email: user?.email,
          event: "user-invited",
          metadata: {
            user_invited: {
              url: `${window.location.href}${navView}`,
              value: humanNameAsString(patient.name?.[0]),
            },
            ...(patient.managingOrganization
              ? { invited_to: { url: window.location.href, value: patient.managingOrganization.display as string } }
              : {}),
            invited_by: loggedInPractitionerName,
            resource: JSON.stringify(patient),
          },
        })
      }

      datadogLogs.logger.info(`Patient Created by ${loggedInPractitionerName}`)
      navigate(`/orgs/${currentOrganizationId}/patients${navView}`)
    },
    [navigationView],
  )

  const { createPatient, isAdding } = useCreatePatient(closeForm, onCreateSuccess)

  const customSidebarTitlebar = (
    <>
      <span className="bg-white w-full">
        <h6 className="font-semibold">Create Patient</h6>
        <p className="text-slate-400 text-sm">Use a permanent address where you can receive email</p>
      </span>
    </>
  )

  const onSubmit = (patient: PatientFormData) => {
    const sanitizedData = sanitize(patient)
    createPatient(sanitizedData)
  }

  return (
    <>
      <PatientList showForm={() => setShowOverlayForm(true)} />
      <Sidebar
        visible={showOverlayForm}
        position="right"
        style={{ minWidth: "40%" }}
        header={customSidebarTitlebar}
        onHide={closeForm}
      >
        <div className="relative h-full">
          <EmailCheckProvider>
            <FormContainer
              validateOnChange={false}
              initialValue={initialValues(undefined, currentOrganization, organizationPractitionersInfo, [])}
              onSubmit={onSubmit}
              onCancel={closeForm}
              validationSchema={newPatientValidationSchema(validateAddress)}
              customSaveButton={({ isSubmitting }) => (
                <EmailCheckContext.Consumer>
                  {({ isCheckingEmailInUse }) => (
                    <SplitButton
                      type="submit"
                      size="lg"
                      loading={isAdding || isCheckingEmailInUse}
                      autoUpdateMainAction
                      actions={[
                        {
                          label: "Save",
                          description: "Create a new patient and go to patient chart",
                          onSelectClick: () => setNavigationView(NAVIGATION_VIEW.PATIENT_INFO),
                          disabled: isCheckingEmailInUse || isSubmitting,
                        },
                        {
                          label: "Save & Pull",
                          description: "Create patient and go to Patient360 module to pull data from network",
                          disabled: !isModuleActive(NAVIGATION_VIEW.P360) || isCheckingEmailInUse || isSubmitting,
                          disabledReason: "Module Patient360 is disabled",
                          onSelectClick: () => setNavigationView(NAVIGATION_VIEW.P360),
                        },
                      ]}
                    />
                  )}
                </EmailCheckContext.Consumer>
              )}
            >
              <PatientForm
                practitioners={organizationPractitionerRefs}
                toggleValidateAddress={() => {
                  setValidateAddress((prevState) => !prevState)
                }}
              />
            </FormContainer>
          </EmailCheckProvider>
        </div>
      </Sidebar>
    </>
  )
}

export { PatientListView }
