import { asReference, CodeableConcept, Coding, PlanDefinitionActionArrayActionArray } from "fhir"
import { FieldArrayRenderProps, useFormikContext } from "formik"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { FC, useCallback, useMemo, useState } from "react"

import { AddFieldArrayItemButton, GenericFieldArray, ValueSetIds } from "commons"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"
import { useValueSet } from "value-set"

import { BodyZones, bodyZonesCodes, ConfigurationItem, PROCEDURE_CONFIG, ProcedureData } from "../../types"
import { getDoseNumberValue } from "../../utils"
import { getMassageInitialValues, getMedInitialValues } from "../../utils/transformers"
import { MedicationInventoryList, NewMedData } from "../MedicationInventoryList"
import { BodySites } from "./BodySites"
import { MedConfigurationItem } from "./MedConfigurationItem"

const MedConfiguration: FC<Props> = ({ currentMedIndex, setCurrentMedIndex, configActions, configCodes }) => {
  const { codes: bodySites } = useValueSet({ valueSetId: ValueSetIds.BODY_SITES })
  const { patientRef, patientId } = usePatientContext()
  const { openEncounter } = useOpenEncounter(patientId)

  const {
    values: { configurationItem },
    setFieldValue,
  } = useFormikContext<ProcedureData>()

  const requireConfigMedication = configCodes.includes(PROCEDURE_CONFIG.PELLET)
  const requireConfigDosage = configCodes.includes(PROCEDURE_CONFIG.BODY_SITE_COORDINATE_AND_DOSAGE)

  const [showMedInventory, setShowMedInventory] = useState(
    requireConfigMedication && !configurationItem[0]?.medicationAdministration,
  )

  const isSingleBodySite =
    !configCodes.includes(PROCEDURE_CONFIG.BODY_SITE_COORDINATE) &&
    !requireConfigDosage &&
    configCodes.includes(PROCEDURE_CONFIG.BODY_SITE)

  const bodySiteActionCode = useMemo(() => {
    return configActions.reduce((acc, action) => {
      const bodySiteAct =
        action.code?.find(({ coding }) => coding?.[0]?.code !== PROCEDURE_CONFIG.PELLET) ??
        action.action?.[0]?.code?.find(({ coding }) => coding?.[0]?.code !== PROCEDURE_CONFIG.PELLET)
      return [...acc, ...(bodySiteAct ? [bodySiteAct] : [])]
    }, Array<CodeableConcept>())?.[0]?.coding?.[0]
  }, [configActions])

  const getInitBodySite = (medIndex: number) =>
    configurationItem[medIndex]?.bodySite?.coding?.[0]?.code?.split("|") ?? []

  const getInitBodySiteDosage = (medIndex: number) =>
    configurationItem[medIndex]?.bodySite?.coding
      ?.find(({ system }) => system === `${bodySiteActionCode?.system}/configure-dosage`)
      ?.code?.split("|") ?? []

  const [bodySiteClass, setBodySiteClass] = useState("opacity-100")
  const [bodySite, setBodySite] = useState<string[] | undefined>(getInitBodySite(currentMedIndex))
  const [bodyZone, setBodyZone] = useState<Coding>(configurationItem[currentMedIndex]?.zone ?? bodyZonesCodes[0])
  const [dosageCodes, setDosageCodes] = useState<string[]>(getInitBodySiteDosage(currentMedIndex))

  const changeCurrentMed = (medIndex: number) => {
    setBodySiteClass("opacity-20")
    setCurrentMedIndex(medIndex)
    setTimeout(() => {
      setBodySiteClass("opacity-100")
      setBodySite(getInitBodySite(medIndex))
      setDosageCodes(getInitBodySiteDosage(medIndex))
      setBodyZone(configurationItem[medIndex]?.zone ?? bodyZonesCodes[requireConfigDosage ? 1 : 0])
    }, 300)
  }

  const { availableDoseUnits, totalDose } = useMemo(() => {
    const totalDose = configurationItem[currentMedIndex]?.medTotalDose
    const availableDoseUnits = !dosageCodes.length
      ? totalDose?.value ?? 0
      : dosageCodes.reduce<number>((acc, dStr) => {
          const dNum = getDoseNumberValue(dStr, totalDose?.unit)

          return acc > 0 ? acc - dNum : 0
        }, totalDose?.value ?? 0)

    return { availableDoseUnits, totalDose }
  }, [dosageCodes, configurationItem[currentMedIndex]?.medTotalDose])

  const updateDosage = useCallback(
    (doseUnits: number = requireConfigDosage ? 1 : 0, doseIndexToUpdate: number = -1) => {
      let newDoseEntry: Coding | undefined
      let deletedEntryValue = 0

      if (doseIndexToUpdate >= 0) {
        const deletedEntry = dosageCodes.splice(doseIndexToUpdate, 1)
        deletedEntryValue = Number.parseFloat(deletedEntry[0]?.replace(totalDose?.unit ?? "mg", ""))
      }

      const newUnitsToAdd = doseUnits <= availableDoseUnits ? doseUnits : availableDoseUnits
      const newDoseSum = (totalDose?.value ?? 0) - (availableDoseUnits + deletedEntryValue) + newUnitsToAdd

      if (doseUnits && availableDoseUnits) {
        const newDoseCode =
          doseIndexToUpdate && doseIndexToUpdate !== -1
            ? [
                ...dosageCodes.slice(0, doseIndexToUpdate - 1),
                `${newUnitsToAdd}${totalDose?.unit ?? "mg"}`,
                ...dosageCodes.slice(doseIndexToUpdate),
              ]
            : [...dosageCodes, `${newUnitsToAdd}${totalDose?.unit ?? "mg"}`]

        newDoseEntry = {
          code: newDoseCode.join("|"),
          system: `${bodySiteActionCode?.system}/configure-dosage`,
          display: `${newDoseSum} of ${totalDose?.value}${totalDose?.unit} distributed`,
        }
        setDosageCodes(newDoseCode)
      } else {
        newDoseEntry = dosageCodes.length
          ? {
              code: dosageCodes.join("|"),
              system: `${bodySiteActionCode?.system}/configure-dosage`,
              display: `${newDoseSum} of ${totalDose?.value}${totalDose?.unit} distributed`,
            }
          : undefined
        setDosageCodes([...dosageCodes])
      }

      return newDoseEntry
    },
    [dosageCodes, totalDose, availableDoseUnits],
  )

  const selectBodySite = useCallback(
    (point: string) => {
      const pointToRemoveIndex = isSingleBodySite || !bodySite ? -1 : bodySite.indexOf(point)
      const allowAdd = !!availableDoseUnits || !dosageCodes.length
      const points = isSingleBodySite
        ? [point]
        : pointToRemoveIndex !== -1
          ? bodySite?.toSpliced(pointToRemoveIndex, 1) ?? []
          : allowAdd
            ? [...(bodySite ?? []), point]
            : bodySite ?? []

      const code = isSingleBodySite
        ? bodySites?.find((c) => c.code === point)
        : points.length
          ? ({
              code: points.join("|"),
              system: `${bodySiteActionCode?.system}/${bodySiteActionCode?.code}`,
              display: `${points.length} selected ${pluralize("point", points.length)}`,
            } as Coding)
          : { code: undefined }
      if (code) {
        setBodySite(points)
        const newDoseEntry = requireConfigDosage
          ? updateDosage(pointToRemoveIndex !== -1 || !allowAdd ? 0 : 1, pointToRemoveIndex)
          : undefined
        setFieldValue(`configurationItem[${currentMedIndex}].bodySite`, {
          coding: [code, ...(newDoseEntry ? [newDoseEntry] : [])],
          text: code?.display,
        })
      }
    },
    [updateDosage, bodySite, bodySiteActionCode, currentMedIndex, availableDoseUnits, bodySites],
  )

  const allowMultiple = configActions[0]?.cardinalityBehavior !== "single"

  const selectBodyZone = (zone: Coding) => {
    setBodySiteClass("opacity-0")

    setBodySite([])
    setDosageCodes([])
    setBodyZone(zone)
    setFieldValue(`configurationItem[${currentMedIndex}].bodySite`, { coding: [{ code: undefined }] })
    setBodySiteClass("opacity-100")
  }

  const handelUpdateDosage = useCallback(
    (updatedDosage: number[]) => {
      const { indexToDelete, dosageCode, dosageSum } = updatedDosage.reduce(
        (acc, doseNumb, currIndex) => {
          return {
            indexToDelete: [...acc.indexToDelete, ...(doseNumb === 0 ? [currIndex] : [])],
            dosageCode: [...acc.dosageCode, ...(doseNumb !== 0 ? [`${doseNumb}${totalDose?.unit ?? "mg"}`] : [])],
            dosageSum: acc.dosageSum + doseNumb,
          }
        },
        { indexToDelete: [], dosageCode: [], dosageSum: 0 } as {
          indexToDelete: number[]
          dosageCode: string[]
          dosageSum: number
        },
      )

      const newDoseEntry = dosageCode.length
        ? {
            code: dosageCode.join("|"),
            system: `${bodySiteActionCode?.system}/configure-dosage`,
            display: `${dosageSum} of ${totalDose?.value}${totalDose?.unit} distributed`,
          }
        : undefined

      let updatedBodySites: string[] = []
      if (!dosageCode.length) indexToDelete.concat(bodySite?.map((_, index) => index) ?? [])

      for (let index = 0; index < indexToDelete.length; index++) {
        const toDelete = indexToDelete[index]
        const prevIndex = index === 0 ? 0 : indexToDelete[index - 1] + 1
        updatedBodySites = [
          ...updatedBodySites,
          ...(bodySite?.slice(prevIndex, toDelete) ?? []),
          ...(index + 1 === indexToDelete.length && bodySite ? bodySite.slice(toDelete + 1) : []),
        ]
      }

      if (!updatedBodySites.length && dosageCode.length) updatedBodySites = [...(bodySite ?? [])]

      const bodySiteCode = updatedBodySites.length
        ? {
            code: updatedBodySites.join("|"),
            system: `${bodySiteActionCode?.system}/${bodySiteActionCode?.code}`,
            display: `${updatedBodySites.length} selected ${pluralize("point", updatedBodySites.length)}`,
          }
        : { code: undefined }

      setFieldValue(`configurationItem[${currentMedIndex}].bodySite`, {
        coding: [bodySiteCode, ...(newDoseEntry ? [newDoseEntry] : [])],
        text: bodySiteCode.display,
      })
      setBodySite(updatedBodySites)
      setDosageCodes(dosageCode)
    },
    [bodySite, bodySiteActionCode, currentMedIndex, totalDose],
  )

  return (
    <div className="flex flex-1 flex-row relative h-full gap-5">
      <GenericFieldArray
        field="configurationItem"
        className="w-max @lg:max-w-64 @2xl:max-w-full @2xl:min-w-64 gap-4"
        itemModelBuilder={() => ({})}
        itemsVisible={false}
        emptyMessageVisible={false}
        addButtonVisible={false}
      >
        {({ push, remove, form: { values, setFieldValue } }: FieldArrayRenderProps) => {
          const updateMeds = (newMeds: NewMedData[], deletedMeds: number[]) => {
            newMeds.forEach(({ mk, catalogAuthor, invData }) => {
              const newMed = getMedInitialValues(
                mk,
                1,
                catalogAuthor,
                patientRef,
                invData,
                openEncounter && asReference(openEncounter),
              )
              push(newMed)
            })
            deletedMeds.sort((a, b) => b - a)
            deletedMeds.forEach((index) => {
              const med: ConfigurationItem = values.configurationItem[index]
              if (med)
                setFieldValue("deletedMedications", [
                  ...values.deletedMedications,
                  { mrId: med.medicationRequest?.id, maId: med.medicationAdministration?.id },
                ])
              remove(index)
            })
          }

          const onAdd = () => {
            if (requireConfigMedication) setShowMedInventory(true)
            else {
              const massage = getMassageInitialValues()
              push(massage)
            }
          }

          return (
            <>
              <div className="flex flex-col gap-4">
                {configurationItem.map((_, index) => (
                  <MedConfigurationItem
                    key={index}
                    medIndex={index}
                    expanded={currentMedIndex === index}
                    onChangeBodySite={(bodySiteCode) => setBodySite(bodySiteCode)}
                    onClick={() => currentMedIndex !== index && changeCurrentMed(index)}
                    onChangeBodyZone={selectBodyZone}
                    isSingleBodySiteSelection={isSingleBodySite}
                    configCodes={configCodes}
                    bodySiteActionCode={bodySiteActionCode}
                    handelUpdateDosage={handelUpdateDosage}
                  />
                ))}
                {allowMultiple && <AddFieldArrayItemButton label="Add new" className="border-b-0" onClick={onAdd} />}
              </div>
              {showMedInventory && (
                <MedicationInventoryList
                  onHide={({ save, newMeds, deletedMeds }) => {
                    setShowMedInventory(false)
                    save && newMeds && deletedMeds && updateMeds(newMeds, deletedMeds)
                  }}
                  allowMultiple={allowMultiple}
                />
              )}
            </>
          )
        }}
      </GenericFieldArray>
      <div
        className={classNames(
          "flex flex-1 sticky top-0 justify-center items-center duration-300 transition-opacity focus:shadow-none pb-3",
          bodySiteClass,
        )}
      >
        {!!configurationItem?.length && (
          <BodySites
            isPellectSelection={isSingleBodySite}
            bodyZone={bodyZone?.code as BodyZones}
            selectedPoint={bodySite}
            onSelectPoint={selectBodySite}
            showPointOrder={requireConfigDosage}
          />
        )}
      </div>
    </div>
  )
}

type Props = {
  currentMedIndex: number
  setCurrentMedIndex(value: number): void
  configActions: PlanDefinitionActionArrayActionArray[]
  configCodes: string[]
}

export { MedConfiguration }
