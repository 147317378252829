import { FC, useId } from "react"
import { MedicationStatement } from "fhir"

import {
  ConfirmDialog,
  SkeletonLoader,
  InfiniteScroll,
  DataContainerSlideoverForm,
  useCrudReducer,
  StackedListContainer,
  ModulesId,
} from "commons"
import { usePatientContext } from "patients"
import { useAppModuleContext } from "internals"
import { useOpenEncounter } from "encounter"

import { getInitialValues, medicationStatementValidationSchema, sanitize } from "./validations"
import {
  useMedicationsStatement,
  useCreateMedicationStatement,
  useUpdateMedicationStatement,
  useDeleteMedication,
} from "../hooks"
import { MedicationStatementForm } from "./MedicationStatementForm"
import { medicationModel } from "./medStatementModuelBuilder"

const MedicationsStatementContainer: FC = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { reset, add, edit, setDeleteIndex, showSlide, initialValue, isNew, deleteIndex } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { medications, isLoading, hasNextPage, fetchNextPage } = useMedicationsStatement(patientId)
  const { createMedication } = useCreateMedicationStatement(reset)
  const { updateMedicationStatement } = useUpdateMedicationStatement(reset)
  const { removeMedicationStatement, isDeleting } = useDeleteMedication(() => setDeleteIndex(undefined))

  const onSubmit = (medication: MedicationStatement) => {
    isNew ? createMedication(sanitize(medication)) : updateMedicationStatement(sanitize(medication))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <DataContainerSlideoverForm
      hasData={!!medications?.length}
      showSlide={showSlide}
      formTitle="Medication Statement"
      formInitialValue={initialValue}
      validationSchema={medicationStatementValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<MedicationStatementForm isEditing={!isNew} />}
      onButtonAddClick={add}
      iconDataNotFound={appSubModules[ModulesId.INTAKE][ModulesId.MEDICATIONE].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={loader()}>
          <StackedListContainer
            data={medications ?? []}
            itemModelBuilder={(item) =>
              medicationModel(
                item,
                () => edit(item),
                () => setDeleteIndex(item.id as string),
              )
            }
          />
        </InfiniteScroll>
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to remove this medication statement?`}
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() => removeMedicationStatement(deleteIndex as string)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </DataContainerSlideoverForm>
  )
}

export { MedicationsStatementContainer }
