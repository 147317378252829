const allergiesQueryKeys = {
  all: ["patient/allergies"] as const,
  list: (patientId: string, key = "WK", encounter?: string) => [
    ...allergiesQueryKeys.all,
    patientId,
    key,
    ...(encounter ? [encounter] : []),
  ],
  actives: (patientId: string) => ["activeAllergies", patientId],
}

export { allergiesQueryKeys }
