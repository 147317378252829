import { useQuery } from "@tanstack/react-query"
import {
  Bundle,
  ChargeItem,
  CodeableConcept,
  codeableConceptAsString,
  getResource,
  getResources,
  Invoice,
  Parameters,
  RequestGroup,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { CustomError } from "commons"
import { SYSTEM_VALUES } from "system-values"
import { getCommonCode, getDiscountPrice, getFeePrice, getTaxPrice, sumPrice } from "utils"

import { CustomInvoiceData, InvoiceItem } from "../types"

const useInvoicePreview = (patientId: string, rg?: RequestGroup, enabled = true) => {
  const { read, operationRequest } = useClient()
  /* Disable cahe query when RG updated */
  const queryKey = ["patient/cpoe-invoice-preview", patientId, rg?.meta?.versionId]

  const generate = async () => {
    const requestGroup = (await read({ endpoint: "RequestGroup", id: rg?.id })) as RequestGroup
    if (requestGroup.status != "draft")
      throw new Error("It seems someone else has already finished this order before you")

    const parametersList = (await operationRequest({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "GET",
      operation: "invoice-preview",
    })) as Parameters[]

    return parametersList?.reduce<Array<InvoiceData>>((acc, { parameter }) => {
      const invoiceBundle = parameter?.find((p) => p.name === "invoice-bundle")?.resource as Bundle

      if (!invoiceBundle) {
        return acc
      }

      const invoice = getResource<Invoice>(invoiceBundle, "Invoice")
      const chargeItems = getResources<ChargeItem>(invoiceBundle, "ChargeItem")
      const summary = parameter?.find((p) => p.name === "summary")?.resource as Parameters

      return [...acc, { invoice, chargeItems, summary }]
    }, [])
  }

  const {
    isError,
    isLoading: isLoadingPreview,
    data,
    error,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: generate,
    enabled,
    meta: { context: { queryKey, patientId } },
    gcTime: 10,
  })

  const customData = useMemo(() => {
    return data?.map(({ invoice, summary }) => {
      const initialData: CustomInvoiceData = {
        items: {} as Record<string, InvoiceItem[]>,
        fees: [],
        feesSubTotal: 0,
        shippingMethods: {} as Record<string, InvoiceItem[]>,
        shippingMethodSubtotal: 0,
        taxes: 0,
        itemsSubtotal: 0,
        discount: 0,
        productFeesSubtotal: 0,
      }

      const itemData = invoice.lineItem?.reduce((result, item) => {
        const productType = getProductCode(item.productCode)

        if (item.chargeItem?.Reference) {
          const descriptionUnit = item.priceComponent?.[0].code?.coding?.[0].code
            ? ` (${item.priceComponent?.[0].code?.coding?.[0].code})`
            : ""

          if (productType)
            result.items = {
              ...result.items,
              ...{
                [productType]: [
                  ...(result.items[productType] ?? []),
                  {
                    productId: getCommonCode({ codes: item.productCode?.[1]?.coding }),
                    productType,
                    description: `${item.chargeItem.Reference.display as string}${descriptionUnit}`,
                    qty: item.priceComponent?.[0].factor,
                    price: item.priceComponent?.[0].amount?.value as number,
                  },
                ],
              },
            }
          result.itemsSubtotal = sumPrice(
            result.itemsSubtotal,
            item.priceComponent?.[0].amount?.value ?? 0,
          ).sum.toNumber()
        }

        if (item.chargeItem?.CodeableConcept?.coding?.some((ci) => ci.system === SYSTEM_VALUES.FEE)) {
          const productType = getProductCode(item.productCode)
          const price = getFeePrice(item.priceComponent)?.amount?.value ?? 0

          if (
            item.chargeItem?.CodeableConcept?.coding?.some((ci) =>
              (
                [
                  SYSTEM_VALUES.SHIPPING_METHOD,
                  SYSTEM_VALUES.SERVICE_FEE,
                  SYSTEM_VALUES.SHIPPING_METHOD_DEFAULT,
                ] as string[]
              ).includes(ci.system ?? ""),
            )
          ) {
            if (productType)
              result.shippingMethods = {
                ...result.shippingMethods,
                ...{
                  [productType]: [
                    ...(result.shippingMethods[productType] ?? []),
                    {
                      description: codeableConceptAsString(item?.chargeItem?.CodeableConcept),
                      price,
                      productType,
                    },
                  ],
                },
              }
            result.shippingMethodSubtotal = sumPrice(result.shippingMethodSubtotal, price).sum.toNumber()
          } else if (item.chargeItem?.CodeableConcept?.coding?.some((ci) => ci.system === SYSTEM_VALUES.PRODUCT_FEE)) {
            const procedureCode = getCommonCode({ codes: item.chargeItem.CodeableConcept.coding })
            result.productFees = {
              ...(result.productFees ?? {}),
              [procedureCode]: [
                ...(result.productFees?.[procedureCode] ?? []),
                {
                  description: `Additional Fee (${codeableConceptAsString(item?.chargeItem?.CodeableConcept)})`,
                  price,
                  productType,
                },
              ],
            }
            result.productFeesSubtotal = sumPrice(result.productFeesSubtotal, price).sum.toNumber()
          } else {
            result.fees = [
              ...result.fees,
              {
                description: codeableConceptAsString(item?.chargeItem?.CodeableConcept),
                price,
              },
            ]
            result.feesSubTotal = sumPrice(result.feesSubTotal, price).sum.toNumber()
          }
        }

        if (item.chargeItem?.CodeableConcept?.coding?.[0].code === "estimated-taxes") {
          result.taxes = sumPrice(result.taxes, getTaxPrice(item.priceComponent)?.amount?.value ?? 0).sum.toNumber()
        }

        if (item.chargeItem?.CodeableConcept?.coding?.[0].system === SYSTEM_VALUES.DISCOUNT) {
          const price = getDiscountPrice(item.priceComponent)?.amount?.value ?? 0
          result.discount = sumPrice(result.discount, price).sum.toNumber()

          const productType = getProductCode(item.productCode)
          if (productType)
            result.discounts = {
              ...result.discounts,
              ...{
                [productType]: [
                  ...(result.discounts?.[productType] ?? []),
                  {
                    description: codeableConceptAsString(item?.chargeItem?.CodeableConcept),
                    price,
                    productType,
                  },
                ],
              },
            }
        }

        return result
      }, initialData)

      return { ...itemData, invoice, summary }
    })
  }, [data])

  return { isLoadingPreview, invoiceData: customData, error: error as CustomError | null, isError, refetch }
}

const getProductCode = (productCodes?: CodeableConcept[]) => {
  return productCodes?.find((cc) => cc.coding?.find((code) => code.system === SYSTEM_VALUES.PRODUCT_TYPE))?.coding?.[0]
    .code
}

type InvoiceData = { invoice: Invoice; chargeItems: ChargeItem[]; summary: Parameters }

export { useInvoicePreview }
