import { Identifier } from "fhir"
import { useState } from "react"
import { FieldArray, FieldArrayRenderProps } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

import { useOrganizationContext } from "organization"

import { identifierInitialValues } from "./validations"
import { IdentifierListItem } from "./IdentifierListItem"
import { PractIdentifierForm } from "./PractIdentifierForm"

const PractitionerIdentifierContiner = ({ field, label }: Props) => {
  const { isAdmin } = useOrganizationContext()
  const [identifierForm, setIdentifierForm] = useState({ visible: false, fieldPath: "" })
  const [confirmDeleteItem, setConfirmDeleteItem] = useState(-1)

  const toggleModal = (visible: boolean) => setIdentifierForm({ ...identifierForm, visible })

  const renderModalFooter = (onDelete: () => void) => (
    <div className="mt-2">
      <Button label="Cancel" className="button-default" onClick={() => setConfirmDeleteItem(-1)} />
      <Button label="Delete" className="button-primary" onClick={onDelete} />
    </div>
  )

  return (
    <FieldArray name={field}>
      {({ push, remove, form: { values, setFieldValue, validateForm, setFieldTouched } }: FieldArrayRenderProps) => {
        const addNewIdentifier = async (identifier: Identifier, tempField: string) => {
          const tempIdentifier = { ...identifierInitialValues, ...identifier }

          // Manually validate newIdentifier
          validateForm({ ...values, newIdentifier: tempIdentifier }).then((value) => {
            if (value[tempField]) {
              setFieldTouched(`${tempField}.value`)
              setFieldTouched(`${tempField}.system`)
              setFieldTouched(`${tempField}.period.start`)
              setFieldTouched(`${tempField}.period.end`)
            } else {
              push(tempIdentifier)
              setFieldValue(tempField, undefined)
              setFieldTouched(tempField, false)
              toggleModal(false)
            }
          })
        }

        return (
          <>
            <div className="flex justify-between w-full">
              <label htmlFor="contact" className="font-medium text-gray-700">
                {label}
              </label>
              {isAdmin && (
                <Button
                  icon={<FontAwesomeIcon icon={faPlus} className="mr-1" />}
                  label="Create new"
                  className="p-button-text p-button-sm"
                  type="button"
                  onClick={() => {
                    // setting initial values for newIdentifier field
                    setFieldValue("newIdentifier", identifierInitialValues)
                    setFieldTouched("newIdentifier", false)
                    setIdentifierForm({ visible: true, fieldPath: "newIdentifier" })
                  }}
                />
              )}
            </div>

            {values.identifier && values?.identifier.length > 0 ? (
              values.identifier.map((value: Identifier, index: number) => (
                <IdentifierListItem
                  key={value.id ?? index}
                  identifier={value}
                  onForm={true}
                  onDelete={isAdmin ? () => setConfirmDeleteItem(index) : undefined}
                />
              ))
            ) : (
              <div className="flex flex-col items-center justify-center w-full my-7">
                <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
                <p className="text-slate-500 text-xs pt-1">No Identifiers found</p>
              </div>
            )}

            <PractIdentifierForm
              fieldPath={identifierForm.fieldPath}
              showForm={identifierForm.visible}
              onSaveForm={() => {
                addNewIdentifier(values.newIdentifier, "newIdentifier")
              }}
              closeForm={() => {
                toggleModal(false)
                setFieldTouched("newIdentifier", false)
                setFieldValue("newIdentifier", undefined)
              }}
            />
            <Dialog
              header="Confirmation"
              closable={false}
              draggable={false}
              visible={!identifierForm.visible && confirmDeleteItem >= 0}
              style={{ width: "25vw" }}
              onHide={() => setConfirmDeleteItem(-1)}
              footer={renderModalFooter(() => {
                confirmDeleteItem >= 0 && remove(confirmDeleteItem)
                setConfirmDeleteItem(-1)
              })}
            >
              <span>Are you sure you want to delete this Identifier?</span>
            </Dialog>
          </>
        )
      }}
    </FieldArray>
  )
}

type Props = {
  field: string
  label: string
}

export { PractitionerIdentifierContiner }
