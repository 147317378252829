import { IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import {
  ChargeItemDefinition,
  Coding,
  Invoice,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  Money,
  Organization,
  ParametersParameterArrayValue,
  PaymentReconciliation,
  PlanDefinition,
  Practitioner,
  PractitionerRole,
  Quantity,
  Questionnaire,
  QuestionnaireResponse,
  Reference,
  ServiceRequest,
  Task,
} from "fhir"
import { MessageProps } from "primereact/message"

export type FormatTypes =
  | "ISO_8601_DATE"
  | "ISO_8601_DATETIME"
  | "SHORT_DATE"
  | "SHORT_DATETIME"
  | "LONG_DATE"
  | "LONG_DATETIME"
  | "FULL_DATETIME"
  | "TIME"
  | "SHORT_DATETIME_W_SEC"
  | "LONG_MONTH_YEAR"
  | "SHORT_MONTH_YEAR"
  | "LONG_DATETIME_WITH_TIMEZONE"
  | "SHORT_DATETIME_WITH_TIMEZONE"
  | "FULL_DATETIME_WITH_TIMEZONE"

export enum MenuStyles {
  /** Show menu as in a dropdown */
  Dropdown,
  /** Show menu items inline */
  ActionItems,
  /** Only show first menu item with faExternalLink icon */
  ExternalAction,
}

export enum RoundedStyles {
  None = 0,
  Left = 1,
  Right = 2,
  Both = Left | Right,
  Full_Left = 4,
  Full_Right = 8,
  Full = Full_Left | Full_Right,
}

export type PractitionerInfo = {
  practitioner: Practitioner
  practitionerRole?: PractitionerRole
  practitionerRoleRef?: Reference
  practitionerRoles?: PractitionerRole[]
  hasDosespotSetup: boolean
}

export type CardClickableArea = "card" | "right-corner"

export type SuggestionProps<T> = { data?: Array<{ category: string; items: Array<T> }>; isLoading?: boolean }

export type QuestionnaireData = {
  questionnaire: Questionnaire
  response?: number
  qResponse?: QuestionnaireResponse
}

export type LaboratoryTest = {
  planDefinition: PlanDefinition
  display: string
  price?: Money
  questionnaires?: Questionnaire[]
}

export type LaboratoryComboTest = {
  combo: PlanDefinition
  laboratoryTests: LaboratoryTest[]
  price?: Money
}

export type MessageTextType = React.ReactNode | ((props: MessageProps) => React.ReactNode)

export type DialogAppendToType = "self" | HTMLElement | undefined | null

export type CustomError = Error & { cause: { name: string; message: string; traceId?: string } }

export type GroupedChargeItemDefinitionsCodes = {
  billToPracticeOrInsuranceCIDs?: (ParametersParameterArrayValue | Coding)[]
  billToPatientCIDs?: (ParametersParameterArrayValue | Coding)[]
}

export type AddressValidationResponse = {
  result: AddressValidationResultData
  responseId: string
}

export type AddressValidationDataAddress = {
  formattedAddress?: string
  postalAddress?: {
    revision?: number
    regionCode?: string
    languageCode?: string
    postalCode?: string
    sortingCode?: string
    administrativeArea?: string
    locality?: string
    sublocality?: string
    addressLines?: string[]
    recipients?: string[]
    organization?: string
  }
  addressComponents?: {
    componentName?: {
      text?: string
      languageCode?: string
    }
    componentType: string
    confirmationLevel: AddressValidationDataConfirmationLevels
    inferred?: boolean
    spellCorrected?: boolean
    replaced?: boolean
    unexpected?: boolean
  }[]

  missingComponentTypes?: string[]
  unconfirmedComponentTypes?: string[]
  unresolvedTokens?: string[]
}

type AddressValidationLatIng = {
  latitude?: number
  longitude?: number
}

export type AddressValidationResultData = {
  verdict?: {
    inputGranularity?: AddressValidationDataGranularityLevels
    validationGranularity?: AddressValidationDataGranularityLevels
    geocodeGranularity?: AddressValidationDataGranularityLevels
    addressComplete?: boolean
    hasUnconfirmedComponents?: boolean
    hasInferredComponents?: boolean
    hasReplacedComponents?: boolean
  }
  address?: AddressValidationDataAddress
  geocode?: {
    location?: AddressValidationLatIng
    plusCode?: {
      globalCode?: string
      compoundCode?: string
    }
    bounds?: {
      low?: AddressValidationLatIng
      high?: AddressValidationLatIng
    }
    featureSizeMeters?: number
    placeId?: string
    placeTypes?: string[]
  }
  metadata?: { business?: boolean; poBox?: boolean; residential?: boolean }
  uspsData?: {
    standardizedAddress?: {
      firstAddressLine?: string
      firm?: string
      secondAddressLine?: string
      urbanization?: string
      cityStateZipAddressLine?: string
      city?: string
      state?: string
      zipCode?: string
      zipCodeExtension?: string
    }
    deliveryPointCode?: string
    deliveryPointCheckDigit?: string
    dpvConfirmation?: string
    dpvFootnote?: string
    dpvCmra?: string
    dpvVacant?: string
    dpvNoStat?: string
    dpvNoStatReasonCode?: number
    dpvDrop?: string
    dpvThrowback?: string
    dpvNonDeliveryDays?: string
    dpvNonDeliveryDaysValues?: number
    dpvNoSecureLocation?: string
    dpvPbsa?: string
    dpvDoorNotAccessible?: string
    dpvEnhancedDeliveryCode?: string
    carrierRoute?: string
    carrierRouteIndicator?: string
    ewsNoMatch?: boolean
    postOfficeCity?: string
    postOfficeState?: string
    abbreviatedCity?: string
    fipsCountyCode?: string
    county?: string
    elotNumber?: string
    elotFlag?: string
    lacsLinkReturnCode?: string
    lacsLinkIndicator?: string
    poBoxOnlyPostalCode?: boolean
    suitelinkFootnote?: string
    pmbDesignator?: string
    pmbNumber?: string
    addressRecordType?: string
    defaultAddress?: boolean
    errorMessage?: string
    cassProcessed?: boolean
  }
  englishLatinAddress?: AddressValidationDataAddress
}

export type ComponentType =
  | "route"
  | "street_number"
  | "locality"
  | "administrative_area"
  | "postal_code"
  | "subpremise"

export type EffectiveComponentType = "line" | "locality" | "administrative_area" | "postal_code"

export enum AddressValidationDataConfirmationLevels {
  UNSPECIFIED = "CONFIRMATION_LEVEL_UNSPECIFIED",
  CONFIRMED = "CONFIRMED",
  UNCONFIRMED_BUT_PLAUSIBLE = "UNCONFIRMED_BUT_PLAUSIBLE",
  UNCONFIRMED_AND_SUSPICIOUS = "UNCONFIRMED_AND_SUSPICIOUS",
}

export enum AddressValidationDataGranularityLevels {
  GRANULARITY_UNSPECIFIED = "GRANULARITY_UNSPECIFIED",
  SUB_PREMISE = "SUB_PREMISE",
  PREMISE = "PREMISE",
  PREMISE_PROXIMITY = "PREMISE_PROXIMITY",
  BLOCK = "BLOCK",
  ROUTE = "ROUTE",
  OTHER = "OTHER",
}

export type MedicationRequestOrderData = {
  serviceRequest: ServiceRequest
  medicationRequest?: MedicationRequest
  medicationDispense?: MedicationDispense
  medicationKnowledge?: MedicationKnowledge
  chargeItemDefinition?: ChargeItemDefinition
  task?: Task
  invoice: Invoice
  hasAutoship?: boolean
  payRec?: PaymentReconciliation
}

export enum ValueSetIds {
  ICD10 = "icd-10",
  ALLERGY_CODE = "ch-allergy-intolerance-code",
  FAMILY_MEMBER = "v3-FamilyMember",
  RXNORM = "rxnorm",
  USPS_STATES = "usps-state",
  RELATIONSSHIP_TYPE = "relatedperson-relationshiptype",
  SERVICE_DELIVERY_LOCATION_TYPE = "v3-ServiceDeliveryLocationRoleType",
  DEVICE_TYPE = "device-type",
  MEDICATION_FORM_CODES = "medication-form-codes",
  REFERENCED_ITEM_CATEGORY = "referenced-item-category",
  AE_SUBTYPE = "ch-audit-event-subtype",
  CONTINENTAL_USA_STATES = "continental-usa-states",
  BODY_SITES = "body-sites",
  TASK_CODE = "ch-task-code",
  PACKAGING_QTY_UNIT = "packaging-quantity-unit",
  DOSE_QTY_UNIT = "dose-quantity-unit",
  PRACTITIONER_IDENTIFIERS = "practitioner-identifiers",
}

export type LaboratoryFacility = {
  lab: Organization
  ref: Reference
  isRequiredANByOrg: boolean
  isRequiredAN: boolean
}

export type FieldErrorType = [string, string | object | []]

export type LatestVitals = Record<
  "height" | "weight" | "heartRate" | "respiratoryRate" | "oxygenSaturation" | "temperature" | "bloodPressure",
  | {
      value: Quantity | string | undefined
      behavior: number
    }
  | undefined
>

export type TabPanelItem = {
  id: string
  visible: boolean
  header: { icon: IconDefinition; title: string }
  content: JSX.Element
}
