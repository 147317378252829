import { faMars, faVenus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PlanDefinition, codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import pluralize from "pluralize"
import { Skeleton } from "primereact/skeleton"
import { classNames } from "primereact/utils"
import { useMemo, useState } from "react"

import { BILLING_TYPES_CODES } from "data"
import { getMoneyCurrencyAlt } from "utils"

import { CardSelectionItem } from "../../../components/CardSelectionItem"
import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { ModalDialog } from "../../../components/ModalDialog"
import { FormField, FormFieldBaseProps } from "../../../forms/FormField"
import { LabComboTitle } from "../../../labs"
import { ComboDefinition, PlanData } from "../../types"

const ComboSelection = ({ combos, isLoadingPrices, isExemptLabPayment, ...formFieldProps }: Props) => {
  const [comboDetails, showComboDetails] = useState<ComboDefinition>()
  const [panelsToReplace, setPanelsToReplace] = useState<PlanDefinition[]>([])
  const [prevCombo, setPrevCombo] = useState<string>()

  const {
    values: { panels, combo, performer, billingType },
    setFieldValue,
  } = useFormikContext<PlanData>()

  const planCombos = useMemo(
    () => (performer ? combos?.filter((c) => c.performer.id === performer.id) : []),
    [performer, combos],
  )

  const handleComboSelection = (comboItem: ComboDefinition) => {
    /* Get PrevCombo selected and filter panels to replace */
    const prevCPanels = combos?.find((c) => c.canonical === prevCombo)?.canonicalPanels
    const filteredPanels = panels?.filter(
      (p) =>
        !comboItem.canonicalPanels.includes(p) &&
        !planCombos?.some((c) => c.canonical === p) &&
        !prevCPanels?.includes(p),
    )

    setFieldValue("panels", filteredPanels)
  }

  const onComboChange = (comboItem: ComboDefinition) => {
    const replacePanels =
      panels && combo !== comboItem.canonical
        ? comboItem.panels.filter((pd) => panels.includes(`${pd.url}|${pd.version}`))
        : []
    if (replacePanels.length === 0) handleComboSelection(comboItem)
    setPanelsToReplace(replacePanels)
    setPrevCombo(combo)
    setFieldValue("combo", combo !== comboItem.canonical ? comboItem.canonical : undefined)
  }

  const cancelComboSelection = () => {
    setFieldValue("combo", prevCombo)
    setPanelsToReplace([])
  }

  const confirmComboSelection = () => {
    handleComboSelection(planCombos?.find((c) => c.canonical === combo) as ComboDefinition)
    setPanelsToReplace([])
  }

  const isInsurance = billingType === BILLING_TYPES_CODES.INSURANCE

  return (
    <>
      {planCombos && planCombos.length > 0 && (
        <FormField
          field="combos"
          label={performer ? `${performer.display} combos` : "Combos"}
          showInvalidState
          labelAlign="items-start"
          {...formFieldProps}
        >
          <div className="space-y-2 overflow-y-auto p-1">
            {planCombos?.map((comboItem, index) => {
              const promo = comboItem.promoCoding
              const isPromoCombo = !!promo
              const isComboShowable = !(isInsurance && isPromoCombo)
              const gender = comboItem.definition.useContext?.find(({ code }) => code.code === "gender")?.value
                ?.CodeableConcept

              if (!isComboShowable) return null

              return (
                <div key={comboItem.canonical ?? index} className="relative overflow-hidden p-0.5">
                  {isPromoCombo && (
                    <div className="absolute left-0 top-0 h-6 w-6">
                      <div className="absolute flex items-center justify-center -left-7 top-2 w-24 transform -rotate-45 bg-red-500 bg-gradient-to-r from-red-400 to-red-600 shadow-lg  text-center text-white py-0.5 text-sm z-10">
                        {promo.display}
                      </div>
                    </div>
                  )}
                  <CardSelectionItem
                    mainText={comboItem.definition.title as string}
                    mainData={<LabComboTitle combo={comboItem.definition} />}
                    extraDetails={
                      <div className="flex space-x-1">
                        {gender && (
                          <span title={codeableConceptAsString(gender)} className="text-sm text-gray-400">
                            <FontAwesomeIcon
                              icon={gender.coding?.[0]?.code === "male" ? faMars : faVenus}
                              className="text-xs mr-1"
                            />
                          </span>
                        )}
                        <span title="Tests" className="text-sm text-gray-400">
                          {comboItem.panels.length} {pluralize("test", comboItem.panels.length)}
                        </span>
                      </div>
                    }
                    rightData={
                      isLoadingPrices ? (
                        <Skeleton className="min-w-10 min-h-4" />
                      ) : !isExemptLabPayment && comboItem.price?.value ? (
                        <p>
                          {getMoneyCurrencyAlt(comboItem.price.currency)}
                          {comboItem.price.value.toFixed(2)}
                        </p>
                      ) : undefined
                    }
                    rightDataClassName={classNames({ "line-through": isInsurance })}
                    onSelect={() => onComboChange(comboItem)}
                    selected={combo?.includes(`${comboItem.definition.url}|${comboItem.definition.version}`)}
                    showSelectedMark={false}
                    onShowDetails={() => showComboDetails(comboItem)}
                    clickableArea="card"
                    disabled={!performer}
                  />
                </div>
              )
            })}
          </div>
        </FormField>
      )}
      <ModalDialog
        title="Laboratory combo details"
        visible={!!comboDetails}
        onCancel={() => showComboDetails(undefined)}
        showCloseIcon
        showCancel
        cancelLabel="Close"
      >
        <div className="space-y-2">
          <div className="flex justify-between font-medium text-gray-900">
            <LabComboTitle combo={comboDetails?.definition as PlanDefinition} />
            {!isExemptLabPayment && comboDetails?.price?.value && (
              <span className={classNames({ "line-through": isInsurance })}>
                {getMoneyCurrencyAlt(comboDetails.price.currency)}
                {comboDetails.price.value.toFixed(2)}
              </span>
            )}
          </div>
          <div className="font-medium text-gray-900">Tests:</div>
          <div className="grid xl:grid-cols-2 col-auto gap-3">
            {comboDetails?.panels?.map((panel) => (
              <div key={panel.id} className="pl-2">
                {panel.title}
                <div className="text-gray-400 text-xs">{`${panel.publisher} - ${panel.identifier?.find((id) => id.system?.includes("sku"))?.value}`}</div>
              </div>
            ))}
          </div>
        </div>
      </ModalDialog>
      <ConfirmDialog
        headerTitle="Confirm panel replacement"
        visible={panelsToReplace.length > 0}
        onConfirm={confirmComboSelection}
        hideDialog={cancelComboSelection}
        waitToFinish
        confirmElement={
          <div className="flex flex-col gap-2">
            <p className="font-semibold">
              The following {pluralize("panel", panelsToReplace.length)} will be replaced by the combo panels
            </p>
            {panelsToReplace.map((panel) => (
              <div key={panel.id} className="pl-2">
                {panel.title}
              </div>
            ))}
          </div>
        }
      />
    </>
  )
}

type Props = {
  combos?: ComboDefinition[]
  isLoadingPrices?: boolean
  isExemptLabPayment?: boolean
} & Omit<FormFieldBaseProps, "field">

export { ComboSelection }
