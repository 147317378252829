import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, isObservation, Reference } from "fhir"
import { v4 } from "uuid"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { Laboratory } from "../../laboratory/types"
import { laboratoryQueryKeys } from "../query-keys"

const useUpdateLab = (closeForm: () => void, isNew: boolean) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const newLab = async ({ dr, results }: Laboratory) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: results.map((resource) => {
        const { id } = resource

        return {
          fullUrl: `urn:uuid:${v4()}`,
          request: {
            method: id ? "PUT" : "POST",
            url: `Observation${id ? `/${id}` : ""}`,
          },
          resource: { ...resource, resourceType: "Observation" },
        }
      }),
    }

    dr.result = bundle.entry?.reduce((prev, { fullUrl, resource }) => {
      if (isObservation(resource) && resource.id) {
        const { id, resourceType } = resource

        return [...prev, { id, resourceType }]
      }

      return [...prev, { uri: fullUrl }]
    }, [] as Reference[])

    bundle.entry?.push({
      resource: { ...dr, resourceType: "DiagnosticReport" },
      request: {
        method: dr.id ? "PUT" : "POST",
        url: `DiagnosticReport${dr.id ? `/${dr.id}` : ""}`,
      },
    })

    return transaction(bundle)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: newLab,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey: laboratoryQueryKeys.list(patientId), type: "all" })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: laboratoryQueryKeys.list(patientId), type: "all" })
      displayNotificationSuccess(`Laboratory ${isNew ? "created" : "updated"} successfully!`)
      datadogLogs.logger.info(`Laboratory ${isNew ? "created" : "updated"} successfully!`, { patientId })
    },
    onSettled: closeForm,
  })

  return { updateLab: mutate, isUpdating: isPending }
}

export { useUpdateLab }
