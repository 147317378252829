import { createContext, useContext } from "react"

import { Address } from "fhir"
import { FormikHelpers, FormikValues } from "formik"

import { AddressValidationDataAddress } from "../types"

const AddressContext = createContext<State>({
  addressComponents: undefined,
  recommendations: undefined,
  checkAddress: () => new Promise(() => {}),
  setRecommendations: () => {},
})
AddressContext.displayName = "AddressContext"

const useAddressContext = () => {
  const context = useContext(AddressContext)

  if (!context) throw new Error("useAddressContext must be used withing AddressContext")

  return context
}

type State = {
  addressComponents: AddressValidationDataAddress["addressComponents"]
  recommendations:
    | { missingTypes: string[]; fixableTypes: { componentType: string; value: string }[]; wrongTypes: string[] }
    | undefined
  checkAddress: (
    address: Address,
    formikHelpers?: FormikHelpers<FormikValues> | undefined,
    onAcceptedAddress?: (() => void) | undefined,
    parentFieldName?: string,
  ) => Promise<void>
  setRecommendations: React.Dispatch<
    React.SetStateAction<
      | { missingTypes: string[]; fixableTypes: { componentType: string; value: string }[]; wrongTypes: string[] }
      | undefined
    >
  >
}

export { AddressContext, useAddressContext }
