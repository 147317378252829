import { useQuery } from "@tanstack/react-query"
import { Composition, getResources, PlanDefinition } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { BILLING_TYPES_CODES } from "data"
import { convertIdentifiersToCodings, getBasePrice, getCidIdentifier, getCommonCode, getIndexedCID } from "utils"

import { useCIDQueryFunction } from "../hooks/useChargeItemDefinitions"
import { commonsQueryKeys } from "../query-keys"
import { LaboratoryTest } from "../types"

const useBloodDrawnTests = (organizationId: string, testIdentifiers?: string[], billingType?: BILLING_TYPES_CODES) => {
  const { search } = useClient()
  const queryKey = commonsQueryKeys.bloodDrawnTests(organizationId, billingType)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(testIdentifiers?.length ? { identifier: testIdentifiers.join(", ") } : {}),
        _include: "catalogHeader",
        _count: "20",
      })

      const respBundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinitions = getResources<PlanDefinition>(respBundle, "PlanDefinition")
      const catalogAuthors = getResources<Composition>(respBundle, "Composition")
      const codes = convertIdentifiersToCodings(planDefinitions)

      const { billToPracticeOrInsuranceCIDs, billToPatientCIDs } = await getChargeItemDefinitions(
        organizationId,
        billingType === BILLING_TYPES_CODES.BILL_PATIENT
          ? { billToPatientCIDs: codes }
          : { billToPracticeOrInsuranceCIDs: codes },
      )

      return {
        planDefinitions,
        chargeItemDefinitions: {
          billToPracticeOrInsuranceCIDs: Object.values(billToPracticeOrInsuranceCIDs),
          billToPatientCIDs: Object.values(billToPatientCIDs),
        },
        catalogAuthors,
        total: planDefinitions?.length ?? 0,
      }
    },
    enabled: !!testIdentifiers?.length,
    throwOnError: true,
    meta: { context: { queryKey, testIdentifiers } },
  })

  const bloodDrawnTests = useMemo(() => {
    const cids =
      billingType === BILLING_TYPES_CODES.BILL_PATIENT
        ? getIndexedCID(data?.chargeItemDefinitions?.billToPatientCIDs)
        : getIndexedCID(data?.chargeItemDefinitions?.billToPracticeOrInsuranceCIDs)

    const result = data?.planDefinitions?.reduce(
      (acc, pd) => {
        if (cids) {
          const codes = convertIdentifiersToCodings(pd ? [pd] : [])
          const cid = cids[getCidIdentifier(getCommonCode({ codes }))]

          const newLabTest = {
            planDefinition: pd,
            price: getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount,
            display: pd.title ?? pd.name ?? "Unknown",
          }
          const author = data?.catalogAuthors.find(({ id }) => pd.catalogHeader?.[0]?.id === id)?.author?.[0].id

          return {
            ...acc,
            [author as string]: [...(acc[author as string] ?? []), newLabTest],
          }
        }
        return acc
      },
      {} as Record<string, LaboratoryTest[] | undefined>,
    )

    return result
  }, [data])

  return {
    bloodDrawnTests,
    total: data?.total ?? 0,
    isLoading,
    isFetching,
  }
}

export { useBloodDrawnTests }
