import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { ParamKeyValuePair } from "react-router-dom"

import { useChartContext } from "chart-view"
import { KpListItem } from "commons"

const PatientSummaryKnowledgeItem = ({
  content,
  view,
  subView,
  externalAction,
  italic,
  isLoading,
  navOptions,
  label,
}: KnowledgeItemProp) => {
  const { showModule } = useChartContext()

  const navToView = (view: string, subView?: string) => {
    const moduleParams = { ...Object.fromEntries(navOptions ?? []), ...(subView ? { subview: subView } : {}) }
    showModule({ module: view, moduleParams })
  }

  const data = Array.isArray(content) ? content : [content]

  return (
    <div className="border-t border-gray-200 first:border-none px-4 py-5 sm:p-0">
      <dl>
        {data.map((itemContent, index) => (
          <KpListItem
            key={`${label}${index}`}
            label={index === 0 ? label : ""}
            content={itemContent}
            externalActionLabel={externalAction}
            onExternalLinkClick={() => navToView(view as string, subView)}
            grayed={italic}
            isLoading={isLoading}
          />
        ))}
      </dl>
    </div>
  )
}

type KnowledgeItemProp = {
  content: string | string[]
  isLoading?: boolean
  view?: string
  subView?: string
  externalAction?: string
  icon?: IconDefinition
  italic?: boolean
  navOptions?: ParamKeyValuePair[]
  label: string
}

export { PatientSummaryKnowledgeItem }
