import { faChevronDown, faChevronUp, faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Reference, asReference } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

import { MEDICATION_CATALOG } from "data"
import {
  MedicationRequestFormData,
  PrescriptionForm,
  PrescriptionSelectionItem,
  getMedicationFormData,
  getRxInitialValues,
  prescriptionValidationSchema,
  sanitize,
} from "eprescribe"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { Accordion } from "../../../components/Accordion"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { DataContainerSlideoverForm, FormField } from "../../../forms"
import { useCrudReducer } from "../../../hooks"
import { MedicationDetails, MedicationGroup, getMedCommonCode, useMedicationKnowledge } from "../../../meds"
import { PractitionerInfo } from "../../../types"
import { useMedsConfigData } from "../../hooks"
import { PLAN_ACTION_CODES, PlanData } from "../../types"
import { getGroupedActionMedCodes } from "../../utils"

const RxFormSection: FC<Props> = ({ practitionersInfo, openEncounter }) => {
  const { currentOrganizationId, loggedInPractitionerRole, currentOrganizationBillingAddress } =
    useOrganizationContext()
  const { patient } = usePatientContext()

  const {
    values: { configureActions, rx, planActivityDefinitions, requester },
    setFieldValue,
    getFieldMeta,
    registerField,
    validateForm,
  } = useFormikContext<PlanData>()

  const [selectedMed, previewMed] = useState<MedicationRequestFormData | undefined>(undefined)
  const { initialValue, editIndex, showSlide, editWithIndex, reset } = useCrudReducer({
    defaultEntity: getRxInitialValues({
      patient,
      loggedInPractitionerRole,
      practitionersInfo,
      encounter: openEncounter,
      requester,
      fallbackShippingAddress: currentOrganizationBillingAddress,
    }),
  })

  const medAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_RXS]
  const { groups: medPacks, medCodes: allowedMedCodes, requiredMeds } = getGroupedActionMedCodes(medAction)

  const { isLoading, mksBySku, catalogs } = useMedicationKnowledge({
    enabled: !!allowedMedCodes.length,
    medCodes: allowedMedCodes.join(","),
    category: MEDICATION_CATALOG.RX,
    hasCIDInOrg: currentOrganizationId,
  })

  const { allRequiredConfigured, medRecommendedDosage, configuredMedCodes, allowMultiple } =
    useMedsConfigData<MedicationRequestFormData>(medAction, requiredMeds, rx ?? [], planActivityDefinitions)

  const medicationsByGroup = useMemo(() => {
    return medPacks.reduce((acc, pack) => {
      const meds = pack.sku.reduce((acc, sku) => {
        const mk = mksBySku?.[sku]
        const pharmacy = catalogs[pack.catalog ?? (mk.catalogHeader?.[0]?.id as string)]
        const preConfigMR = configuredMedCodes?.[sku]
        const newMr = preConfigMR
          ? getMedicationFormData({ medicationKnowledge: mk, medicationRequestInfo: preConfigMR })
          : getRxInitialValues({
              patient,
              loggedInPractitionerRole,
              practitionersInfo,
              encounter: openEncounter,
              medicationKnowledge: mk,
              medRecommendedDosage,
              pharmacy: pharmacy && asReference(pharmacy),
              requester,
              fallbackShippingAddress: currentOrganizationBillingAddress,
            })

        return [...acc, ...(mk && newMr ? [newMr] : [])]
      }, Array<MedicationRequestFormData>())

      const group: MedicationGroup<MedicationRequestFormData> = { ...pack, medications: meds }

      if (meds.length) return [...acc, group]

      return [...acc]
    }, Array<MedicationGroup<MedicationRequestFormData>>())
  }, [mksBySku, configuredMedCodes, medRecommendedDosage, practitionersInfo])

  const onUpdateMR = useCallback(
    (mr: MedicationRequestFormData, index: number) => {
      const updatedMR = sanitize({ ...mr, encounter: openEncounter })
      setFieldValue("rx", [...(rx ?? [])].toSpliced(index > 0 ? index : 0, 1, updatedMR)).finally(reset)
    },
    [rx],
  )

  const { error, touched } = getFieldMeta("rx")

  const activeMedPackIndex = useMemo(
    () => medPacks.findIndex(({ sku }) => sku?.some((code) => Object.keys(configuredMedCodes).includes(code))),
    [configuredMedCodes],
  )

  const validate = useCallback(
    () => (!allRequiredConfigured ? "Please configure required prescriptions" : ""),
    [allRequiredConfigured],
  )

  useEffect(() => {
    validateForm()
  }, [allRequiredConfigured])

  const handleSelectRX = useCallback(
    (med: MedicationRequestFormData) => {
      const code = getMedCommonCode(med)
      const selectedIndex = Object.keys(configuredMedCodes).indexOf(code)
      if (selectedIndex !== -1) {
        setFieldValue("rx", [...(rx?.toSpliced(selectedIndex, 1) ?? [])])
      } else {
        const updatedMR = sanitize({ ...med, encounter: openEncounter })
        if (allowMultiple === true || (typeof allowMultiple === "number" && allowMultiple > (rx?.length ?? 0))) {
          setFieldValue("rx", [...(rx ?? []), updatedMR])
        } else {
          setFieldValue("rx", [updatedMR])
        }

        if (!updatedMR.dispenseRequest?.shippingAddress) {
          // Set the error avoiding replace by validate effect
          setTimeout(() => registerField("rx", { validate: () => "Invalid address for selected prescriptions" }))
        }
      }
    },
    [configuredMedCodes, allowMultiple, rx, openEncounter],
  )

  return (
    <div className="flex flex-col grow left-col h-min justify-stretch p-3">
      <FormField
        field="rx"
        showInvalidState
        label="Pharmaceuticals"
        labelAlign="items-start"
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium py-3"
        validation={validate}
        horizontal
      >
        <DataContainerSlideoverForm
          form={
            <PrescriptionForm
              practitionersInfo={practitionersInfo}
              allowedMedicationCodes={allowedMedCodes}
              medRecommendedDosage={medRecommendedDosage}
              isEditing={editIndex !== undefined}
              hidePrescriberField
            />
          }
          width="45%"
          hasData={!!medPacks.length || isLoading}
          onCancel={reset}
          formTitle="Prescription"
          showFab={false}
          slideContent="form"
          showSlide={editIndex !== undefined || showSlide}
          onSubmit={(data) => onUpdateMR(data, editIndex ?? rx?.length ?? 0)}
          formInitialValue={initialValue}
          validationSchema={prescriptionValidationSchema(practitionersInfo)}
          customEmptyMessage={
            <>
              <div
                className={classNames("flex flex-col items-center justify-center w-full py-4", {
                  "border border-red-600 rounded-lg": !!error && touched,
                })}
              >
                <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
                <p className="text-slate-500 text-xs pt-1">{`No prescriptions added`}</p>
              </div>
            </>
          }
        >
          {isLoading ? (
            <SkeletonLoader loaderType="two-lines" repeats={medPacks.length} />
          ) : (
            <div className="flex flex-col grow pb-5">
              <Accordion
                data={medicationsByGroup}
                headerTemplate={({ name }) => <h6 className="font-medium text-sm">{name}</h6>}
                contentTemplate={({ medications }) => (
                  <div className="flex flex-col space-y-3 grow px-4 py-0.5">
                    {medications?.map((item, itemIndex) => {
                      const selectedIndex = Object.keys(configuredMedCodes).indexOf(getMedCommonCode(item))
                      return (
                        <PrescriptionSelectionItem
                          key={item.id ?? itemIndex}
                          item={item}
                          onSelect={handleSelectRX}
                          onShowDetails={(data) => previewMed(data)}
                          edit={(data) =>
                            editWithIndex(
                              {
                                ...data,
                                dispenseRequest: {
                                  ...data.dispenseRequest,
                                  shippingAddress: data.dispenseRequest?.shippingAddress,
                                },
                              },
                              selectedIndex,
                            )
                          }
                          selected={selectedIndex !== -1}
                        />
                      )
                    })}
                  </div>
                )}
                className="paddingless"
                expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" className="p-accordion-toggle-icon mr-0" />}
                collapseIcon={<FontAwesomeIcon icon={faChevronUp} size="sm" className="p-accordion-toggle-icon mr-0" />}
                activeIndex={activeMedPackIndex}
              />
            </div>
          )}
        </DataContainerSlideoverForm>
      </FormField>

      <MedicationDetails medication={selectedMed} onHide={() => previewMed(undefined)} />
    </div>
  )
}

type Props = { practitionersInfo: PractitionerInfo[]; openEncounter?: Reference }

export { RxFormSection }
