import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, humanNameAsString, Patient, ServiceRequest } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { srCategoryCodes } from "data"
import { formatDate } from "utils"

import { getCategory, getOrderDetail } from "../utils"

const useOrganizationOrders = (
  organizationId: string,
  category?: string,
  patientId?: string,
  status?: string,
  authored?: Date,
  occurrence?: Date,
  searchText?: string,
) => {
  const { search } = useClient()
  const queryKey = ["org-orders", organizationId, category, patientId, status, searchText, authored, occurrence]

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<OrdersQueryData, Error>({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const statusFieldFilter = category === srCategoryCodes["lab-order"].code ? "orderDetail" : "status"

      const filters = new URLSearchParams({
        "subject:Patient.organization": organizationId,
        ...(searchText
          ? {
              _filter: `subject:Patient.termsearch eq ${searchText} or identifier eq ${searchText}`,
            }
          : {}),
        // ...(requester ? { "requester:text": requester } : {}),
        category: getCategory(category),
        ...(getOrderDetail(category) ? { orderDetail: getOrderDetail(category) } : {}),
        ...(status ? { [statusFieldFilter]: status } : {}),
        ...(patientId ? { subject: patientId } : {}),
        ...(authored ? { authored: formatDate(authored) } : {}),
        ...(occurrence ? { occurrence: formatDate(occurrence) } : {}),
        _include: "subject",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-_authored",
      })

      const bundle = await search({ endpoint: "ServiceRequest", filters, signal })

      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const patients = getResources<Patient>(bundle, "Patient")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        serviceRequests,
        patients,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey } },
  })

  const { serviceRequests } = useMemo(() => {
    const srs = data?.pages.flatMap((page) => page.serviceRequests) ?? []
    const patients = data?.pages?.flatMap((page) => page.patients) ?? []
    const indexedPatients = patients.reduce<Record<string, string>>(
      (acc, patient) => ({ ...acc, [patient.id as string]: humanNameAsString(patient.name?.[0]) }),
      {},
    )

    const serviceRequests = srs.reduce<ServiceRequest[]>(
      (acc, sr) => [
        ...acc,
        {
          ...sr,
          subject: {
            ...sr.subject,
            display: indexedPatients[sr.subject.id as string] ?? sr.subject.display,
          },
        },
      ],
      [],
    )

    return {
      serviceRequests,
    }
  }, [data?.pages])

  return {
    serviceRequests,
    isLoading,
    count: serviceRequests.length,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type OrdersQueryData = {
  serviceRequests: ServiceRequest[]
  patients: Patient[]
  next: number | undefined
  total: number
}

export { useOrganizationOrders }
