import { MedicationKnowledge } from "fhir"
import { FC } from "react"

import { Accordion } from "../../../components/Accordion"
import { LabComboTitle } from "../../../labs"
import { ComboDefinition, PanelDefinition } from "../../types"
import { MedicationItemDetail } from "./MedicationItemDetail"
import "./PlanDefinitionDetails.css"

const PlanDefinitionDetails: FC<Props> = ({ plan }) => (
  <Accordion
    data={[{ ...plan }]}
    onClick={(e) => e.stopPropagation()}
    headerTemplate={({ combos, panels, rx, nutraceuticals }) => (
      <div className="flex flex-1 justify-between text-sm w-full font-medium">
        <span>Test combos: {combos?.length}</span>
        <span>Test panels: {panels?.length}</span>
        {!!nutraceuticals?.length && <span>Nutraceuticals: {nutraceuticals?.length}</span>}
        {!!rx?.length && <span>Rx: {rx?.length}</span>}
        <span>Info</span>
      </div>
    )}
    contentTemplate={({ combos, panels, rx, nutraceuticals }) => (
      <div className="flex flex-col grow gap-2">
        {!!combos?.length && (
          <>
            <h6 className="font-medium text-sm">Combos</h6>
            {combos?.map((combo) => (
              <div key={combo.definition.id} className="pl-2 flex flex-col gap-2">
                <div className="font-normal text-sm text-slate-500 flex flex-1 justify-between whitespace-nowrap items-center">
                  <LabComboTitle combo={combo.definition} />
                  <hr className="w-full border-slate-300 ml-3" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-dense gap-2">
                  {combo.panels.map((p, index) => (
                    <span key={p.id ?? p.title ?? index} className="text-sm text-slate-400">
                      {p.title}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
        {!!panels?.length && (
          <div className="flex flex-col grow gap-2 mt-5">
            <h6 className="font-medium text-sm">Panels</h6>
            <ul className="pl-2 grid grid-flow-row grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 row-auto gap-2">
              {panels.map((panel) => (
                <li
                  key={panel.definition.id}
                  className="font-normal text-sm text-slate-500 whitespace-nowrap items-center"
                >
                  {panel.definition.title}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!!rx?.length && (
          <div className="flex flex-col grow gap-2 mt-5">
            <h6 className="font-medium text-sm">Rx</h6>
            <ul className="pl-2 grid grid-flow-row-dense grid-cols-2 xl:grid-cols-3 row-auto gap-2">
              {rx.map((mk) => (
                <MedicationItemDetail key={mk.id} mk={mk} />
              ))}
            </ul>
          </div>
        )}
        {!!nutraceuticals?.length && (
          <div className="flex flex-col grow gap-2 mt-5">
            <h6 className="font-medium text-sm">Nutraceuticals</h6>
            <ul className="pl-2 grid grid-flow-row grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 row-auto gap-2">
              {nutraceuticals.map((mk) => (
                <MedicationItemDetail key={mk.id} mk={mk} />
              ))}
            </ul>
          </div>
        )}
      </div>
    )}
  />
)

type Props = {
  plan?: {
    combos: ComboDefinition[]
    panels: PanelDefinition[]
    nutraceuticals?: MedicationKnowledge[]
    rx?: MedicationKnowledge[]
  }
}
export { PlanDefinitionDetails }
