import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { CustomError } from "../types"

const useSendToPatient = (onSettled?: () => void) => {
  const { operationRequest } = useClient()

  const sendToPatient = async (id: string) =>
    operationRequest({ endpoint: "Invoice", method: "POST", operation: "send-to-patient", id })

  const { mutate: sendOrderToPatient, isPending } = useMutation({
    mutationFn: sendToPatient,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: (data) => {
      displayNotificationSuccess("Invoice sent to patient successfully!")
      datadogLogs.logger.info(`Invoice sent to patient successfully!`, { data })
    },
    onSettled: onSettled,
  })

  return { sendOrderToPatient, isSendingToPatient: isPending }
}

export { useSendToPatient }
