import { PlanDefinitionActionArrayActionArray, Reference } from "fhir"
import { FC, useEffect } from "react"

import { useStepFormContext } from "../../../../hooks"
import { PractitionerInfo } from "../../../../types"
import { PLAN_ACTION_CODES } from "../../../types"
import { NutrasFormSection } from "../NutrasFormSection"
import { RxFormSection } from "../RxFormSection"

const Medications: FC<Props> = ({
  configureActions,
  practitionersInfo,
  openEncounter,
  requiresNutras,
  requiresRXs,
  stepId,
}) => {
  const { toogleOptionalStep } = useStepFormContext()

  const medActions = [
    ...(requiresRXs ? [configureActions[PLAN_ACTION_CODES.CONFIGURE_RXS]] : []),
    ...(requiresNutras ? [configureActions[PLAN_ACTION_CODES.CONFIGURE_NUTRAS]] : []),
  ]
  const isOptionalStep = !medActions.every((act) => act.requiredBehavior === "must")

  useEffect(() => {
    toogleOptionalStep?.({ stepId, optional: isOptionalStep })
  }, [isOptionalStep])

  return (
    <>
      {requiresRXs && <RxFormSection practitionersInfo={practitionersInfo} openEncounter={openEncounter} />}
      {requiresNutras && <NutrasFormSection practitionersInfo={practitionersInfo} openEncounter={openEncounter} />}
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  openEncounter?: Reference
  requiresRXs: boolean
  requiresNutras: boolean
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  stepId: string
}

export { Medications }
