import { useSuspenseQuery } from "@tanstack/react-query"
import { getResources, Organization } from "fhir"

import { useClient } from "api"

import { organizationQueryKeys } from "../query-keys"

const usePractitionerOrganizations = (practitionerId?: string) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.withPractitionerId(practitionerId)

  const filters = new URLSearchParams({
    type: "healthcare-provider",
    active: "true",
    _count: "1000",
  })

  const { data } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) =>
      search({ endpoint: `Practitioner/${practitionerId}`, filters, operation: "allowed-organizations", signal }).then(
        (bundle) => getResources<Organization>(bundle),
      ),
    meta: { context: { queryKey, practitionerId } },
  })

  return { organizations: data }
}

export { usePractitionerOrganizations }
