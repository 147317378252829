import { useInfiniteQuery } from "@tanstack/react-query"
import {
  getResources,
  Invoice,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  PaymentReconciliation,
  ServiceRequest,
  Task,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { MedicationRequestOrderData } from "commons/types"
import { hasMedAutoship } from "utils"

import { medsQueryKeys } from "../meds_query_keys"

const useMrOrdersResources = (
  patientId: string,
  subcategory: "nutraceutical-order" | "medication-order",
  statusFilter: string[],
  searchText?: string,
) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.ordersResources(patientId, subcategory, statusFilter, searchText)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    MedicationRequestOrdersResourcesData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _query: "patient-medication-orders",
        _patient: patientId,
        _subcategory: subcategory,
        ...(searchText ? { _filter: searchText.toUpperCase() } : {}),
        ...(statusFilter.length > 0 ? { _status: statusFilter.join(",") } : {}),
        _count: "20",
        _order: "desc",
        _page: `${pageParam}`,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const tasks = getResources<Task>(bundle, "Task")
      const invoices = getResources<Invoice>(bundle, "Invoice")
      const payRec = getResources<PaymentReconciliation>(bundle, "PaymentReconciliation")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        serviceRequests,
        tasks,
        payRec,
        medicationRequests,
        medicationDispenses,
        medicationKnowledges,
        invoices,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId, statusFilter, searchText } },
  })

  const { mrOrderData, medicationRequests, medicationKnowledges } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.serviceRequests)
    const tasks = data?.pages.flatMap((page) => page.tasks)
    const invoices = data?.pages.flatMap((page) => page.invoices)
    const payRecs = data?.pages.flatMap((page) => page.payRec)
    const medicationRequests = data?.pages.flatMap((page) => page.medicationRequests)
    const medicationKnowledges = data?.pages.flatMap((page) => page.medicationKnowledges)

    const mrData = medicationRequests?.reduce<Record<string, MedicationRequest>>(
      (prev, mr) => ({ ...prev, [mr.id as string]: mr }),
      {},
    )

    const result = newData?.reduce((acc, sr) => {
      const taskSR = tasks?.find(
        (task) => task.code?.coding?.[0]?.code === "dispense-medications" && task.focus?.id === sr.id,
      )
      const taskInvoice = tasks?.find((task) => task.id === taskSR?.dependsOn?.[0].id)
      const invoice = invoices?.find((inv) => inv.id === taskInvoice?.focus?.id) as Invoice
      const payRec = invoice && payRecs?.find((pay) => pay?.R5_request.id === invoice.id)

      const medicationRequests =
        sr.basedOn?.reduce<Array<MedicationRequest>>(
          (acc, ref) => (mrData?.[ref.id as string] ? [...acc, mrData[ref.id as string]] : [...acc]),
          [],
        ) ?? []

      acc = [
        ...acc,
        {
          serviceRequest: sr as ServiceRequest,
          invoice,
          payRec,
          hasAutoship: hasMedAutoship(medicationRequests),
        },
      ]
      return acc
    }, new Array<MedicationRequestOrderData>())

    return {
      mrOrderData: result,
      medicationRequests,
      medicationKnowledges,
      total: data?.pages?.[0]?.total ?? 0,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    mrOrderData,
    medicationRequests,
    medicationKnowledges,
    isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadOrders: refetch,
  }
}

type MedicationRequestOrdersResourcesData = {
  serviceRequests: ServiceRequest[]
  payRec: PaymentReconciliation[]
  tasks: Task[]
  medicationRequests: MedicationRequest[]
  medicationDispenses: MedicationDispense[]
  medicationKnowledges: MedicationKnowledge[]
  invoices: Invoice[]
  next: number | undefined
  total: number
}

export { useMrOrdersResources }
