import { useMutation } from "@tanstack/react-query"
import { DocumentReference } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess, openLinkInNewTab, sanitizeURL } from "utils"

const usePrescriptionMrOrder = (hideDialog?: () => void) => {
  const { operationRequest, getSignedUrl } = useClient()

  const getOrderPrescription = async ({
    serviceRequestId,
    forceRefresh,
  }: {
    serviceRequestId: string
    forceRefresh?: boolean
  }) => {
    const filters = new URLSearchParams({ "force-refresh": "true" })

    return operationRequest<DocumentReference>({
      endpoint: "ServiceRequest",
      method: "POST",
      operation: "prescription",
      id: serviceRequestId,
      filters: forceRefresh ? filters : undefined,
    })
  }

  const { mutate, isPending, data } = useMutation({
    mutationFn: getOrderPrescription,
    onError: (error: CustomError, serviceRequestId) => {
      hideDialog?.()
      displayNotificationError(registerErrorTrace(error, { serviceRequestId }))
    },

    onSuccess: async (data, { forceRefresh }) => {
      if (forceRefresh) {
        displayNotificationSuccess("The prescription has been updated with the most recent changes")
        datadogLogs.logger.info("The prescription has been updated with the most recent changes", { data })
      } else {
        if (data.content[0].attachment.url) {
          const url = data.content[0].attachment.url.startsWith("/")
            ? data.content[0].attachment.url.slice(1)
            : data.content[0].attachment.url
          const signedUrl = await getSignedUrl(sanitizeURL(url))
          if (signedUrl.url) openLinkInNewTab(signedUrl.url)
        } else {
          displayNotificationError(new Error("Wrong prescription url") as CustomError)
        }
      }
    },
  })

  return { getPrescription: mutate, isGettingPrescription: isPending, prescription: data }
}

export { usePrescriptionMrOrder }
