import { useInfiniteQuery } from "@tanstack/react-query"
import {
  CarePlan,
  DiagnosticReport,
  getResources,
  isCarePlan,
  isDiagnosticReport,
  isObservation,
  isQuestionnaireResponse,
  isServiceRequest,
  Observation,
  ServiceRequest,
} from "fhir"
import groupBy from "lodash/groupBy"
import { useMemo } from "react"

import { useClient } from "api"
import { ListGroup } from "commons"
import { srCategoryCodes } from "data"
import { getLoincCode } from "utils"

import { mcQueryKeys } from "../query-keys"
import { CarePlanWithDR } from "../types"
import { getSRStatusOrder, renameSRStatus, sortCarePlanItems } from "../utils"

const usePatientPlans = (patientId: string, searchText?: string, statusFilter?: string[]) => {
  const { search } = useClient()
  const queryKey = mcQueryKeys.list(patientId, searchText, statusFilter)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    PatientPlansQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "200",
        _page: `${pageParam}`,
        _sort: "-authored",
        category: srCategoryCodes["algorithm-order"].code as string,
        _include:
          "supporting-info:DiagnosticReport,DiagnosticReport:result,supporting-info:QuestionnaireResponse,supporting-info:ServiceRequest",
        _revinclude: "DiagnosticReport:based-on:ServiceRequest,CarePlan:supporting-info:ServiceRequest",
        ...(searchText ? { title: searchText } : {}),
        ...(statusFilter?.length ? { status: statusFilter.join(",") } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const carePlans = getResources<CarePlan>(bundle, "CarePlan")
      const diagnosticReports = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest").filter(
        ({ intent }) => intent === "plan",
      )
      const observations = getResources<Observation>(bundle)

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        carePlans,
        diagnosticReports,
        serviceRequests,
        observations,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { groupedCarePlans, orders } = useMemo(() => {
    const carePlansBySR = data?.pages
      .flatMap((page) => page.carePlans ?? [])
      ?.reduce(
        (acc, cp) => {
          const sr = cp.supportingInfo?.find((ref) => isServiceRequest(ref))
          if (sr?.id) return { ...acc, [sr?.id as string]: cp }
          return acc
        },
        {} as Record<string, CarePlan>,
      )

    const diagnosticReports = data?.pages.flatMap((page) => page.diagnosticReports) ?? []
    const serviceRequests = data?.pages.flatMap((page) => page.serviceRequests) ?? []
    const observations = data?.pages.flatMap((page) => page.observations) ?? []

    const diagnosticReportsByPlan = diagnosticReports.reduce<Record<string, DiagnosticReport>>((acc, dr) => {
      const drBasedOnPlan = dr.basedOn?.find(isCarePlan)

      if (drBasedOnPlan?.id) {
        return { ...acc, [drBasedOnPlan.id]: dr }
      }

      return acc
    }, {})

    const questionnaireResponseBySR = serviceRequests.reduce<Record<string, string>>((acc, sr) => {
      const questionnaireResponse = sr.supportingInfo?.find(isQuestionnaireResponse)?.id
      if (!!questionnaireResponse && !!sr.id) {
        return { ...acc, [sr.id]: questionnaireResponse }
      }

      return acc
    }, {})

    const carePlansWithDR = serviceRequests.map((sr) => {
      const plan = carePlansBySR?.[sr.id as string]
      const dr = diagnosticReportsByPlan[plan?.id ?? ""]
      const drOrder = diagnosticReports.find(({ id }) =>
        sr.supportingInfo?.some((ref) => isDiagnosticReport(ref) && ref.id === id),
      )
      const drObservations = observations
        .filter(({ id }) => drOrder?.result?.some((ref) => isObservation(ref) && ref.id === id))
        ?.reduce(
          (acc, o) => {
            return {
              data: { ...acc.data, [o.id as string]: o },
              values: {
                ...acc.values,
                [getLoincCode(o.code.coding)]: o.value?.Quantity?.value?.toString() ?? o.value?.string ?? "",
              },
            }
          },
          { data: {}, values: {} } as {
            values: Record<string, string>
            data: Record<string, Observation>
          },
        )

      const results = drOrder ? { dr: drOrder, observations: drObservations } : undefined

      return {
        plan,
        diagnosticReport: dr,
        qrId: questionnaireResponseBySR[sr?.id ?? ""],
        order: sr,
        results,
      } as CarePlanWithDR
    })

    const groupedCarePlansByStatus = groupBy(carePlansWithDR, ({ order }) => renameSRStatus(order.status))

    const carePlansListGroup: ListGroup<CarePlanWithDR>[] = Object.entries(groupedCarePlansByStatus)
      .sort(([statusA], [statusB]) => getSRStatusOrder(statusA) - getSRStatusOrder(statusB))
      .map(([key, items = []]) => ({
        key,
        name: key,
        items: sortCarePlanItems(items, key),
      }))

    return {
      groupedCarePlans: carePlansListGroup,
      orders: serviceRequests,
    }
  }, [data?.pages])

  return {
    groupedCarePlans,
    orders,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type PatientPlansQueryData = {
  carePlans: CarePlan[] | undefined
  diagnosticReports: DiagnosticReport[]
  serviceRequests: ServiceRequest[]
  observations: Observation[]
  next: number | undefined
  total: number
}

export { usePatientPlans }
