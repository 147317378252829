import { FC, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId, SectionContainer, SkeletonLoader } from "commons"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { AppSubModules } from "app-modules"
import { CPOERequestsContextProvider } from "../context/CPOERequestsProvider"
import { useCpoeOrders } from "../hooks"
import { CPOE_STEPS } from "../types"
import { OrdersFinish } from "./OrdersFinish"
import { OrdersFooterActions } from "./OrdersFooterActions"
import { OrdersList } from "./OrdersList"

const CheckoutContainer: FC = () => {
  const [searchParams] = useSearchParams()
  const { currentOrganizationId, currentOrganizationReaderAccounts } = useOrganizationContext()
  const { patientId, patient } = usePatientContext()
  const { activeSubModule, closeModule, setActiveSubModule } = useChartContext()

  const {
    cpoeRequests,
    chargeItemDefintions,
    activeRequests,
    requestGroup,
    activityDefinition,
    requestShippingMethods,
    requestDiscounts,
    requestCoverage,
    isLoading,
    isError,
    isOnlyDfo,
    isOnlyMedication,
  } = useCpoeOrders(currentOrganizationId, patientId)

  const readerAccount = useMemo(
    () =>
      currentOrganizationReaderAccounts?.find(({ id }) =>
        requestGroup?.action?.[1]?.action?.some((a) => a.resource?.localRef === id),
      ),
    [requestGroup?.action?.[1], currentOrganizationReaderAccounts],
  )

  // Show final step from outside chart
  useEffect(() => {
    if (searchParams.get("view") === ModulesId.CHECKOUT && searchParams.get("subview") === CPOE_STEPS.FINISH) {
      setActiveSubModule(AppSubModules.checkout[CPOE_STEPS.FINISH])
    }
  }, [])

  if (isLoading) return <SkeletonLoader repeats={4} loaderType="two-lines" />
  if (!cpoeRequests.length || searchParams.get("view") !== ModulesId.CHECKOUT) {
    return null
  }

  return (
    <CPOERequestsContextProvider
      requestGroup={requestGroup}
      actions={cpoeRequests}
      chargeItemDefinitions={chargeItemDefintions}
      activityDefinition={activityDefinition}
      initialActiveRequests={activeRequests}
      requestDiscounts={requestDiscounts}
      initialCoverageByType={requestCoverage}
      requestShippingMethods={requestShippingMethods}
      showShippingMethods={true}
      showBillingTypes={true}
      isError={isError}
      isOnlyDfo={isOnlyDfo}
      isOnlyMedications={isOnlyMedication}
      patientId={patientId}
      patient={patient}
      initialReaderAccount={readerAccount}
    >
      <SectionContainer>
        {activeSubModule ? (
          <div className="inline-flex justify-between space-x-3 overflow-hidden w-full h-full">
            <div className="flex-1 overflow-y-auto grow">{activeSubModule.renderContent()}</div>
            <div className="w-2/5 border-2 p-3 pt-0 rounded-lg flex flex-col overflow-x-hidden overflow-y-auto max-h-full">
              <h3 className="font-medium sticky top-0 left-0 bg-white py-3 z-10">Summary</h3>
              <OrdersFinish />
            </div>
          </div>
        ) : (
          <OrdersList />
        )}
      </SectionContainer>
      <div className="flex flex-shrink-0 border-t-2 justify-end gap-3 px-1 py-4 mt-3">
        <OrdersFooterActions closeCheckout={closeModule} />
      </div>
    </CPOERequestsContextProvider>
  )
}

export { CheckoutContainer }
