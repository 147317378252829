import { addDays } from "date-fns/addDays"
import { formatDate } from "date-fns/format"
import {
  ActivityDefinition,
  ActivityDefinitionParticipantArray,
  Invoice,
  Reference,
  RequestGroup,
  humanNameAsString,
} from "fhir"
import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

import { AppSubModules } from "app-modules"
import { useChartContext } from "chart-view"
import { ActionProps, Button, ModulesId, SplitButton } from "commons"
import { formatsByTypes } from "data"
import { intercomTrackEvent } from "intercom"
import { useOrganizationContext } from "organization"
import { useAuth } from "security"

import { useCPOERequestsContext, useCpoeOrdersFinish, useUpdateCpoeCids } from "../hooks"
import { CPOE_STEPS, PaymentDevicePerformer } from "../types"
import { CPOE_ACTIONS, getInvoiceMeta } from "../utils"

const OrdersFooterActions: FC<{ closeCheckout(): void }> = ({ closeCheckout }) => {
  const { user } = useAuth()
  const { isExemptLabPayment } = useOrganizationContext()
  const { showSubModule, showModule, activeSubModule } = useChartContext()

  const {
    patientId,
    patient,
    requestGroup,
    activityDefinition,
    selectedRequests,
    isProcessingActions,
    selectedDiscounts,
    selectedShippingMethods,
    actions,
    isProcessEnabled,
    readerAccount,
    coverageByType,
    isError,
    updateRG,
    activeRequestsInfo: {
      hasBillPatientLabRequest,
      hasBillToPracticeLabRequest,
      hasRXRequest,
      hasNutraRequest,
      hasLabsRequest,
      hasInsuranceLabsRequest,
      hasMedsRequest,
      hasPlanBasedRequest,
      hasProcedureRequest,
      insuranceRequests,
    },
    checkoutAddressInfo: { missingAddresses, missingLabsShippingAddress, missingMedsShippingAddress },
    unmetRequirementsToInvoicePreview,
  } = useCPOERequestsContext()

  const [scheduleDate, setScheduleDate] = useState<Date>()
  const [showScheduleDialog, setShowScheduleDialog] = useState(false)

  const hasOnlyMR = hasMedsRequest && !hasLabsRequest && !hasInsuranceLabsRequest && !hasProcedureRequest

  const checkoutStep = useMemo(() => activeSubModule?.getId() ?? CPOE_STEPS.SUMMARY, [activeSubModule])
  const isFirstStep = checkoutStep === CPOE_STEPS.SUMMARY
  const isLastStep = checkoutStep === CPOE_STEPS.FINISH

  useEffect(() => {
    if (!actions?.length) closeCheckout()
  }, [actions?.length])

  const moveStep = useCallback(
    (previous?: boolean) => {
      switch (checkoutStep) {
        case CPOE_STEPS.SUMMARY:
          if (!previous) showSubModule({ subModule: AppSubModules.checkout[ModulesId.CHECKOUT_CONFIG] })
          break
        case CPOE_STEPS.FINISH:
          if (previous) showModule({ module: ModulesId.CHECKOUT })
          break
      }
    },
    [checkoutStep],
  )

  const { updateCpoeCidsAD, isUpdatingCids } = useUpdateCpoeCids(moveStep)

  const showDetails = ({
    invoice,
    processedRequestRefs,
  }: {
    invoice?: Invoice[]
    processedRequestRefs?: Reference[]
  }) => {
    let newParams = {}
    if (invoice?.length) {
      if (invoice.length === 1) {
        newParams = { invoiceId: invoice[0].id as string }
      }

      if (user) {
        invoice.forEach((inv) => {
          const invoiceMeta = invoice && getInvoiceMeta(inv)
          intercomTrackEvent({
            user_id: user.b2cUserId,
            email: user.email,
            event: "order-placed",
            metadata: {
              order: {
                url: `${window.location.href.split("?")[0]}?view=${ModulesId.INVOICE}&invoiceId=${inv.id}`,
                value: inv.id as string,
              },
              ...invoiceMeta,
              ...{ order_for: patient.name ? humanNameAsString(patient.name?.[0]) : invoiceMeta?.order_for },
            },
          })
        })
      }

      showModule({ module: ModulesId.INVOICE, moduleParams: newParams })
    } else if (processedRequestRefs?.length) {
      const insuranceRequest = insuranceRequests.find(
        ({ resource }) => resource.resource?.id === processedRequestRefs[0].id,
      )
      if (insuranceRequest?.laboratoryData?.carePlanId)
        showModule({
          module: ModulesId.PLANS,
          moduleParams: { subview: ModulesId.PLAN_DETAILS, planId: insuranceRequest.laboratoryData.carePlanId },
        })
      else if (insuranceRequest?.laboratoryData?.serviceRequest.id)
        showModule({
          module: ModulesId.LABSORDER,
          moduleParams: { order: insuranceRequest.laboratoryData.serviceRequest.id },
        })
      // TODO: Handle other insurance orders if any
      else closeCheckout()
    } else closeCheckout()
  }

  const { ordersFinish, isFinishing } = useCpoeOrdersFinish(
    (_, error) => {
      if (error) closeCheckout()
    },
    showDetails,
    {
      hasLabsRequests: hasLabsRequest,
      hasNutraRequests: hasNutraRequest,
      hasPlanBasedRequests: hasPlanBasedRequest,
      hasProcedureRequests: hasProcedureRequest,
      hasRXRequests: hasRXRequest,
    },
    false,
  )

  const isLoading = isFinishing || isProcessingActions || isUpdatingCids

  const finishCheckout = useCallback(
    (action?: string) => {
      let performer: ActivityDefinitionParticipantArray | undefined = undefined

      switch (action) {
        case CPOE_ACTIONS.PROCESS:
        case CPOE_ACTIONS.SCHEDULE_ORDER:
          performer = activityDefinition?.participant?.find((participant) => participant.type === "device")
          break
        case CPOE_ACTIONS.SEND_TO_PATIENT:
          performer = activityDefinition?.participant?.find((participant) => participant.type === "patient")
          break
        case CPOE_ACTIONS.SAVE_DRAFT:
          performer = activityDefinition?.participant?.find((participant) => participant.type === "practitioner")
          break
      }

      const selectedAd: ActivityDefinition | undefined = activityDefinition && {
        ...activityDefinition,
        participant: readerAccount
          ? [PaymentDevicePerformer]
          : performer
            ? [performer]
            : activityDefinition.participant,
      }

      if (requestGroup && selectedAd) {
        ordersFinish({
          patientId: patientId,
          requestGroup: requestGroup,
          activityDefinition: selectedAd as ActivityDefinition,
          scheduleDate: action === CPOE_ACTIONS.SCHEDULE_ORDER ? scheduleDate?.toISOString() : undefined,
        })
      }
    },
    [requestGroup, scheduleDate, readerAccount],
  )

  const finishActions = useMemo(() => {
    const hasUnmetRequirements = checkoutStep === CPOE_STEPS.FINISH && unmetRequirementsToInvoicePreview.isInvalid
    const actions: Array<ActionProps> = [
      {
        label: CPOE_ACTIONS.PROCESS,
        onSelectClick: () => finishCheckout(CPOE_ACTIONS.PROCESS),
        description: "The invoice will be generated and charged automatically",
        disabledReason: hasUnmetRequirements
          ? (unmetRequirementsToInvoicePreview.message as string)
          : "Credit card is required",
        disabled:
          hasUnmetRequirements ||
          (!isProcessEnabled &&
            !(
              hasInsuranceLabsRequest &&
              !hasMedsRequest &&
              !hasBillPatientLabRequest &&
              !hasProcedureRequest &&
              !hasPlanBasedRequest
            )),
      },
      ...(hasBillPatientLabRequest || hasMedsRequest
        ? [
            {
              label: CPOE_ACTIONS.SEND_TO_PATIENT,
              onSelectClick: () => finishCheckout(CPOE_ACTIONS.SEND_TO_PATIENT),
              description: "The invoice will be generated and a link will be sent to the patient",
            },
          ]
        : []),
      {
        label: CPOE_ACTIONS.SAVE_DRAFT,
        onSelectClick: () => finishCheckout(CPOE_ACTIONS.SAVE_DRAFT),
        description: "The invoice will be generated, but will not be processed or charged",
      },
      ...(hasOnlyMR
        ? [
            {
              label: CPOE_ACTIONS.SCHEDULE_ORDER,
              onSelectClick: () => {
                setShowScheduleDialog(true)
              },
              description: "This order will be scheduled for the date you select",
              disabled: missingMedsShippingAddress,
              disabledReason: `Required address for nutraceuticals not specified`,
            },
          ]
        : []),
    ]

    return actions
  }, [
    finishCheckout,
    showScheduleDialog,
    hasBillPatientLabRequest,
    isProcessEnabled,
    hasMedsRequest,
    hasInsuranceLabsRequest,
    hasPlanBasedRequest,
    hasProcedureRequest,
    missingAddresses,
    missingLabsShippingAddress,
    missingMedsShippingAddress,
    checkoutStep,
  ])

  const handleUpdateRG = useCallback(() => {
    const newRequestsAction = {
      ...requestGroup?.action?.[0],
      action: [...actions.map(({ resource }) => resource)],
    }
    const updatedRG: RequestGroup = {
      ...(requestGroup ?? { intent: "order", status: "draft" }),
      action: [newRequestsAction, ...(requestGroup?.action?.slice(1) ?? [])],
    }
    updateRG(updatedRG)
    return updatedRG
  }, [requestGroup, actions, updateRG])

  const onNextClick = useCallback(() => {
    const updatedRG = handleUpdateRG()
    updateCpoeCidsAD({
      shippingMethod: !hasMedsRequest ? undefined : selectedShippingMethods,
      coverageByType: !hasMedsRequest ? undefined : coverageByType,
      discounts: !hasMedsRequest && !hasLabsRequest ? undefined : selectedDiscounts,
      requestGroup: updatedRG,
      readerAccount,
    })
    moveStep()
  }, [
    checkoutStep,
    coverageByType,
    selectedShippingMethods,
    selectedDiscounts,
    readerAccount,
    hasMedsRequest,
    hasLabsRequest,
    moveStep,
    handleUpdateRG,
  ])

  const nextActions = useMemo(() => {
    const noRequestsSelected = checkoutStep === CPOE_STEPS.SUMMARY && !selectedRequests.length

    const actions: Array<ActionProps> = [
      {
        label: CPOE_ACTIONS.NEXT,
        onSelectClick: onNextClick,
        description: "Go to the next step",
        disabledReason: noRequestsSelected ? "Select at least one item" : "Error",
        disabled: noRequestsSelected,
      },
      ...((hasBillPatientLabRequest || hasMedsRequest) && checkoutStep === CPOE_STEPS.FINISH
        ? [
            {
              label: CPOE_ACTIONS.SEND_TO_PATIENT,
              onSelectClick: () => finishCheckout(CPOE_ACTIONS.SEND_TO_PATIENT),
              description: "The invoice will be generated and a link will be sent to the patient",
            },
          ]
        : []),
      {
        label: CPOE_ACTIONS.SAVE_CLOSE,
        onSelectClick: () => {
          const updatedRG = handleUpdateRG()

          updateCpoeCidsAD({
            shippingMethod: !hasMedsRequest ? undefined : selectedShippingMethods,
            coverageByType: !hasMedsRequest ? undefined : coverageByType,
            discounts: !hasMedsRequest && !hasLabsRequest ? undefined : selectedDiscounts,
            requestGroup: updatedRG,
            readerAccount,
          })

          closeCheckout()
        },
        description: "All configurations will be saved, but will not be processed or charged",
      },
    ]

    return actions
  }, [
    handleUpdateRG,
    onNextClick,
    hasLabsRequest,
    hasMedsRequest,
    hasBillToPracticeLabRequest,
    hasInsuranceLabsRequest,
    hasBillPatientLabRequest,
    requestGroup,
    selectedDiscounts,
    coverageByType,
    selectedRequests.length,
    selectedShippingMethods,
    readerAccount,
    checkoutStep,
  ])

  return (
    <>
      {!!scheduleDate && (
        <span
          className="flex justify-end items-center text-primary cursor-pointer"
          onClick={() => {
            setShowScheduleDialog(true)
          }}
        >
          <span className="font-semibold mr-1">Order Held Until:</span>
          <span>{formatDate(scheduleDate, formatsByTypes.LONG_DATE)}</span>
        </span>
      )}
      <Dialog
        closable={true}
        header="Schedule order"
        visible={showScheduleDialog}
        draggable={false}
        style={{ width: "35vw" }}
        onHide={() => setShowScheduleDialog(false)}
        footer={({ onHide }) => (
          <div className="mt-8 space-x-2">
            <Button
              label="Cancel"
              size="xl"
              buttonStyle="default"
              onClick={() => {
                setScheduleDate(undefined)
                onHide?.()
              }}
            />
            <Button
              label="Continue"
              size="xl"
              onClick={() => {
                finishCheckout(CPOE_ACTIONS.SCHEDULE_ORDER)
                onHide?.()
              }}
            />
          </div>
        )}
      >
        <div className="mt-8">Please select the date you wish to process this order</div>
        <Calendar
          className="w-full mt-2 p-inputtext-sm"
          showIcon
          value={scheduleDate}
          minDate={addDays(new Date(), 1)}
          dateFormat={"M d, yy"}
          onChange={(e) => setScheduleDate(e.target.value as Date)}
        />
      </Dialog>
      <div className="flex flex-1 items-center justify-end">
        <Button
          type="button"
          label="Close"
          buttonStyle="default"
          className="mr-2"
          size="xl"
          onClick={closeCheckout}
          disabled={isFinishing}
        />
        {!isFirstStep && (
          <Button
            type="button"
            label="Previous"
            buttonStyle="default"
            className="mr-2"
            size="xl"
            disabled={isError || isLoading}
            onClick={() => moveStep(true)}
          />
        )}
        {!isLastStep &&
        (hasMedsRequest ||
          hasBillToPracticeLabRequest ||
          hasBillPatientLabRequest ||
          checkoutStep !== CPOE_STEPS.FINISH) &&
        !(isExemptLabPayment && hasLabsRequest) ? (
          <SplitButton
            loading={isLoading}
            disabled={isError || isLoading}
            actions={nextActions}
            size="xl"
            autoUpdateMainAction
            key="split-next-action"
          />
        ) : (
          <SplitButton
            loading={isLoading}
            disabled={isError || isLoading}
            actions={finishActions}
            size="xl"
            autoUpdateMainAction
            key="split-finish-actions"
          />
        )}
      </div>
    </>
  )
}

export { OrdersFooterActions }
