import { Coding, Consent, codeableConceptAsString } from "fhir"
import pluralize from "pluralize"
import { Checkbox } from "primereact/checkbox"
import { classNames } from "primereact/utils"
import { useId, useState } from "react"

import {
  EmptyMessage,
  FooterActions,
  InfiniteScroll,
  ModalDialog,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
} from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useAvailableConsents, useConsentDocument, useConsents, useCreateConsent } from "../hooks"
import { AvailableConsents } from "../types"
import { consentModelBuilder } from "./consentModelBuilder"

const ConsentsContainer = () => {
  const { patientId, patient } = usePatientContext()
  const { appSubModules } = useAppModuleContext()

  const { consents, isLoading, hasNextPage, fetchNextPage } = useConsents(patientId)
  const { getConsentDocument, isGettingDR } = useConsentDocument()

  const { availableConsents, areAnyConsentAvailable, isLoadingAC } = useAvailableConsents(patientId, patient.gender)

  const { createConsents, isCreatingConsents } = useCreateConsent()

  const [selectedConsent, setSelectedConsent] = useState<string>()
  const [showAdd, setShowAdd] = useState(false)
  const [selectedCodes, setSelectedCodes] = useState<Coding[]>([])

  const handleDownloadConsentAttachemnt = (consent: Consent) => {
    setSelectedConsent(consent.id as string)
    getConsentDocument(consent.id as string)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType={"two-lines"} />

  const onSelectCategory = (code: Coding, selected: boolean) => {
    if (selected) setSelectedCodes([...selectedCodes, code])
    else setSelectedCodes(selectedCodes.filter((c) => c.code !== code.code))
  }

  const showConsentModal = () => {
    setShowAdd(true)
    setSelectedCodes([])
  }

  const addOptions = [
    {
      label: "Create new consent",
      command: () => showConsentModal(),
      disabled: !areAnyConsentAvailable,
      title: !areAnyConsentAvailable ? "No consents available" : undefined,
      loading: isLoadingAC,
    },
  ]

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      {isLoading ? (
        loader()
      ) : !consents?.length ? (
        <EmptyMessage
          icon={appSubModules["patient"][ModulesId.CONSENT].getIcon()}
          itemTitle="Consent"
          subMessage={false}
          action={showConsentModal}
          isLoading={isLoadingAC}
        />
      ) : (
        <>
          <div className="bg-white h-full overflow-auto">
            <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={loader()}>
              <StackedListContainer
                data={consents}
                itemModelBuilder={(item) =>
                  consentModelBuilder({
                    consent: item,
                    download: () => handleDownloadConsentAttachemnt(item),
                    isLoading: isGettingDR && selectedConsent === item.id,
                  })
                }
              />
            </InfiniteScroll>
          </div>
          <FooterActions actions={addOptions} />
        </>
      )}
      <ModalDialog
        title="Request new consent"
        visible={showAdd || isCreatingConsents}
        onCancel={() => setShowAdd(false)}
        showCloseIcon
        showCancel
        showAccept={areAnyConsentAvailable}
        acceptDisabled={selectedCodes.length === 0}
        acceptLoading={isCreatingConsents}
        onAccept={() => {
          createConsents({ patient, consentCategories: selectedCodes })
          setShowAdd(false)
        }}
      >
        {availableConsents?.map((consent, index) => (
          <ConsentRequestItem
            key={index}
            consent={consent}
            selected={selectedCodes.some((c) => c.code === consent.coding.code)}
            onChange={(checked) => onSelectCategory(consent.coding, checked)}
          />
        ))}
      </ModalDialog>
    </div>
  )
}

const ConsentRequestItem = ({ consent, selected, onChange }: Props) => (
  <div
    className={classNames(
      "flex items-center",
      consent.count > 0 ? "cursor-default text-gray-500" : "cursor-pointer text-black",
    )}
    onClick={() => consent.count === 0 && onChange(!selected)}
  >
    <Checkbox
      disabled={consent.count > 0}
      className={classNames({ "cursor-default": consent.count > 0 })}
      checked={selected}
    />
    <div className="flex flex-col ml-2">
      <span className="">{codeableConceptAsString({ coding: [consent.coding] })}</span>
      {consent.count > 0 && (
        <span className="text-xs text-yellow-600">{`${consent.count} pending ${pluralize(
          "consent",
          consent.count,
        )}`}</span>
      )}
    </div>
  </div>
)

type Props = {
  consent: AvailableConsents
  selected: boolean
  onChange(checked: boolean): void
}

export { ConsentsContainer }
