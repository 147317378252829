import { Patient } from "fhir"
import { Form, Formik, FormikProps } from "formik"

import { Button, EmailCheckContext, EmailCheckProvider, EmailField, PhoneField } from "commons"

import { usePatchPatient } from "../../hooks"

const TelecomForm = ({ patient }: Props) => {
  const initialValues: Partial<Patient> = { telecom: patient.telecom }

  const { patchPatient, isPatching } = usePatchPatient()

  const handleSubmit = (telecomData: Partial<Patient>) => {
    patchPatient({ patientId: patient.id as string, patientData: { ...telecomData, meta: patient.meta } })
  }

  return (
    <EmailCheckProvider>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting, isValid }: FormikProps<Partial<Patient>>) => (
          <Form className="flex flex-1 flex-col overflow-hidden divide-y">
            <fieldset className="relative p-fluid flex flex-1 flex-col overflow-y-auto grow px-3" id="telecom">
              <legend>Telecom</legend>
              <EmailField
                field="telecom[0].value"
                label="Email"
                validateRules
                initialValue={initialValues.telecom?.[0].value}
                validateDuplicate
              />
              <PhoneField
                unmask
                field="telecom[1].value"
                className="mt-2"
                label="Phone"
                validation={(value) => (!value ? "Phone is required" : undefined)}
              />
            </fieldset>
            <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
              <EmailCheckContext.Consumer>
                {({ isCheckingEmailInUse }) => (
                  <Button
                    type="submit"
                    label="Update"
                    size="lg"
                    loading={isPatching || isSubmitting || isCheckingEmailInUse}
                    disabled={!isValid || isCheckingEmailInUse}
                  />
                )}
              </EmailCheckContext.Consumer>
            </div>
          </Form>
        )}
      </Formik>
    </EmailCheckProvider>
  )
}

type Props = {
  patient: Patient
}

export { TelecomForm }
