import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CodeableConcept, codeableConceptAsString, Coding, Medication } from "fhir"
import { FieldProps, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import { FC, useEffect } from "react"

import { CodeableConceptField, DateField, DropdownField, FormField, InputField, ValueSetIds } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"
import { useValueSet } from "value-set"

import { bodyZonesCodes, InventoryData, PROCEDURE_CONFIG, ProcedureData } from "../../types"
import { BodySitesAndDosageField } from "./BodySitesAndDosageField"

const MedConfigurationItem: FC<Props> = ({
  medIndex,
  expanded,
  isSingleBodySiteSelection,
  configCodes,
  bodySiteActionCode,
  onClick,
  onChangeBodySite,
  onChangeBodyZone,
  handelUpdateDosage,
}) => {
  const { codes: bodySites } = useValueSet({ valueSetId: ValueSetIds.BODY_SITES })

  const {
    values: { configurationItem },
    setFieldValue,
  } = useFormikContext<ProcedureData>()
  const currentBatch = (configurationItem?.[medIndex]?.medicationAdministration?.contained?.[0] as Medication)?.batch
  const batchLimit = (currentBatch as InventoryData).quantity

  const medLot = (item: InventoryData) =>
    item ? (
      <div className="flex gap-2">
        <span>L: {item.lotNumber}</span>
        <span>
          E: {item.expirationDate ? formatDate(new Date(item.expirationDate), formatsByTypes.ISO_8601_DATE) : "N/A"}
        </span>
        <span>Q: {item.quantity}</span>
      </div>
    ) : undefined

  const requireMedAdministration = configCodes.includes(PROCEDURE_CONFIG.PELLET)
  const requiresDoseConfiguration = configCodes.includes(PROCEDURE_CONFIG.BODY_SITE_COORDINATE_AND_DOSAGE)

  const dosage = configurationItem[medIndex]?.bodySite?.coding?.find(
    ({ system }) => system === `${bodySiteActionCode?.system}/configure-dosage`,
  )

  const cardTitle = !requireMedAdministration
    ? `Massage ${medIndex + 1}`
    : codeableConceptAsString(configurationItem[medIndex]?.medicationRequest?.medication?.CodeableConcept)

  useEffect(() => {
    setFieldValue(
      `configurationItem[${medIndex}].medicationRequest.dispenseRequest.quantity.value`,
      Math.min(batchLimit, configurationItem[medIndex]?.medicationRequest?.dispenseRequest?.quantity?.value ?? 1),
    )
  }, [batchLimit, configurationItem[medIndex]?.medicationRequest?.dispenseRequest?.quantity?.value])

  return (
    <div
      className={classNames(
        "flex flex-col p-4 rounded-md border transition-all ease-in-out duration-200",
        expanded ? "cursor-default border-gray-700" : "cursor-pointer border-gray-300",
      )}
      onClick={onClick}
    >
      <div className="flex flex-row justify-between items-baseline space-x-1">
        <div className="flex flex-row gap-2 items-baseline">
          <span
            className={classNames(
              "rounded-full w-2 h-2",
              configurationItem[medIndex]?.bodySite?.coding?.[0]?.code ? "bg-green-500" : "bg-yellow-500",
            )}
          ></span>
          <span className="font-medium text-gray-900">{cardTitle}</span>
        </div>
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </div>
      <div className={classNames("flex flex-col p-4 gap-4", { hidden: !expanded })}>
        {requireMedAdministration && (
          <>
            <div className="flex flex-col gap-2">
              {configurationItem[medIndex]?.invData ? (
                <FormField
                  field={`configurationItem[${medIndex}].medicationAdministration.contained[0].batch`}
                  label="Batch"
                >
                  {({ field: { name, value, onChange } }: FieldProps) => (
                    <Dropdown
                      id={name}
                      name={name}
                      className="slashed small-trigger"
                      options={configurationItem[medIndex].invData}
                      optionLabel="lotNumber"
                      placeholder="Select a batch"
                      value={value}
                      onChange={onChange}
                      itemTemplate={medLot}
                      valueTemplate={medLot}
                    />
                  )}
                </FormField>
              ) : (
                <>
                  <InputField
                    label="Batch"
                    field={`configurationItem[${medIndex}].medicationAdministration.contained[0].batch.lotNumber`}
                    validation={(value) => !value && "Batch is required"}
                  />
                  <DateField
                    label="Expiration date"
                    field={`configurationItem[${medIndex}].medicationAdministration.contained[0].batch.expirationDate`}
                    stringFormatType="ISO_8601_DATETIME"
                    validation={(value) => !value && "Expiration date is required"}
                    minDate={new Date()}
                  />
                </>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <FormField
                label="Quantity"
                field={`configurationItem[${medIndex}].medicationRequest.dispenseRequest.quantity.value`}
              >
                {({ field: { name, value, onChange } }: FieldProps) => (
                  <InputNumber
                    id={name}
                    name={name}
                    locale="en-US"
                    showButtons
                    min={1}
                    max={batchLimit}
                    allowEmpty={false}
                    value={value}
                    onValueChange={(e) => {
                      onChange(e)
                      setFieldValue(
                        `configurationItem[${medIndex}].medicationRequest.dispenseRequest.initialFill.quantity.value`,
                        e.value,
                      )
                      setFieldValue(
                        `configurationItem[${medIndex}].medicationAdministration.dosage.dose.value`,
                        (e.value as number) * (configurationItem[medIndex]?.medTotalDose?.value ?? 1),
                      )
                    }}
                    className="input-text-center p-inputtext-sm"
                    inputClassName="w-20"
                  />
                )}
              </FormField>
            </div>
          </>
        )}

        {!isSingleBodySiteSelection ? (
          <>
            <DropdownField
              field={`configurationItem[${medIndex}].zone`}
              label="Zone"
              optionLabel="display"
              optionValue=""
              dataKey="code"
              options={bodyZonesCodes}
              handleChange={(e) => onChangeBodyZone(e.value)}
              validation={(value) => !(value as Coding)?.code && "Zone is required"}
            />
            <BodySitesAndDosageField
              field={`configurationItem[${medIndex}].bodySite`}
              validation={(value) =>
                !(value as CodeableConcept)?.coding?.[0]?.code && "Administration site is required"
              }
              bodySites={configurationItem[medIndex]?.bodySite?.coding?.[0]?.code?.split("|") ?? []}
              dosages={requiresDoseConfiguration ? dosage?.code?.split("|") ?? [] : []}
              modalTitle={cardTitle}
              maxDosage={configurationItem[medIndex]?.medTotalDose?.value ?? 0}
              doseUnit={configurationItem[medIndex]?.medTotalDose?.unit ?? "mg"}
              onUpdateDosage={handelUpdateDosage}
            />
          </>
        ) : (
          <CodeableConceptField
            className="p-fluid"
            field={`configurationItem[${medIndex}].bodySite`}
            label="Administration site"
            codes={bodySites ?? []}
            validation={(value) => !value?.coding?.[0]?.code && "Administration site is required"}
            handleChange={(e) => onChangeBodySite([e.value.code])}
          />
        )}
      </div>
    </div>
  )
}

type Props = {
  medIndex: number
  expanded: boolean
  onClick(): void
  onChangeBodySite(bodySiteCode: string[]): void
  onChangeBodyZone(bodyZone: Coding): void
  isSingleBodySiteSelection: boolean
  configCodes: string[]
  bodySiteActionCode?: Coding
  handelUpdateDosage(newDosage: number[]): void
}

export { MedConfigurationItem }
