import { Reference } from "fhir"
import { FormikHelpers } from "formik"
import { FC, useMemo, useState } from "react"

import { strCapitalize } from "utils"

import { Button } from "../../../components/Buttons"
import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { SplitButton } from "../../../components/SplitButton"
import { Step, StepFormContainer } from "../../../forms/StepFormContainer"
import { PractitionerInfo } from "../../../types"
import { useRequestedConfigs } from "../../hooks"
import { PLAN_ACTION_CODES, PlanData } from "../../types"
import { planValidationSchema } from "../validations"
import { LabsFormSection } from "./LabsFormSection"
import { OrderAndTaskInfo } from "./OrderAndTaskInfo"
import { Appointment } from "./steps/Appointment"
import { Assessment } from "./steps/Assessment"
import { Medications } from "./steps/Medications"

const PlanStepsForm: FC<Props> = ({
  contextLabel,
  initialValues,
  openEncounter,
  practitionersInfo,
  isProcessingAction,
  isCanceling,
  onSubmit,
  onClose,
  handleActivate,
  handleCancel,
}) => {
  const [cancelConfirm, setCancelConfirm] = useState(false)
  const { requiresAlgorithm, requiresEmails, requiresLabs, requiresNutras, requiresRXs } = useRequestedConfigs(
    initialValues.configureActions,
  )

  const { steps: formSteps } = useMemo(() => {
    const configs = { requiresAlgorithm, requiresEmails, requiresLabs, requiresNutras, requiresRXs }

    const steps = initialValues.appointment?.id
      ? ([
          {
            id: "appointment",
            header: "Appointment",
            valueKeys: ["appointment", "mailTasks", ...commonValueKeys],
            children: <Appointment practitionersInfo={practitionersInfo} configs={configs} />,
            innerContainerClassName: "px-4 sm:px-6 py-6 space-y-6 divide-y flex flex-col",
          },
        ] as Step<Partial<PlanData>>[])
      : []

    if (requiresAlgorithm) {
      steps.push({
        id: PLAN_ACTION_CODES.CONFIGURE_ALGORITHM,
        header: "Assessment",
        valueKeys: ["mcActivity", "algorithmActivity", ...commonValueKeys],
        enableReinitialize: true,
        children: (
          <Assessment
            practitionersInfo={practitionersInfo}
            configs={configs}
            affectedSteps={[
              `${PLAN_ACTION_CODES.CONFIGURE_EMAILS}-${PLAN_ACTION_CODES.CONFIGURE_QUESTIONNAIRES}`,
              PLAN_ACTION_CODES.CONFIGURE_LABS,
              `${PLAN_ACTION_CODES.CONFIGURE_NUTRAS}-${PLAN_ACTION_CODES.CONFIGURE_RXS}`,
            ]}
          />
        ),
        innerContainerClassName: "px-4 sm:px-6 py-6 space-y-6 divide-y flex flex-col",
      })
    }

    if (requiresEmails && !requiresAlgorithm) {
      steps.push({
        id: `${PLAN_ACTION_CODES.CONFIGURE_EMAILS}-${PLAN_ACTION_CODES.CONFIGURE_QUESTIONNAIRES}`,
        header: "Questionnaires & Consent",
        valueKeys: ["mailTasks", "appointment", ...commonValueKeys],
        enableReinitialize: true,
        children: <OrderAndTaskInfo requiresEmails />,
        innerContainerClassName: "px-4 sm:px-6 py-6 space-y-6 divide-y",
      })
    }

    if (requiresLabs) {
      steps.push({
        id: PLAN_ACTION_CODES.CONFIGURE_LABS,
        header: "Labs",
        valueKeys: [...labsValueKeys, ...commonValueKeys],
        children: (
          <LabsFormSection configureActions={initialValues.configureActions} practitionersInfo={practitionersInfo} />
        ),
        innerContainerClassName: "px-4 sm:px-6 py-6 space-y-6 divide-y",
      })
    }

    if (requiresNutras || requiresRXs) {
      steps.push({
        id: `${PLAN_ACTION_CODES.CONFIGURE_NUTRAS}-${PLAN_ACTION_CODES.CONFIGURE_RXS}`,
        header: "Medications",
        valueKeys: [
          "planActivityDefinitions",
          ...commonValueKeys,
          ...(requiresRXs ? (["rx"] as (keyof PlanData)[]) : []),
          ...(requiresNutras ? (["nutraceutical"] as (keyof PlanData)[]) : []),
        ],
        children: (
          <Medications
            requiresNutras={requiresNutras}
            requiresRXs={requiresRXs}
            practitionersInfo={practitionersInfo}
            openEncounter={openEncounter}
            configureActions={initialValues.configureActions}
            stepId={`${PLAN_ACTION_CODES.CONFIGURE_NUTRAS}-${PLAN_ACTION_CODES.CONFIGURE_RXS}`}
          />
        ),
        innerContainerClassName: "px-4 sm:px-6 py-6 space-y-6 divide-y",
      })
    }

    return { steps }
  }, [initialValues])

  return (
    <>
      <StepFormContainer
        title={initialValues.carePlan.title ?? `${strCapitalize(contextLabel)} Configuration`}
        initialValue={initialValues}
        validationSchema={planValidationSchema}
        disableSave={isProcessingAction}
        onSubmit={onSubmit}
        steps={formSteps}
        onClose={onClose}
        customSaveButton={({ validate }) => (
          <SplitButton
            size="lg"
            type="submit"
            loading={isProcessingAction}
            autoUpdateMainAction
            actions={[
              {
                label: "Save & Activate",
                description: `Save current configuration and activate ${contextLabel}`,
                onSelectClick: async () => {
                  const errors = await validate()
                  if (!Object.keys(errors).length) return handleActivate(initialValues.carePlan.id)
                },
              },
              {
                label: "Save",
                description: "Save current configuration",
              },
            ]}
          />
        )}
        cancelButton={
          handleCancel ? (
            <Button
              label="Cancel"
              buttonStyle="default"
              size="lg"
              title={`Cancel ${contextLabel}`}
              disabled={isProcessingAction}
              onClick={() => setCancelConfirm(true)}
            />
          ) : undefined
        }
        className="@container"
      />
      {handleCancel && (
        <ConfirmDialog
          visible={cancelConfirm}
          confirmText={`Are you sure you want to cancel this ${contextLabel}?`}
          isLoading={isCanceling}
          waitToFinish
          onConfirm={handleCancel}
          hideDialog={() => setCancelConfirm(false)}
        />
      )}
    </>
  )
}

const commonValueKeys: (keyof PlanData)[] = ["requester", "configureActions", "carePlan"]
const labsValueKeys: (keyof PlanData)[] = [
  "billingType",
  "combo",
  "combos",
  "bloodDrawnInOffice",
  "extraPlanDefinition",
  "icd10",
  "order",
  "panels",
  "performer",
  "performers",
  "specimenDate",
  "suggestedPanels",
]

type Props = {
  contextLabel: string
  initialValues: PlanData
  practitionersInfo: PractitionerInfo[]
  openEncounter?: Reference
  isProcessingAction?: boolean
  isCanceling?: boolean
  handleActivate(planId?: string): void
  handleCancel?(): void
  onSubmit(data: PlanData, formikHelpers?: FormikHelpers<PlanData>): void
  onClose(): void
}

export { PlanStepsForm }
