import { useInfiniteQuery } from "@tanstack/react-query"
import { Condition, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { conditionsQueryKeys } from "../query-keys"

const useConditions = ({ patientId, key, enabled, encounter }: ConditionsArgs) => {
  const { search } = useClient()
  const queryKey = conditionsQueryKeys.list(patientId, key)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    ConditionsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-recorded-date",
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Condition`, filters, signal })

      const conditions = getResources(bundle) as Condition[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { conditions, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
    enabled,
  })

  const { conditions, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.conditions)
    const count = newData?.length

    return {
      conditions: newData,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    conditions,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

interface ConditionsArgs {
  patientId: string
  key?: string
  enabled?: boolean
  encounter?: string
}

type ConditionsQueryData = {
  conditions: Condition[]
  next: number | undefined
  total: number
}

export { useConditions }
