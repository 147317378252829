import { faCalendarDays, faChartLineUpDown, faNoteMedical } from "@fortawesome/pro-regular-svg-icons"
import { format, parseISO } from "date-fns"
import { Observation } from "fhir"

import { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"

import { LaboratoryValueItem } from "./LaboratoryValueItem"

const labObservationModelBuilder = (observation: Observation): StackedListItemProps => {
  return {
    leftData: [
      {
        lineItems: [{ name: "Laboratory", value: observation.code?.text }],
      },
      {
        lineItems: [
          ...(observation.effective?.dateTime
            ? [
                {
                  name: "Effective",
                  value: format(parseISO(observation.effective?.dateTime), formatsByTypes.SHORT_DATETIME),
                  icon: faCalendarDays,
                },
              ]
            : []),
        ],
      },
      ...(observation?.value?.string
        ? [
            {
              lineItems: [
                {
                  component: <LaboratoryValueItem value={observation.value.string} />,
                },
              ],
            },
          ]
        : []),
      ...(observation?.value?.Quantity
        ? [
            {
              lineItems: [
                {
                  component: (
                    <LaboratoryValueItem
                      value={`${observation.value.Quantity.value} ${observation.value.Quantity.unit ?? ""}`}
                    />
                  ),
                },
              ],
            },
          ]
        : []),
      ...(observation.referenceRange?.[0]?.text
        ? [
            {
              lineItems: [
                {
                  component: (
                    <LaboratoryValueItem
                      title="Reference range"
                      icon={faChartLineUpDown}
                      value={`${observation.referenceRange?.[0].text ?? ""} ${observation.value?.Quantity?.unit ?? ""}`}
                    />
                  ),
                },
              ],
            },
          ]
        : []),
      ...(observation.interpretation?.[0]?.text
        ? [
            {
              lineItems: [
                {
                  component: (
                    <LaboratoryValueItem
                      title="Interpretation"
                      icon={faNoteMedical}
                      value={observation.interpretation?.[0].text}
                    />
                  ),
                },
              ],
            },
          ]
        : []),
    ],
    ...(observation.code.coding?.[0].code === "imported" ? { badge: { text: "P360", colorStyle: "blue" } } : {}),
  }
}

export { labObservationModelBuilder }
