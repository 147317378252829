import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { allergiesQueryKeys } from "../query-keys"

const useDeleteAllergy = (onSettled?: () => void, reloadAllergies?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const deleteAllergy = async (allergyId: string) => remove("AllergyIntolerance", allergyId)

  const { mutate: removeAllergy, isPending: isDeleting } = useMutation({
    mutationFn: deleteAllergy,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: allergiesQueryKeys.all })
      reloadAllergies?.()
      displayNotificationSuccess("Allergy deleted successfully!")
      datadogLogs.logger.info(`Allergy deleted successfully!`, { data })
    },
    onSettled,
    onError: (error: CustomError, allergyId) => {
      displayNotificationError(registerErrorTrace(error, { allergyId }))
    },
  })

  return { removeAllergy, isDeleting }
}

export { useDeleteAllergy }
