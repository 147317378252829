import {
  Account,
  Address,
  ChargeItemDefinition,
  CodeableConcept,
  Coverage,
  Invoice,
  Parameters,
  PaymentReconciliation,
  codeableConceptAsString,
  isCarePlan,
} from "fhir"

import { BILLING_TYPES_CODES } from "data"
import { SYSTEM_VALUES } from "system-values"
import {
  getAddressByType,
  getBasePrice,
  getDiscountPrice,
  getFeePrice,
  getHomeAddress,
  getMoneyCurrencyAlt,
  getTaxPrice,
  isAddressNonContinentalState,
  lineInvoiceTypes,
  strCapitalize,
} from "utils"

import { ACTION_GROUP_CODES, ActionGroupCode, CpoeRequest, SummaryParameter } from "./types"

const buildAction = (
  actionResource: ChargeItemDefinition | Account | Coverage,
  actionCode: "add-fee" | "add-discount" | "use-reader" | string,
) => ({
  code: [
    {
      coding: [
        {
          code: actionCode,
          system: SYSTEM_VALUES.CPOE_ACTION,
        },
      ],
    },
  ],
  resource: {
    localRef: actionResource.id,
  },
})

const checkIsActionType = ({
  codeableConcept,
  type,
  isNot,
}: {
  codeableConcept?: CodeableConcept[]
  type: ActionGroupCode
  isNot?: ActionGroupCode
}) =>
  codeableConcept?.some(
    (cc) => cc.coding?.find((coding) => coding.system === SYSTEM_VALUES.CPOE_ACTION_GROUP)?.code === type,
  ) &&
  (isNot
    ? !codeableConcept.some(
        (cc) => cc.coding?.find((coding) => coding.system === SYSTEM_VALUES.CPOE_ACTION_GROUP)?.code === isNot,
      )
    : true)

const getSummaryParameter = (summary: Parameters, key: SummaryParameter) => {
  const param = summary.parameter?.find((param) => param.name === key)

  switch (key) {
    case "discounts":
      return param?.part?.reduce(
        (prev, part) => {
          return { ...prev, ...{ [part.name]: part.value?.decimal ?? 0 } }
        },
        {} as Record<string, number>,
      )

    default:
      return param?.value?.decimal ?? 0
  }
}

const getActiveRequests = (requests: CpoeRequest[]) =>
  requests.filter((req) => req.resource.code?.[0].coding?.[0].code === "activate")

enum CPOE_ACTIONS {
  PROCESS = "Process",
  SCHEDULE_ORDER = "Schedule Order",
  SEND_TO_PATIENT = "Send to patient",
  SAVE_DRAFT = "Save (draft)",
  NEXT = "Next",
  SAVE_CLOSE = "Save & Close",
}

const getInvoiceMeta = (invoice: Invoice, paymentReconciliation?: PaymentReconciliation) => {
  const {
    paymentAmount,
    issuer,
    paymentIssuer,
    paymentStatus,
    paymentDate,
    totalGross,
    subject,
    creditCard,
    lineItem,
  } = {
    ...invoice,
    ...paymentReconciliation,
  }
  const price = paymentAmount
    ? `${getMoneyCurrencyAlt(paymentAmount.currency)}${paymentAmount.value ?? 0}`
    : totalGross
      ? `${getMoneyCurrencyAlt(totalGross.currency)}${totalGross.value ?? 0}`
      : "$0"

  const order_issuer = paymentIssuer?.display ?? issuer?.display
  const credit_card =
    creditCard &&
    JSON.stringify({
      card_holder: creditCard.cardHolderName,
      last4Digits: creditCard.last4Digits,
      expiration_month: creditCard.expirationMonth,
      expiration_year: creditCard.expirationYear,
      card_type: creditCard.type,
    })

  const products = lineItem?.reduce((acc, { chargeItem, priceComponent }) => {
    const itemPrice =
      getBasePrice(priceComponent) ??
      getTaxPrice(priceComponent) ??
      getFeePrice(priceComponent) ??
      getDiscountPrice(priceComponent)
    const priceStr =
      `${itemPrice?.factor && itemPrice?.type === lineInvoiceTypes.BASE ? "x" + itemPrice.factor : ""} ${getMoneyCurrencyAlt(itemPrice?.amount?.currency)}${itemPrice?.amount?.value ?? 0}`.replaceAll(
        "-",
        "",
      )
    const product = chargeItem?.Reference?.display ?? codeableConceptAsString(chargeItem?.CodeableConcept)
    return [...acc, `${product} ${priceStr}`]
  }, Array<string>())

  return {
    price,
    order_issuer,
    credit_card,
    payment_status: paymentStatus,
    payment_date: paymentDate,
    order_for: subject?.display,
    products: products?.join("\n"),
  }
}

const getRGActiveItemsInfo = (selectedRequests: CpoeRequest[]) =>
  selectedRequests.reduce(
    (prev, request) => {
      const { type, laboratoryData, medicationData } = request

      switch (true) {
        case type === ACTION_GROUP_CODES.NUTRA:
          return {
            ...prev,
            hasNutraRequest: true,
            hasMedsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? medicationData?.medicationRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
          }
        case type === ACTION_GROUP_CODES.PROCEDURE:
          return {
            ...prev,
            hasProcedureRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? medicationData?.medicationRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
          }
        case type === ACTION_GROUP_CODES.PHARMA:
          return {
            ...prev,
            hasRXRequest: true,
            hasMedsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? medicationData?.medicationRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
          }
        case type === ACTION_GROUP_CODES.LAB && laboratoryData?.billingType === BILLING_TYPES_CODES.INSURANCE:
          return {
            ...prev,
            hasInsuranceLabsRequest: true,
            hasLabsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? laboratoryData?.serviceRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
            insuranceRequests: [...prev.insuranceRequests, request],
          }
        case type === ACTION_GROUP_CODES.LAB:
          return {
            ...prev,
            hasLabsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? laboratoryData?.serviceRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
            hasBillToPracticeLabRequest: !prev.hasBillToPracticeLabRequest
              ? laboratoryData?.billingType === BILLING_TYPES_CODES.BILL_PRACTICE
              : true,
            hasBillPatientLabRequest: !prev.hasBillPatientLabRequest
              ? laboratoryData?.billingType === BILLING_TYPES_CODES.BILL_PATIENT
              : true,
          }
      }

      return prev
    },
    {
      hasNutraRequest: false,
      hasRXRequest: false,
      hasMedsRequest: false,
      hasProcedureRequest: false,
      hasLabsRequest: false,
      hasInsuranceLabsRequest: false,
      hasPlanBasedRequest: false,
      hasBillToPracticeLabRequest: false,
      hasBillPatientLabRequest: false,
      insuranceRequests: Array<CpoeRequest>(),
    },
  )

const getCheckoutAddressInfo = (
  address?: Address[],
  hasNutraRequest?: boolean,
  hasInsuranceLabsRequest?: boolean,
  hasRXRequest?: boolean,
) => {
  const postalAddress = getAddressByType("postal", address) ?? getHomeAddress(address) ?? address?.[0]
  const physicalOrAnyValidAddress = getAddressByType("physical", address) ?? postalAddress

  const nutrasShippingAddress = isAddressNonContinentalState(postalAddress) ? undefined : postalAddress
  const labsShippingAddress = isAddressNonContinentalState(physicalOrAnyValidAddress)
    ? undefined
    : physicalOrAnyValidAddress

  const missingMedsShippingAddress = !nutrasShippingAddress && !!hasNutraRequest
  const missingLabsShippingAddress = !!hasInsuranceLabsRequest && !labsShippingAddress
  const missingPatientAddress = !!hasRXRequest && !physicalOrAnyValidAddress
  const missingAddresses = missingMedsShippingAddress || missingLabsShippingAddress || missingPatientAddress

  return {
    missingAddresses,
    missingLabsShippingAddress,
    missingMedsShippingAddress,
    missingPatientAddress,
    nutrasShippingAddress,
    labsShippingAddress,
  }
}

export const SECTION_KEY_ORDER: { [key: string]: number } = {
  nutraceutical: 0,
  rx: 1,
  "lab-order": 2,
  procedure: 3,
}

const getSectionName = (section: string) => {
  switch (section) {
    case ACTION_GROUP_CODES.NUTRA:
      return "Nutraceuticals"
    case ACTION_GROUP_CODES.PHARMA:
      return "Pharmaceuticals"
    case ACTION_GROUP_CODES.PROCEDURE:
      return "Procedures"
    case ACTION_GROUP_CODES.LAB:
      return "Laboratories"

    default:
      return strCapitalize(section)
  }
}

export const defaultCoveragesByType = {
  rx: undefined,
  "lab-order": undefined,
  nutraceutical: undefined,
  procedure: undefined,
}

export {
  CPOE_ACTIONS,
  buildAction,
  checkIsActionType,
  getActiveRequests,
  getCheckoutAddressInfo,
  getInvoiceMeta,
  getRGActiveItemsInfo,
  getSectionName,
  getSummaryParameter,
}
