import { useMountEffect } from "primereact/hooks"
import { Message } from "primereact/message"
import { MouseEvent, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { Button, ConfirmDialog, FormContainer, ModulesId } from "commons"
import { useAppModuleContext } from "modules"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"

import { MedicationRequestFormData } from "../../types"
import { prescriptionValidationSchema } from "../../utils/validations"
import { PrescriptionForm } from "./PrescriptionForm"

const PrescriptionFormContainer = ({ initialValues, isEditing, onSubmit, onCancel }: Props) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const { patient } = usePatientContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [mrData, setMRData] = useState<MedicationRequestFormData | undefined>(undefined)

  const missingPatientAddress = !patient.address?.length

  useMountEffect(() => {
    if (isModuleActive(ModulesId.INTAKE) && !["allergies", "conditions"].includes(searchParams.get("kp") as string)) {
      searchParams.set("kp", "allergies")
      setSearchParams(searchParams)
    }
  })

  return (
    <div className="h-full">
      <FormContainer
        title={`${isEditing ? "Update" : "Create"} Prescription`}
        initialValue={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideButtonsDivider
        saveLabel={`${isEditing ? "Update" : "Create"} Prescription`}
        validationSchema={prescriptionValidationSchema(organizationPractitionersInfo)}
        innerContainerClassName="px-4 space-y-4 pb-6"
        footerClassName="border-t border-gray-200"
        disableSave={missingPatientAddress}
        customSaveButton={({ values, validate, isSubmitting }) => {
          const shouldShowAlert =
            ["capsule", "tablet"].includes(values.prescriptionQuantity?.code ?? "") &&
            values.dispenseRequest?.quantity?.value === 1

          return (
            <Button
              label={`${isEditing ? "Update" : "Create"} Prescription`}
              type={shouldShowAlert ? "button" : "submit"}
              loading={isSubmitting}
              onClick={
                shouldShowAlert
                  ? async () => {
                      const errors = await validate()
                      if (!Object.keys(errors).length) setMRData(values)
                    }
                  : undefined
              }
            />
          )
        }}
      >
        {({ isSubmitting, setSubmitting }) => (
          <>
            {missingPatientAddress && <MissingAddressWarningMessage />}
            <PrescriptionForm isEditing={isEditing} practitionersInfo={organizationPractitionersInfo} />

            <ConfirmDialog
              confirmText={`You are creating a prescription with only 1 ${mrData?.prescriptionQuantity?.code}.  Are you sure you wish to proceed?`}
              actionName="Proceed"
              actionType="submit"
              visible={!!mrData}
              isLoading={isSubmitting}
              hideDialog={() => {
                setMRData(undefined)
                setSubmitting(false)
              }}
              waitToFinish
              appendTo="self"
            />
          </>
        )}
      </FormContainer>
    </div>
  )
}

const MissingAddressWarningMessage = () => {
  const { showModule } = useChartContext()

  const navToAddressSubview = (event: MouseEvent) => {
    event.preventDefault()
    showModule({ module: ModulesId.PATIENT, moduleParams: { subview: "address" } })
  }

  return (
    <div className="sticky top-0 z-20 bg-white py-2">
      <Message
        className="w-full"
        severity="warn"
        content={
          <div className="flex gap-2 text-sm">
            <span>In order to create a prescription the patient should have an address on file.</span>
            <button className="font-medium underline" onClick={navToAddressSubview}>
              Add it here.
            </button>
          </div>
        }
      />
    </div>
  )
}

type Props = {
  initialValues: MedicationRequestFormData
  isEditing?: boolean
  onSubmit(_: MedicationRequestFormData): void
  isSubmitting?: boolean
  onCancel(): void
}

export { PrescriptionFormContainer }
