import { Parameters } from "fhir"
import { FC } from "react"

import { getSummaryParameter } from "../utils"

const OrderSummary: FC<Props> = ({ summary, currency, hasMedicationItems }: Props) => {
  const discounts = getSummaryParameter(summary, "discounts-total") as number | undefined

  return (
    <div className="flex justify-between w-full items-baseline py-2">
      <div className="mt-2 w-1/3">Summary</div>
      <div className="flex-1 w-full text-sm">
        <div className="border-b py-2 flex justify-between">
          <span className="inline-block">Subtotal</span>
          <span className="inline-block text-right">
            {currency + (getSummaryParameter(summary, "subtotal") as number).toFixed(2)}
          </span>
        </div>

        <div className="border-b py-2 flex justify-between">
          <span className="inline-block">Taxes</span>
          <span className="inline-block text-right">
            {currency + (getSummaryParameter(summary, "taxes") as number).toFixed(2)}
          </span>
        </div>
        {!!discounts && hasMedicationItems && (
          <div className="border-b py-2 flex flex-col space-y-1">
            <div className="flex justify-between">
              <span className="inline-block">Discounts total</span>
              <span className="inline-block text-right">{`-${currency + (discounts * -1).toFixed(2)}`}</span>
            </div>
          </div>
        )}
        <div className="py-2 font-bold flex justify-between">
          <span className="inline-block">Total</span>
          <span className="inline-block text-right">
            {currency + (getSummaryParameter(summary, "total") as number).toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  )
}

type Props = { summary: Parameters; currency: string; hasMedicationItems: boolean }

export { OrderSummary }
