import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"
import { faChevronDown, faChevronUp, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString, isPatient, MedicationKnowledge } from "fhir"
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from "formik"
import isEqual from "lodash/isEqual"
import { Button } from "primereact/button"
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { ReactHTML, useEffect, useState } from "react"

import { TextAreaField } from "commons"
import {
  dispenseInterval,
  MedicationKnowledgeImage,
  MedicationRequestData,
  MedicationRequestInfo,
  MedicationRequestItem,
  treatmentFrequencies,
} from "commons/meds"

import { getDraftValues, getRecommendedUnitsForTreatment } from "../validations"
import { MedicationRequestUnitsDropdown } from "./MedicationRequestUnitsDropdown"

const MedicationRequestCardForm = ({
  medicationData: { medicationRequestInfo, medicationKnowledge, productPrice },
  isDeleting,
  isSaving,
  onSave,
  onDelete,
  onSelectMK,
  className = "shadow-md border border-slate-300 rounded-lg",
  imageClassName = "h-24",
  customTag,
  collapsibleForm = false,
}: Props) => {
  const [isTyping, setIsTyping] = useState(false)
  const [showCollapsed, setShowCollapsed] = useState(collapsibleForm)
  const toogleCollapse = () => setShowCollapsed((state) => !state)

  const Tag = customTag ?? Form

  const MRForm = ({ setFieldValue, initialValues, values, submitForm }: FormikProps<MedicationRequestInfo>) => {
    const defaultAutoship =
      initialValues?.dispenseRequest?.dispenseInterval?.unit !== dispenseInterval[0].value.unit ||
      initialValues?.dispenseRequest?.dispenseInterval?.value !== dispenseInterval[0].value.value

    const recommendedDispenseUnits = getRecommendedUnitsForTreatment(
      medicationKnowledge?.amount?.value ?? 1,
      medicationRequestInfo.dosageInstruction?.[0],
      medicationRequestInfo.dispenseRequest?.dispenseInterval,
    )
    const orientedDispenseUnits = medicationRequestInfo.dispenseRequest?.quantity?.value ?? 1
    const showUnitsWarning = recommendedDispenseUnits > orientedDispenseUnits

    const prescriptionWarnMessage = `The specified units of ${codeableConceptAsString(
      medicationRequestInfo.medication?.CodeableConcept,
    )} are not enough to comply with the targeted treatment. Consider increasing the units to ${recommendedDispenseUnits}.`

    const [autoship, setAutoship] = useState(defaultAutoship)

    useEffect(() => {
      if (!isEqual(initialValues, values) && !isTyping) {
        submitForm()
      }
    }, [values, isTyping])

    const onKeyDown = () => {
      setIsTyping(true)
    }

    const onBlur = () => {
      setIsTyping(false)
    }

    const updateAutoship = (state: boolean) => {
      if (!state) {
        setFieldValue("dispenseRequest.numberOfRepeatsAllowed", 0)
        setFieldValue("dispenseRequest.dispenseInterval", dispenseInterval[0].value)
      } else {
        setFieldValue("dispenseRequest.numberOfRepeatsAllowed", undefined)
        setFieldValue(
          "dispenseRequest.dispenseInterval",
          defaultAutoship ? initialValues?.dispenseRequest?.dispenseInterval : dispenseInterval[1].value,
        )
      }
      setAutoship(state)
    }

    const showPrescriptionWarning = (target: HTMLElement) => {
      confirmPopup({
        target,
        message: prescriptionWarnMessage,
        icon: "pi pi-exclamation-triangle",
        acceptClassName: "button-primary",
        rejectClassName: "button-default",
        style: { width: "25vw" },
        accept: () => setFieldValue("dispenseRequest.initialFill.quantity.value", recommendedDispenseUnits),
      })
    }

    return (
      <Tag className={classNames("flex flex-col relative p-3 mr-card min-w-fit", className, medicationRequestInfo.id)}>
        <div className="product-list-item flex h-full">
          <div className="flex flex-1 flex-wrap">
            <div
              role="button"
              className="cursor-pointer"
              onClick={() => {
                onSelectMK(medicationKnowledge)
              }}
            >
              <MedicationKnowledgeImage
                drugCharacteristic={medicationKnowledge?.drugCharacteristic}
                className={imageClassName}
              />
            </div>

            <div className="flex flex-col gap-2">
              <MedicationRequestItem medicationKnowledge={medicationKnowledge ?? {}} className="ml-2 xl:ml-3" />
              <span
                className={classNames("ml-2 xl:ml-3 inline-flex items-center transition-all ease-in-out", {
                  hidden: showCollapsed,
                })}
              >
                <MedicationRequestUnitsDropdown
                  field="dispenseRequest.initialFill.quantity.value"
                  optionUnits={Array.from({ length: 20 }, (_, i) => i + 1)}
                  unitName={medicationRequestInfo.dispenseRequest?.quantity?.unit}
                  price={productPrice ?? medicationKnowledge?.cost?.[0].cost}
                  disabled={isSaving || isDeleting}
                />
                {showUnitsWarning && (
                  <span
                    id={`warning${medicationRequestInfo.id}`}
                    className={classNames({ "p-disabled": isSaving || isDeleting })}
                    data-pr-tooltip="Warning: Units are not enough to comply with the targeted treatment. Click here to take action."
                    onClick={(e) => {
                      e.stopPropagation()
                      !isSaving && !isDeleting && showPrescriptionWarning(e.currentTarget)
                    }}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 cursor-pointer text-lg" />
                    <ConfirmPopup key={medicationRequestInfo.id} />
                    <Tooltip target={`#warning${medicationRequestInfo.id}`} position="bottom" />
                  </span>
                )}
              </span>
            </div>
          </div>

          <div className="flex justify-end">
            <div className="flex flex-col space-y-5">
              {collapsibleForm && (
                <FontAwesomeIcon
                  icon={showCollapsed ? faChevronDown : faChevronUp}
                  size="sm"
                  onClick={toogleCollapse}
                  className="cursor-pointer self-end"
                />
              )}
              <div
                className={classNames("flex flex-col justify-between space-y-3 transition-all ease-in-out", {
                  hidden: showCollapsed,
                })}
              >
                {onDelete && (
                  <div className="product-list-action flex flex-col gap-1 lg:gap-2">
                    <Button
                      label={isSaving ? "Saving" : "Remove"}
                      type="button"
                      icon={<FontAwesomeIcon icon={faTrashCan} size="1x" className="mr-1" />}
                      className="button-default p-button-sm"
                      loading={isSaving || isDeleting}
                      disabled={isDeleting || isSaving}
                      onClick={() => {
                        onDelete(medicationRequestInfo?.id as string)
                      }}
                    />
                  </div>
                )}
                {isPatient(medicationRequestInfo.requester) && (
                  <span className="text-orange-400 text-xs truncate">Requested by patient</span>
                )}
                <div className="flex items-center gap-2">
                  <span className="text-sm">Autoship</span>
                  <InputSwitch
                    checked={autoship}
                    onChange={(e) => updateAutoship(e.value)}
                    disabled={isDeleting || isSaving}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames("flex @lg:flex-row gap-2 mt-3 flex-wrap transition-all ease-in-out", {
            hidden: showCollapsed,
          })}
        >
          <div className="flex flex-col flex-1">
            <label htmlFor="doseQuantity" className="text-sm font-medium text-gray-700 h-7">
              Dosage
            </label>
            <div className="p-inputgroup product-dosage flex relative">
              <Field
                title="Units"
                name="doseQuantity"
                validate={(value: string) => (/^\d(-\d)?$/.test(value) ? undefined : "Dose quantity is invalid.")}
              >
                {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
                  <InputText
                    type="text"
                    id={name}
                    name={name}
                    onChange={onChange}
                    value={value}
                    className={classNames("p-inputtext-sm max-w-min min-w-[3.2rem] text-center", {
                      "p-invalid": touched && error,
                    })}
                    disabled={isDeleting || isSaving}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                  />
                )}
              </Field>
              <ErrorMessage name="doseQuantity">
                {(msg) => <small className="p-error absolute -bottom-6">{msg}</small>}
              </ErrorMessage>
              <span className="text-sm capitalize justify-center flex px-2 items-center max-w-max min-w-[4.5rem] cursor-default border border-slate-300">
                {medicationRequestInfo.medicationUnit}
              </span>
              <Field title="Frequency" name="treatmentFrequency">
                {({ field: { name, value, onChange } }: FieldProps) => (
                  <Dropdown
                    options={treatmentFrequencies}
                    optionLabel="label"
                    optionValue="value"
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="p-inputtext-sm items-center small-trigger max-w-min min-w-[9rem]"
                    disabled={isDeleting || isSaving}
                  />
                )}
              </Field>
              {/* <Field title="Duration" name="dispenseRequest.initialFill.duration">
              {({ field: { name, value, onChange } }: FieldProps) => (
                <Dropdown
                  options={treatmentDurations}
                  optionLabel="label"
                  optionValue="value"
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  className="p-inputtext-sm small-trigger"
                  disabled={isSaving}
                />
              )}
            </Field> */}
            </div>
          </div>

          <div className="flex flex-col flex-1 gap-2">
            <div className="flex justify-between items-center">
              <label htmlFor="autoship" className="text-sm font-medium text-gray-700">
                Dispense
              </label>
            </div>
            <Field title="Interval" name="dispenseRequest.dispenseInterval">
              {({ field: { name, value, onChange } }: FieldProps) => (
                <Dropdown
                  options={autoship ? dispenseInterval.slice(1) : dispenseInterval}
                  optionLabel="label"
                  optionValue="value"
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  className="p-inputtext-sm items-center flex-1 small-trigger"
                  disabled={isDeleting || isSaving || !autoship}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={classNames("flex flex-col gap-2 mt-2transition-all ease-in-out", { hidden: showCollapsed })}>
          <TextAreaField
            field="note[0].text"
            rows={2}
            className="p-fluid w-full mt-4"
            inputClassName="resize-none"
            label="Additional instructions"
            placeholder="(empty)"
            disabled={isDeleting || isSaving}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          />
        </div>
      </Tag>
    )
  }

  return (
    <Formik
      key={medicationRequestInfo.id}
      enableReinitialize
      initialValues={getDraftValues(medicationRequestInfo)}
      onSubmit={onSave}
    >
      {MRForm}
    </Formik>
  )
}

type Props = {
  medicationData: MedicationRequestData
  isDeleting?: boolean
  isSaving?: boolean
  onSave(medInfo: MedicationRequestInfo): void
  onDelete?(medicationRequestId: string): void
  onSelectMK(_?: MedicationKnowledge): void
  className?: string
  imageClassName?: string
  customTag?: keyof ReactHTML
  collapsibleForm?: boolean
}

export { MedicationRequestCardForm }
