import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

import { IdentifierForm } from "./AddIdentifierForm"

const PractIdentifierForm = ({ showForm, onSaveForm, closeForm, fieldPath }: Props) => {
  const renderFooter = () => (
    <div className="pt-4 border-t-2 border-slate-200">
      <Button label="Cancel" onClick={closeForm} type="button" className="button-default" />
      <Button label="Create" type="button" onClick={onSaveForm} className="button-primary" />
    </div>
  )

  return (
    <Dialog
      appendTo={"self"}
      header="Identifier"
      modal
      visible={showForm}
      onHide={closeForm}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "35vw" }}
      footer={renderFooter}
    >
      <IdentifierForm fieldPath={fieldPath} />
    </Dialog>
  )
}

type Props = {
  onSaveForm?(): void
  closeForm(): void
  showForm?: boolean
  fieldPath: string
}

export { PractIdentifierForm }
