import { FC, PropsWithChildren, useState } from "react"

import { EmailCheckContext } from "../hooks/useEmailCheckContext"

const EmailCheckProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isCheckingEmailInUse, setIsCheckingEmailInUse] = useState(false)

  return (
    <EmailCheckContext.Provider value={{ isCheckingEmailInUse, setIsCheckingEmailInUse }}>
      {children}
    </EmailCheckContext.Provider>
  )
}

export { EmailCheckProvider, EmailCheckContext }
