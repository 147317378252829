import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query"
import { formatDate } from "date-fns/format"
import { formatDistance } from "date-fns/formatDistance"
import { QuestionnaireResponse } from "fhir"
import { Panel, PanelFooterTemplateOptions } from "primereact/panel"
import { classNames } from "primereact/utils"
import { FC } from "react"

import { allergyModelBuilder } from "allergies"
import { useChartContext } from "chart-view"
import { conditionModelBuilder } from "conditions"
import { formatsByTypes } from "data"
import { medicationModel } from "medications-statement"
import { useAppModuleContext } from "modules"

import { ModulesId } from "../../../../Module"
import { QuestionnaireData } from "../../../../types"
import { Intake } from "../../../types"
import { CPQuestionnaires } from "../CPQuestionnaires"
import { IntakeDataSection } from "./IntakeDataSection"
import { IntakeObservationsSection } from "./IntakeObservationsSection"

const CPIntakesDetails: FC<Props> = ({ intakes, isUpdatingSurvey, isMCPlan, onSurveyHide }) => {
  const { isModuleActive, appSubModules } = useAppModuleContext()
  const { showSubModule } = useChartContext()

  const renderHeaderTemplate = (options: PanelFooterTemplateOptions, item: QuestionnaireData) => {
    return (
      <CPQuestionnaires
        isUpdatingSurvey={isUpdatingSurvey}
        isMCPlan={isMCPlan}
        onSurveyHide={onSurveyHide}
        questionnaires={[item]}
        className={options.className}
      />
    )
  }

  const renderFooterTemplate = (options: PanelFooterTemplateOptions, item: QuestionnaireResponse) => {
    const lastUpdatedAt = item?.meta?.lastUpdated
    const isCompleted = item?.status === "completed"

    return (
      <div className={classNames(options.className, "flex justify-end gap-2")}>
        <span className="text-gray-500 text-sm">
          {isCompleted
            ? lastUpdatedAt
              ? `Completed on ${formatDate(lastUpdatedAt, formatsByTypes.LONG_DATE)}`
              : "No completion date provided"
            : lastUpdatedAt
              ? `Updated ${formatDistance(lastUpdatedAt, new Date(), { addSuffix: true })}`
              : "No last updated date provided"}
        </span>
      </div>
    )
  }

  return (
    <div className="my-6 mx-auto flex flex-col justify-between gap-4">
      {intakes?.map((intake) => (
        <Panel
          key={intake.questionnairesData.qResponse!.id}
          headerTemplate={(options) => renderHeaderTemplate(options, intake.questionnairesData)}
          footerTemplate={(options) => renderFooterTemplate(options, intake.questionnairesData.qResponse!)}
          pt={{
            content: {
              className: "rounded-br-none rounded-bl-none",
            },
          }}
        >
          <section className="flex flex-col divide-y">
            {!!intake.observations?.length && <IntakeObservationsSection observations={intake.observations} />}

            {!!intake?.medicationStatements?.length && (
              <IntakeDataSection
                title="Medication Statements"
                data={intake.medicationStatements}
                modelBuilder={medicationModel}
                onLinkClicked={() => showSubModule({ subModule: appSubModules.intake[ModulesId.MEDICATIONE] })}
                isLinkVisible={isModuleActive(ModulesId.INTAKE)}
                className="gap-4 pt-3"
              />
            )}

            {!!intake?.allergiesIntolerances?.length && (
              <IntakeDataSection
                title="Allergies"
                data={intake.allergiesIntolerances}
                modelBuilder={allergyModelBuilder}
                onLinkClicked={() => showSubModule({ subModule: appSubModules.intake[ModulesId.ALLERGY] })}
                isLinkVisible={isModuleActive(ModulesId.INTAKE)}
                className="gap-4 pt-3"
              />
            )}

            {!!intake?.conditions?.length && (
              <IntakeDataSection
                title="Conditions"
                data={intake.conditions}
                modelBuilder={conditionModelBuilder}
                onLinkClicked={() => showSubModule({ subModule: appSubModules.intake[ModulesId.CONDITIONS] })}
                isLinkVisible={isModuleActive(ModulesId.INTAKE)}
                className="gap-4 pt-3"
              />
            )}
          </section>
        </Panel>
      ))}
    </div>
  )
}

type Props = {
  intakes?: Intake[]
  isUpdatingSurvey: boolean
  isMCPlan: boolean
  onSurveyHide: (options?: RefetchOptions) => Promise<QueryObserverResult>
}

export { CPIntakesDetails }
