import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { AllergyIntolerance, codeableConceptAsString } from "fhir"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { faCalendarDays, faCalendarExclamation, faTags } from "@fortawesome/pro-regular-svg-icons"

import { Badge, StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate, strCapitalize } from "utils"

const allergyModelBuilder = (
  allergy: AllergyIntolerance,
  edit?: () => void,
  remove?: () => void,
): StackedListItemProps => ({
  leftData: [
    { lineItems: [{ name: "Coding", value: codeableConceptAsString(allergy.code) }] },
    {
      lineItems: [
        ...(allergy.recordedDate
          ? [
              {
                name: "Recorded date",
                value: formatDate(new Date(allergy.recordedDate), formatsByTypes.LONG_DATETIME),
                icon: faCalendarDays,
              },
            ]
          : []),
        ...(allergy.lastOccurrence
          ? [
              {
                name: "Last ocurrence",
                value: formatDate(new Date(allergy.lastOccurrence), formatsByTypes.LONG_DATETIME),
                icon: faCalendarExclamation,
              },
            ]
          : []),
        ...(allergy.category
          ? [
              {
                name: "Category",
                component: (
                  <p
                    className={classNames("@md:flex items-center truncate", {
                      "mt-1 @md:mt-0 @md:ml-3": true,
                    })}
                    title="Category"
                  >
                    <FontAwesomeIcon icon={faTags} className="fa-fw mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400" />
                    <label className={`label-${allergy.id}`}>
                      {strCapitalize(allergy.category[0])}
                      {allergy.category.length > 1 && (
                        <Tooltip
                          target={`.label-${allergy.id}`}
                          content={allergy.category.slice(1).join(", ")}
                        ></Tooltip>
                      )}
                      {allergy.category.length > 1 && (
                        <Badge
                          className="opacity-50 ml-1"
                          text={"+" + (allergy.category.length - 1).toString()}
                        ></Badge>
                      )}
                    </label>
                  </p>
                ),
              },
            ]
          : []),
      ],
    },
  ],
  badge: allergy.clinicalStatus?.coding?.[0]
    ? {
        text: allergy.clinicalStatus.coding[0].code as string,
        colorStyle: allergy.clinicalStatus.coding[0].code === "active" ? "red" : "green",
      }
    : undefined,
  menu: [
    ...(edit && remove
      ? [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
            command: edit,
          },
          {
            label: "Delete",
            icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
            command: remove,
          },
        ]
      : []),
  ],
})

export { allergyModelBuilder }
