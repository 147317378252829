import { faEllipsisVertical, faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox } from "primereact/checkbox"
import { Menu } from "primereact/menu"
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"

import { MenuStyles } from "../types"
import { Badge } from "./Badge"
import { StackedListItemProps } from "./StackedListItem"

const StackedListItemCheckeable = ({
  modelData: {
    leftData,
    rightData,
    image,
    menu,
    menuStyle = MenuStyles.Dropdown,
    isLoading,
    columnStyle = "Edge",
    badge,
  },
  checked,
  disabled,
  onCheck,
  modeAuto = true,
  shadowed,
  itemPadding = "py-4",
}: Props) => {
  const menuRef = useRef<Menu>(null)
  const [checkStatus, setCheckStatus] = useState(checked)

  useEffect(() => {
    setCheckStatus(checked)
  }, [checked])

  return (
    <li
      className={classNames("flex items-center", itemPadding, { "cursor-pointer": !disabled })}
      onClick={
        disabled
          ? undefined
          : (e) => {
              e.stopPropagation()
              setCheckStatus(!checkStatus)
              onCheck?.(modeAuto ? !checkStatus : !checked)
            }
      }
    >
      <div className="flex items-center mr-3">
        <Checkbox checked={modeAuto ? !!checkStatus : !!checked} disabled={disabled} />
      </div>
      <div
        className={classNames("min-w-0 flex-1 items-center", {
          "flex justify-between": columnStyle === "Edge",
          "grid grid-cols-2": columnStyle === "Grid",
          "px-4": image,
        })}
      >
        <div className="truncate">
          {leftData?.map(({ lineItems: lineItem }, line) => (
            <div
              key={`ml${line}`}
              className={classNames("flex text-sm", {
                "text-gray-900 font-medium": line === 0 && !disabled && !shadowed,
                "text-gray-500 font-medium": line === 0 && (disabled || shadowed),
                "text-gray-400 text-xs mt-1": line !== 0,
              })}
            >
              {lineItem.map((item, index) => (
                <p
                  key={`ml${line}i${index}`}
                  className={classNames("flex items-center", {
                    "ml-3": index !== 0,
                    "cursor-pointer": item.onClick,
                  })}
                  title={item.name}
                  onClick={item.onClick}
                >
                  {item.icon && (
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="fa-fw mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400"
                    />
                  )}
                  {item.value}
                </p>
              ))}
            </div>
          ))}
        </div>
        {rightData && (
          <div
            className={classNames("w-fit text-right flex flex-col", {
              "items-end": columnStyle === "Edge",
              "items-start": columnStyle === "Grid",
            })}
          >
            {badge && (
              <Badge
                className="hidden @md:inline-flex"
                text={badge.text}
                colorStyle={badge.colorStyle}
                size={badge.size}
              />
            )}
            {rightData.map(({ lineItems: lineItem }, line) => (
              <div
                key={`sl${line}`}
                className={classNames("flex text-gray-400 text-xs", {
                  "mt-1": line !== 0,
                })}
              >
                {lineItem.map((item, index) => (
                  <p
                    key={`sl${line}i${index}`}
                    className={classNames("flex items-center", {
                      "ml-3": index !== 0,
                      "cursor-pointer": item.onClick,
                    })}
                    title={item.name}
                    onClick={item.onClick}
                  >
                    {item.icon && (
                      <FontAwesomeIcon
                        icon={item.icon}
                        className="fa-fw mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400"
                      />
                    )}
                    {item.value}
                  </p>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      {badge && (
        <Badge
          className={classNames("h-fit ml-3", {
            "@md:hidden": rightData,
          })}
          text={badge.text}
          colorStyle={badge.colorStyle}
          size={badge.size}
        />
      )}
      {(isLoading || (menu && menu.length > 0)) && (
        <div className="ml-3 mr-1 flex-shrink-0 text-gray-400 text-sm">
          {isLoading ? (
            <span>
              <FontAwesomeIcon icon={faSpinner} spin className="fa-fw" />
            </span>
          ) : menuStyle === MenuStyles.ExternalAction ? (
            <span
              className="cursor-pointer"
              title={menu?.[0].label}
              onClick={(event) => {
                event.stopPropagation()
                !menu?.[0].disabled && menu?.[0].command?.({ originalEvent: event, item: menu[0] })
              }}
            >
              <FontAwesomeIcon icon={faExternalLink} className="fa-fw" />
            </span>
          ) : menuStyle === MenuStyles.ActionItems ? (
            menu?.map((menuItem, index) => (
              <span
                key={index}
                className="cursor-pointer"
                title={menuItem.label}
                onClick={(event) => {
                  event.stopPropagation()
                  !menuItem.disabled && menuItem.command?.({ originalEvent: event, item: menuItem })
                }}
              >
                {menuItem.icon ?? menuItem.label}
              </span>
            ))
          ) : (
            <span
              className="cursor-pointer flex"
              title="Menu"
              onClick={(event) => {
                event.stopPropagation()
                menuRef.current && menuRef.current.toggle(event)
              }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} className="fa-fw h-4 w-4" />
              <Menu model={menu} popup ref={menuRef} id="popup_menu" style={{ fontSize: "small" }} />
            </span>
          )}
        </div>
      )}
    </li>
  )
}

type ModeType =
  | {
      modeAuto?: false
      onCheck(checked: boolean): void
      checked: boolean
    }
  | {
      modeAuto: true
      checked?: boolean
      onCheck?(checked: boolean): void
    }

type Props = {
  modelData: StackedListItemProps
  disabled?: boolean
  shadowed?: boolean
  itemPadding?: string
} & ModeType

export { StackedListItemCheckeable }
