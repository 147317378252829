import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { differenceInMinutes } from "date-fns"
import { Reference, ResourceObject, asReference, isPractitioner } from "fhir"
import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useLocation } from "react-router-dom"

import { loginRequest, setupTOTPRequest } from "authConfig"
import { AppModule, AppSubModulesTypes, CustomError, LoadingView } from "commons"
import { datadogRum as dataDog } from "datadog"
import { AppModules, AppSubModules } from "internals"
import { datadogLogs, registerErrorTrace } from "logger"

import { AuthError } from "../components"

const AuthContext = createContext<State | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const DEFAULT_RENEW_TOKEN_TIMEOUT = 3 // Wait 3 minutes by default due the min time allowed is 5 minutes

const isDevelop = process.env.NODE_ENV === "development"

const userMuck = isDevelop && import.meta.env.VITE_APP_MOCK_USER && JSON.parse(import.meta.env.VITE_APP_MOCK_USER)

const AuthProvider: FC<PropsWithChildren<Props>> = ({ children, isOnline, setIsOnline }) => {
  const { pathname, search } = useLocation()
  const { instance } = useMsal()
  const [isLoading, setIsloading] = useState(!userMuck)
  const [user, setUser] = useState<User | undefined>(userMuck)
  const [error, setError] = useState<Error | undefined>()
  const isOnlineRef = useRef(isOnline)
  const refreshTokenTimer = useRef<NodeJS.Timeout | null>(null)
  const retryRenewTokenCount = useRef(0)

  const clearRefreshTokenTimer = () => {
    if (refreshTokenTimer.current) {
      clearTimeout(refreshTokenTimer.current)
    }
  }

  const initializeUser = (response: AuthenticationResult) => {
    instance.setActiveAccount(instance.getAccountByHomeId(response.account?.homeAccountId))

    const name = response.account?.name ?? "unspecified"
    const email = (response.idTokenClaims as { email: string })?.email ?? "unspecified email"
    const token = `${response.tokenType} ${response.accessToken}`
    let logId = email

    const linkedUser = getLinkedUser(response)
    const b2cUserId = (response.idTokenClaims as IdTokenClaims)["sub"]

    if (!b2cUserId) {
      datadogLogs.setUser({ id: response.account?.homeAccountId ?? email, name, email })

      setError(
        new Error("Unauthorized", {
          cause: { name: "401", message: `No B2C user linked to ${name}` },
        }),
      )
    } else if (!linkedUser) {
      datadogLogs.setUser({ id: response.account?.homeAccountId ?? email, name, email })

      setError(
        new Error("Unauthorized", {
          cause: { name: "401", message: `No resource linked to user ${name}` },
        }),
      )
    } else {
      logId = linkedUser.id ?? email

      datadogLogs.setUser({ id: response.account?.homeAccountId ?? email, name, email })
      datadogLogs.logger.info(`User ${response.account?.homeAccountId ?? email} logged in successfully!`, {
        id: response.account?.homeAccountId ?? email,
        name,
        email,
      })

      setUser({ name, email, token, linkedUser, b2cUserId })
    }

    dataDog.setUser({
      id: logId,
      name: name,
      email: email,
    })

    setIsloading(false)
  }

  const tryRenewAccessToken = async () => {
    const account = instance.getActiveAccount()

    if (account) {
      const request = {
        ...loginRequest,
        account,
      }

      // Silently acquires an access token which is then attached to a request for aidbox data
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          const interval = getRenewTokenInterval(response)

          initializeUser(response)
          clearRefreshTokenTimer()

          retryRenewTokenCount.current = 0
          refreshTokenTimer.current = setTimeout(() => tryRenewAccessToken(), interval)
        })
        .catch(async (error) => {
          if (
            error?.errorMessage?.includes("AADB2C90077") ||
            error?.errorMessage?.includes("AADB2C90091") ||
            error?.errorMessage?.includes("AADB2C90080") ||
            error instanceof InteractionRequiredAuthError
          ) {
            await instance.loginRedirect(loginRequest)
          } else {
            if (isNetworkError(error)) {
              setIsOnline(false)

              throw new Error("NetworkError", { cause: { name: "499", message: "NetworkError" } })
            } else if (/post_request_failed/.test(error?.message)) {
              if (retryRenewTokenCount.current <= 2) {
                retryRenewTokenCount.current++

                tryRenewAccessToken()
              } else {
                await instance.loginRedirect(loginRequest)
              }
            } else {
              setError(new Error(error.errorCode, { cause: { name: error.name, message: error.errorMessage } }))
            }
          }
        })
        .finally(() => setIsloading(false))
    } else {
      await instance.loginRedirect(loginRequest)
    }
  }

  const checkAccount = async () => {
    await instance.initialize()
    instance
      .handleRedirectPromise()
      .then(async (response) => {
        if (response?.account) {
          const interval = getRenewTokenInterval(response)

          initializeUser(response)
          clearRefreshTokenTimer()

          refreshTokenTimer.current = setTimeout(() => tryRenewAccessToken(), interval)
        } else {
          const account = instance.getActiveAccount()

          if (!account) {
            await instance.loginRedirect(loginRequest)
          } else {
            await tryRenewAccessToken()
          }
        }
      })
      .catch(async (error) => {
        setIsloading(false)

        if (
          error?.errorMessage?.includes("AADB2C90077") ||
          error?.errorMessage?.includes("AADB2C90091") ||
          error?.errorMessage?.includes("AADB2C90080") ||
          error instanceof InteractionRequiredAuthError
        ) {
          await instance.loginRedirect(loginRequest)
        } else if (error?.errorMessage?.includes("AADB2C99002")) {
          setError(
            new Error(error.errorCode, {
              cause: {
                name: "No account found",
                message: "We couldn't find an account matching your information.",
              },
            }),
          )
        } else if (error?.errorMessage?.includes("AADB2C90273")) {
          setError(
            new Error(error.errorCode, {
              cause: {
                name: "Access denied",
                message: "The resource owner or authorization server denied the request.",
              },
            }),
          )
        } else {
          if (isNetworkError(error)) {
            setIsOnline(false)
            throw new Error("NetworkError", { cause: { name: "499", message: "NetworkError" } })
          } else {
            setError(new Error(error.errorCode, { cause: { name: error.name, message: error.errorMessage } }))
          }
        }
      })
  }

  useEffect(() => {
    if (!userMuck) checkAccount()
  }, [])

  useEffect(() => {
    if (!isOnline) {
      clearRefreshTokenTimer()
    } else if (!isOnlineRef.current && isOnline) {
      checkAccount()
    }

    isOnlineRef.current = isOnline
  }, [isOnline])

  const setupTOTP = useCallback(() => {
    instance.acquireTokenRedirect(setupTOTPRequest)
  }, [instance])

  const setupSMS = useCallback(() => {
    window.location.href = `${window.VITE_APP_AUTH_URL}/phone-setup?redirectUrl=${window.location.href}`
  }, [])

  const logout = useCallback(
    async (isSessionExpired?: boolean) => {
      const account = instance.getActiveAccount()
      window.Intercom("shutdown")

      if (account) {
        const logoutRequest = {
          account: instance.getAccountByHomeId(account.homeAccountId),
          postLogoutRedirectUri: isSessionExpired ? `${pathname}${search}` : "/",
        }
        await instance.logoutRedirect(logoutRequest)
      }
    },
    [instance, pathname, search],
  )

  const setLinkedResource = useCallback(
    (resource: ResourceObject) => {
      const linkedResource = asReference(resource)

      if (!isPractitioner(linkedResource)) {
        setError(
          new Error("Unauthorized", {
            cause: {
              name: "401",
              message: `Sorry ${
                linkedResource.display ?? "Unknown tenant"
              }, but you don't have permission to access to EHR. If you think it is a mistake, contact to support.`,
            },
          }),
        )
      } else {
        if (user) {
          datadogLogs.setUser({ id: linkedResource.id, name: user.name, email: user.email })
          setUser({ ...user, linkedResource })
        }
      }
    },
    [user],
  )

  const value = useMemo(
    () => ({
      AppModules,
      AppSubModules,
      user,
      setupTOTP,
      logout,
      setupSMS,
      setLinkedResource,
    }),
    [user, setupTOTP, logout, setupSMS, setLinkedResource],
  )

  if (isLoading) {
    return <LoadingView />
  }

  if (error) {
    const customError = registerErrorTrace(error as CustomError)
    const isCORSError = error?.message === "AADB2C90002"

    return <AuthError error={customError} logout={logout} shouldRetry={isCORSError} />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const isNetworkError = (error: Error) => /endpoints_resolution_error|no_network_connectivity/.test(error?.message)

const getRenewTokenInterval = (response: AuthenticationResult) => {
  const diff = response.expiresOn ? differenceInMinutes(response.expiresOn, new Date()) : DEFAULT_RENEW_TOKEN_TIMEOUT

  return Math.floor((diff * 2) / 3) * 60000
}

const getLinkedUser = (response: AuthenticationResult): Reference | undefined => {
  try {
    const claimUsers = JSON.parse((response.idTokenClaims as IdTokenClaims)["aidbox/users"]) as string[]

    if (claimUsers?.length) {
      const user = claimUsers.find((user) => user.includes("evexias"))

      if (user) {
        const [, id] = user.split("|")

        return {
          id,
          resourceType: "User",
        }
      }
    }

    if ((response.idTokenClaims as IdTokenClaims)["user/id"]) {
      return {
        id: (response.idTokenClaims as IdTokenClaims)["user/id"],
        resourceType: "User",
      }
    }
  } catch (error) {
    console.error(error)
    return undefined
  }
}

type IdTokenClaims = {
  "user/id": string
  "aidbox/users": string
  sub: string
}

type State = {
  AppModules: AppModule[]
  AppSubModules: AppSubModulesTypes
  user?: User
  setupTOTP(): void
  setupSMS(): void
  logout(): void
  setLinkedResource(resource: ResourceObject): void
}

export type User = {
  email: string
  name: string
  token: string
  linkedUser: Reference
  linkedResource?: Reference
  hmac?: string
  b2cUserId: string
}

type Props = {
  isOnline: boolean
  setIsOnline(status: boolean): void
  children: ReactNode
}

export { AuthContext, AuthProvider }
