import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core"
import { faCircleNotch, faPlus, faSpinner, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"

import { RoundedStyles } from "../types"

const Button = ({
  type = "button",
  label,
  icon,
  iconClassName = "h-5 w-5",
  buttonStyle = "primary",
  size = "md",
  roundedStyle = RoundedStyles.Both,
  className,
  onClick,
  disabled,
  loading,
  title,
  borderless = buttonStyle === "text",
  font = buttonStyle === "text" ? "font-bold" : "font-medium",
}: Props) => (
  <button
    type={type}
    className={classNames(
      font,
      { "shadow-sm focus:primary-ring-shadow ring-1 ring-inset": !borderless },
      { "bg-white text-gray-700 ring-gray-300": buttonStyle === "default" },
      { "ring-primary hover:ring-primary-hover": ["primary", "outlined", "text"].includes(buttonStyle) },
      {
        "text-primary hover:text-primary-hover": ["outlined", "text"].includes(buttonStyle),
      },
      { "bg-primary text-white": !["default", "outlined", "text"].includes(buttonStyle) },
      { "hover:bg-slate-100": buttonStyle === "default" && !disabled },
      { "hover:bg-primary-hover": !["default", "outlined", "text"].includes(buttonStyle) && !disabled },
      { "inline-flex items-center gap-x-1.5": icon || loading },
      { "text-xs": size === "xs" },
      { "text-sm": size !== "xs" },
      { "rounded-l": ["xs", "sm"].includes(size) && (roundedStyle & RoundedStyles.Left) === RoundedStyles.Left },
      { "rounded-r": ["xs", "sm"].includes(size) && (roundedStyle & RoundedStyles.Right) === RoundedStyles.Right },
      {
        "rounded-l-md":
          !["xs", "sm"].includes(size) &&
          buttonStyle !== "floating" &&
          (roundedStyle & RoundedStyles.Left) === RoundedStyles.Left,
      },
      {
        "rounded-r-md":
          !["xs", "sm"].includes(size) &&
          buttonStyle !== "floating" &&
          (roundedStyle & RoundedStyles.Right) === RoundedStyles.Right,
      },
      { "rounded-l-full": (roundedStyle & RoundedStyles.Full_Left) === RoundedStyles.Full_Left },
      { "rounded-r-full": (roundedStyle & RoundedStyles.Full_Right) === RoundedStyles.Full_Right },
      { "rounded-full z-20": buttonStyle === "floating" },
      { "py-1 px-2": ["xs", "sm"].includes(size) && buttonStyle !== "floating" },
      { "py-1.5 px-2.5": size === "md" && buttonStyle !== "floating" },
      { "py-2 px-3": size === "lg" && buttonStyle !== "floating" },
      { "py-2.5 px-3.5": size === "xl" && buttonStyle !== "floating" },
      { "p-1": ["xs", "sm"].includes(size) && buttonStyle === "floating" },
      { "p-2": size === "md" && buttonStyle === "floating" },
      { "p-3": size === "lg" && buttonStyle === "floating" },
      { "p-4": size === "xl" && buttonStyle === "floating" },
      { "opacity-60": disabled || loading },
      className,
    )}
    onClick={onClick}
    disabled={disabled || loading}
    title={title}
  >
    {(icon || loading) && (
      <FontAwesomeIcon
        icon={loading ? faCircleNotch : (icon as IconDefinition | IconName)}
        className={iconClassName}
        spin={loading}
      />
    )}
    {label}
  </button>
)

const FloatingButton = ({
  size = "xl",
  icon = faPlus,
  className = "fixed bottom-6 right-6",
  ...props
}: FloatingProps) => {
  return <Button buttonStyle="floating" size={size} icon={icon} className={className} {...props} />
}

const AddFieldArrayItemButton = ({
  className = "py-4",
  onClick,
  disabled,
  label = "Add new",
  loading,
}: FieldArrayProps) => (
  <div className={classNames("p-3 flex flex-row w-full", className)}>
    <button
      id={`field-array-button-${label}`}
      type="button"
      className={classNames("bg-white flex focus:outline-none items-center group")}
      onClick={() => !loading && onClick?.()}
      disabled={disabled}
    >
      <span
        className={classNames(
          "border-2 border-dashed border-gray-300 flex h-8 w-8 items-center justify-center rounded-full text-gray-400",
          { "group-hover:border-gray-400 group-hover:text-gray-500": !disabled },
        )}
      >
        <FontAwesomeIcon icon={loading ? faSpinner : faPlus} spin={loading} className="h-5 w-5" />
      </span>
      <span
        className={classNames(
          "ml-4 text-sm font-medium",
          { "text-gray-400": disabled },
          { "text-primary hover:text-primary-hover": !disabled },
        )}
      >
        {label}
      </span>
    </button>
  </div>
)

const DismissButton = ({ label, className, onClick }: DismissProps) => (
  <button
    type="button"
    title={label}
    className={classNames(
      "flex justify-center items-center h-8 w-8 rounded-full text-gray-400",
      "hover:bg-gray-50 hover:border hover:border-gray-200 hover:text-gray-500",
      className,
    )}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faTimes} className="h-5 w-5" />
  </button>
)

type FloatingProps = Omit<Props, "buttonStyle">

type FieldArrayProps = Pick<Props, "label" | "className" | "onClick" | "disabled" | "loading">

type DismissProps = Pick<Props, "label" | "className" | "onClick">

type Props = {
  id?: string
  type?: "submit" | "button"
  label?: string
  icon?: IconDefinition | IconName
  iconClassName?: string
  buttonStyle?: "default" | "primary" | "floating" | "outlined" | "text"
  font?: "font-bold" | "font-semibold" | "font-medium" | "font-normal"
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  roundedStyle?: RoundedStyles
  className?: string
  onClick?(event?: React.SyntheticEvent | undefined | null): void
  disabled?: boolean
  loading?: boolean
  title?: string
  borderless?: boolean
}

export { Button, FloatingButton, AddFieldArrayItemButton, DismissButton }
