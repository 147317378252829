import { DialogFormContainer } from "commons"

import { MedicationForm } from "./MedForm"
import { MedItem, MedParams } from "../../types"
import { useEditMK } from "administration/hooks/meds/useEditMK"

const MedicationFormContainer = ({ med, onHide }: Props) => {
  const initialValue = med ?? { fee: 0, basePrice: 0, id: "" }

  const { editMK } = useEditMK(() => {
    onHide()
  })
  const onSubmit = (data: MedParams) => {
    editMK(data)
  }

  const isEditing = !!initialValue.id

  return (
    <>
      <DialogFormContainer
        onSubmit={onSubmit}
        onCancel={onHide}
        showForm={true}
        title={"Edit medication"}
        saveLabel={"Update"}
        initialValue={{
          ...initialValue,
        }}
        useFormik
        isLoadingData={false}
      >
        <MedicationForm isEditing={isEditing} />
      </DialogFormContainer>
    </>
  )
}

type Props = {
  med?: MedItem
  onHide(): void
}

export { MedicationFormContainer }
