import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  ActivityDefinition,
  Bundle,
  getResources,
  Invoice,
  Provenance,
  Reference,
  RequestGroup,
  RequestGroupActionArrayActionArray,
  ResourceObject,
  ServiceRequest,
} from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { plansQueryKeys } from "commons/care-plans"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { labOrdersQueryKeys } from "laboratory-orders"
import { datadogLogs, registerErrorTrace } from "logger"
import { proceduresQueryKeys } from "procedures/query-keys"
import { displayNotificationSuccess } from "utils"

import { ordersQueryKeys } from "../query-keys"

const useCpoeOrdersFinish = (
  onSettled: (data: unknown, error: CustomError | null) => void,
  onSuccess: (data: { invoice?: Invoice[]; processedRequestRefs?: Reference[] }) => void,
  requestsTypes: RequestsTypes,
  shouldRefetchPlansQuery: boolean,
) => {
  const { operationRequest, patch } = useClient()
  const queryClient = useQueryClient()

  const finish = async ({
    patientId,
    requestGroup,
    activityDefinition,
    scheduleDate,
  }: {
    patientId: string
    requestGroup: RequestGroup
    activityDefinition?: ActivityDefinition
    scheduleDate?: string
  }) => {
    if (scheduleDate) {
      const requestActions = requestGroup.action?.[0].action?.reduce((acc, ac) => {
        if (ac.code?.[0].coding?.[0].code === "activate") {
          const newAction = {
            ...ac,
            code: [
              {
                coding: [
                  {
                    ...ac.code?.[0].coding?.[0],
                    code: "on-hold",
                    display: "On-hold",
                  },
                ],
              },
            ],
            timing: {
              dateTime: scheduleDate,
            },
          } as RequestGroupActionArrayActionArray
          return [...acc, newAction]
        }
        return [...acc, { ...ac }]
      }, new Array<RequestGroupActionArrayActionArray>())

      const requestAction = { ...requestGroup?.action?.[0], action: requestActions }
      requestGroup.action?.splice(0, 1, requestAction)
    }

    const updatedContained = [activityDefinition, ...(requestGroup.contained ?? [])] as ResourceObject[]

    const result = await patch(
      "RequestGroup",
      requestGroup.id as string,
      {
        contained: updatedContained,
        action: requestGroup?.action,
        meta: requestGroup.meta,
      } as ResourceObject,
    )
    if (result) {
      const bundle = await operationRequest<Bundle>({
        endpoint: `Patient/${patientId}/cpoe`,
        method: "POST",
        operation: "finish",
      })

      const sr = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const invoice = getResources<Invoice>(bundle, "Invoice")
      const processedRequestRefs = getResources<Provenance>(bundle, "Provenance").reduce((acc, prov) => {
        const processedRequests = prov.target
        return [...acc, ...processedRequests]
      }, Array<Reference>())

      return { serviceRequest: sr, invoice, processedRequestRefs }
    }
    return null
  }

  const { mutate: ordersFinish, isPending: isFinishing } = useMutation({
    mutationFn: finish,
    onSuccess: async (data, { patientId }) => {
      const queriesToInvalidate = getQueriesToInvalidate(patientId, requestsTypes, shouldRefetchPlansQuery)
      await Promise.all(
        queriesToInvalidate.map(({ queryKey, shouldRefetch }) =>
          shouldRefetch
            ? queryClient.refetchQueries({ queryKey })
            : queryClient.invalidateQueries({ queryKey, refetchType: "all" }),
        ),
      )
      displayNotificationSuccess("Order processed successfully!")
      const srIds = data?.serviceRequest.flatMap(({ id }) => id).join(", ")
      datadogLogs.logger.info(`Order ${srIds} processed successfully!`, {
        srId: srIds,
        invoice: data?.invoice,
      })
      onSuccess({ invoice: data?.invoice, processedRequestRefs: data?.processedRequestRefs })
    },
    onSettled: (data, error: CustomError | null, { patientId }) => {
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      onSettled?.(data, error)
    },
    onError: async (error: CustomError, { patientId }) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey: ordersQueryKeys.list(patientId), refetchType: "all" })
      }
      displayNotificationError(registerErrorTrace(error, { finish: "CPOE finish", patientId }))
    },
  })

  return { ordersFinish, isFinishing }
}

const getQueriesToInvalidate = (
  patientId: string,
  { hasLabsRequests, hasNutraRequests, hasPlanBasedRequests, hasProcedureRequests, hasRXRequests }: RequestsTypes,
  shouldRefetchPlansQuery: boolean,
) =>
  [
    { queryKey: labOrdersQueryKeys.withPatientID(patientId), shouldInvalidate: hasLabsRequests },
    {
      queryKey: plansQueryKeys.details(patientId),
      shouldInvalidate: hasPlanBasedRequests,
      shouldRefetch: shouldRefetchPlansQuery,
    },
    { queryKey: medsQueryKeys.medicationRequestList(patientId, "nutraceutical"), shouldInvalidate: hasNutraRequests },
    { queryKey: medsQueryKeys.medicationRequestList(patientId, "medication"), shouldInvalidate: hasRXRequests },
    { queryKey: proceduresQueryKeys.list(patientId), shouldInvalidate: hasProcedureRequests },
  ].filter(({ shouldInvalidate, shouldRefetch }) => shouldInvalidate || shouldRefetch)

type RequestsTypes = {
  hasLabsRequests: boolean
  hasNutraRequests: boolean
  hasRXRequests: boolean
  hasProcedureRequests: boolean
  hasPlanBasedRequests: boolean
}

export { useCpoeOrdersFinish }
