import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { eprescribeQueryKeys } from "../query-keys"

const useSigSuggestions = ({
  input,
  sku,
  depth = 3,
  seq = 0,
}: {
  input: string
  sku?: string
  depth?: number
  seq?: number
}) => {
  const { operationRequest } = useClient()

  const queryKey = eprescribeQueryKeys.sigSuggestions(input)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams([
        ["input", input],
        ["code", sku ?? ""],
        ["depth", `${depth}`],
        ["seq", `${seq}`],
      ])

      const result = (await operationRequest({
        endpoint: "",
        method: "GET",
        operation: "suggest",
        filters,
      })) as SuggestionsResponse

      return result
    },
    meta: { context: { queryKey } },
    enabled: !!sku,
  })

  return { suggestions: data?.suggestions ?? [], suffix: data?.suffix, isLoading, refetch }
}

type SuggestionsResponse = { suffix: string; suggestions: string[] }

export { useSigSuggestions }
