import { useMutation, useQueryClient } from "@tanstack/react-query"
import { asReference, Parameters, Patient, Questionnaire, Reference } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { surveysQueryKeys } from "../query-keys"

const useAssignQuestionnaire = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const assignedQ = async ({ questionnaire, patient, sendByEmail = true, openEncounter }: Params) => {
    const patientRef = asReference(patient)
    const response = await operationRequest<Parameters>({
      endpoint: "Questionnaire",
      method: "POST",
      operation: "populate-survey",
      id: questionnaire.id,
      parameters: {
        parameter: [
          { name: "subject", value: { Reference: patientRef } },
          ...(sendByEmail ? [{ name: "sendByEmail", value: { boolean: sendByEmail } }] : []),
          ...(openEncounter ? [{ name: "encounter", value: { Reference: openEncounter } }] : []),
        ],
        resourceType: "Parameters",
      },
    })

    return { response }
  }

  const { mutate: assignQuestionnaire, isPending: isAssigning } = useMutation({
    mutationFn: assignedQ,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async ({ response }) => {
      await queryClient.invalidateQueries({ queryKey: surveysQueryKeys.inProgress.all })
      await queryClient.invalidateQueries({ queryKey: surveysQueryKeys.completed.all })
      displayNotificationSuccess("The questionnaire has been assigned successfully!")
      datadogLogs.logger.info("The questionnaire has been assigned successfully!", { response })
    },
    onSettled: onSettled,
  })

  return { assignQuestionnaire, isAssigning }
}

export type Params = {
  patient: Patient
  sendByEmail?: boolean
  questionnaire: Questionnaire
  openEncounter?: Reference
}

export { useAssignQuestionnaire }
