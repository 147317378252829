import { faCalendarCheck, faPencil, faStar, faTrashCan, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Account, AccountBETACreditCardArray } from "fhir"
import { FC } from "react"

import {
  ConfirmDialog,
  DataContainerSlideoverForm,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"
import { formatCreditCardNumber, getAddressByType } from "utils"

import {
  useAccountCreditCards,
  useCreateCreditCard,
  useDeleteCreditCard,
  useUpdateCreditCard,
  useUpdateDefaultCreditCard,
} from "../hooks"
import { CreditCardForm } from "./CreditCardForm"
import { CreditCardImage } from "./CreditCardImage"
import { creditCardValidationSchema, getInitialValues } from "./validations"

const CreditCardContainer: FC = () => {
  const { patientId, patient } = usePatientContext()
  const { appSubModules } = useAppModuleContext()

  const { accountId, account, creditCards, defaultCreditCard, isLoading, isFetching } = useAccountCreditCards(patientId)
  const { showSlide, initialValue, isNew, editIndex, deleteIndex, reset, add, editWithIndex, setDeleteIndex } =
    useCrudReducer({ defaultEntity: getInitialValues(patient) })

  const { createCreditCard } = useCreateCreditCard(reset)
  const { updateCreditCard } = useUpdateCreditCard(reset)
  const { removeCreditCard, isDeleting } = useDeleteCreditCard(() => setDeleteIndex())
  const { updateDefaultCreditCard, isUpdating } = useUpdateDefaultCreditCard({ onSettled: reset })

  const onSubmit = (creditCard: AccountBETACreditCardArray) => {
    isNew
      ? createCreditCard({
          creditCard,
          account: account as Account,
          creditCardList: creditCards,
          patientId,
        })
      : updateCreditCard({
          creditCard,
          account: account as Account,
          creditCardList: creditCards,
          index: editIndex as number,
        })
  }

  const getCCid = (creditCard: AccountBETACreditCardArray) => `${creditCard.type}|${creditCard.last4Digits}`
  const defaultCreditCardId = defaultCreditCard && getCCid(defaultCreditCard)

  if (isLoading) return <SkeletonLoader repeats={4} loaderType="two-lines" />

  return (
    <DataContainerSlideoverForm
      hasData={!!creditCards?.length}
      showSlide={showSlide}
      formTitle={"Credit Card"}
      formInitialValue={initialValue}
      validationSchema={creditCardValidationSchema}
      subMessageDataNotFound={false}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<CreditCardForm isEditing={!isNew} shippingAddress={getAddressByType("postal", patient.address)} />}
      customAddButtonText="Add Credit Card"
      onButtonAddClick={add}
      iconDataNotFound={appSubModules["patient"][ModulesId.CREDIT_CARDS].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={creditCards}
          itemModelBuilder={(item, index) =>
            modelBuilder(
              item,
              defaultCreditCardId === getCCid(item),
              isUpdating || isFetching,
              isExpired(item),
              () => {
                updateDefaultCreditCard({
                  creditCardId: getCCid(item),
                  account: account as Account,
                })
              },
              () => editWithIndex(item, index),
              () => setDeleteIndex(index),
            )
          }
        />
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to remove this credit card?`}
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() =>
          removeCreditCard({
            index: deleteIndex as number,
            accountId: accountId as string,
            creditCardList: creditCards,
            defaultCreditCardId,
            patientId,
          })
        }
        hideDialog={() => setDeleteIndex()}
      />
    </DataContainerSlideoverForm>
  )
}

const isExpired = (creditCard: AccountBETACreditCardArray) =>
  new Date(parseInt(creditCard?.expirationYear as string), parseInt(creditCard?.expirationMonth as string), 1) <
  new Date()

const modelBuilder = (
  creditCard: AccountBETACreditCardArray,
  isPreferred: boolean,
  isUpdating: boolean,
  isExpired: boolean,
  makePreferred: () => void,
  edit: () => void,
  remove: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Number", value: formatCreditCardNumber(creditCard.last4Digits as string, creditCard.type) }],
    },
    {
      lineItems: [
        { name: "Holder name", value: creditCard.cardHolderName ?? "Unspecified", icon: faUser },
        {
          name: "Expiration date",
          value: `${creditCard.expirationMonth?.padStart(2, "0")}-${creditCard.expirationYear}`,
          icon: faCalendarCheck,
        },
        ...(isPreferred ? [{ name: "Preferred credit card", value: "Preferred", icon: faStar }] : []),
      ],
    },
  ],
  isLoading: isUpdating,
  onClick: isPreferred ? undefined : makePreferred,
  menu: [
    { label: "Edit", icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />, command: edit },
    { label: "Delete", icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />, command: remove },
  ],
  image: <CreditCardImage creditCard={creditCard} />,
  badge: isExpired ? { text: "expired", colorStyle: "red" } : undefined,
})

export { CreditCardContainer }
