import { faLink, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, MedicationRequest } from "fhir"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { FC, PropsWithChildren } from "react"

import { BILLING_TYPES_CODES, mrCategoryCodes } from "data"
import { MedicationKnowledgeImage } from "medication-requests"
import { useOrganizationContext } from "organization"
import { SYSTEM_VALUES } from "system-values"
import { getBillingTypeCode, getCodingBySystem, getMedCodes, getPriceByCode, openLinkInNewTab } from "utils"

import { SkeletonLoader } from "../../components/SkeletonLoader"
import { useChargeItemDefinitions } from "../../hooks"
import { useMonograph } from "../hooks"
import { MedicationKnowledgeContraindications } from "./MedicationKnowledgeContraindications"

const MedicationKnowledgeDetails: FC<PropsWithChildren<Props>> = ({
  selectedMK,
  onHide,
  showImgFallback,
  mr,
  children: extraContent,
}) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { monograph, isLoading } = useMonograph(selectedMK?.monograph?.[0].source?.id ?? "")
  const medCodes = getMedCodes({ meds: [...(selectedMK ? [selectedMK] : []), ...(mr ? [mr] : [])], withQty: true })
  const { chargeItemDefinitions, isLoading: isLoadingCID } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      billToPatientCIDs: medCodes,
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })
  const lfID = selectedMK?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.LIFEFILE_MEDICATION)?.code
  const ndc = selectedMK?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.MEDICATION_NDC)?.code
  const doseForm = selectedMK?.doseForm?.coding?.[0]?.display
  const route = selectedMK?.intendedRoute?.[0]?.coding?.[0]?.display?.replace(/use|route/, "")?.trim()
  const patientPrice = getPriceByCode({
    chargeItemDefinitions: chargeItemDefinitions?.billToPatientCIDs ?? {},
    medCoding: selectedMK?.code?.coding,
    factor: mr?.dispenseRequest?.quantity?.value,
    includePatientFee: true,
  })?.value
  const practicePrice = getPriceByCode({
    chargeItemDefinitions: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medCoding: selectedMK?.code?.coding,
    factor: mr?.dispenseRequest?.quantity?.value,
  })?.value
  const packing = selectedMK?.packaging?.type?.coding?.[0]?.display ?? ""
  const flavor = selectedMK?.drugCharacteristic?.find((c) =>
    c.type?.coding?.some((coding) => coding.system === SYSTEM_VALUES.DRUG_CHARACTERISTIC),
  )?.value?.string
  const billingTypeCode = getBillingTypeCode(mr)
  const amount =
    selectedMK?.amount?.value && selectedMK?.amount?.unit
      ? `${selectedMK?.amount?.value} ${selectedMK?.amount?.unit}`
      : "unspecified"

  const handleShowMonographPDF = (url?: string) => {
    openLinkInNewTab(url)
  }
  const renderModalFooter = () => (
    <div className="mt-2">
      <Button label="Close" className="button-default" onClick={onHide} />
    </div>
  )

  return (
    <Dialog
      header={
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <span>{selectedMK?.code?.text}</span>
            <div className="flex divide-x gap-1 text-sm font-normal text-gray-400">
              {selectedMK?.manufacturer?.display && (
                <span className="pl-1 first:pl-0">
                  Pharmacy: {selectedMK.manufacturer.display.replace("Farmakeio", "FarmaKeio")}
                </span>
              )}
              {lfID && <span className="pl-1 first:pl-0">ID: {lfID}</span>}
              {ndc && <span className="pl-1 first:pl-0">NDC: {ndc}</span>}
            </div>
            <div className="flex divide-x gap-1 text-sm font-normal text-gray-400">
              {doseForm && <span className="pl-1 first:pl-0">Drug Form: {doseForm}</span>}
              {<span className="pl-1 first:pl-0">Amount: {amount}</span>}
              {packing && <span className="pl-1 first:pl-0">Pkg: {packing}</span>}
              {route && <span className="pl-1 first:pl-0">Route: {route}</span>}
              {route?.toLowerCase()?.includes("oral") && (
                <span className="pl-1 first:pl-0">Flavor: {flavor ? `Flavor: ${flavor}` : "No Flavor"}</span>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            {isLoadingCID ? (
              <SkeletonLoader loaderType="plain-text" repeats={1} containerClassName="w-28 mr-4" />
            ) : (
              <div className="grid grid-cols-[1fr_auto] gap-1 text-sm font-normal text-gray-500 mr-4">
                {patientPrice && billingTypeCode !== BILLING_TYPES_CODES.BILL_PRACTICE && (
                  <>
                    <span className="text-right">Patient Price:</span>
                    <span className="w-fit">${patientPrice.toFixed(2)}</span>
                  </>
                )}
                {practicePrice &&
                  billingTypeCode !== BILLING_TYPES_CODES.BILL_PATIENT &&
                  getCodingBySystem(mr?.category, SYSTEM_VALUES.MEDICATION_REQUEST_CATEGORY)?.code !==
                    mrCategoryCodes.nutraceutical.code && (
                    <>
                      <span className="text-right">Practice Price:</span>
                      <span className="w-fit">${practicePrice.toFixed(2)}</span>
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
      }
      visible={selectedMK !== undefined}
      draggable={false}
      dismissableMask={true}
      style={{ width: "50vw" }}
      onHide={onHide}
      footer={renderModalFooter}
    >
      <div className="flex flex-col">
        <div className={selectedMK?.drugCharacteristic || showImgFallback ? "p-2" : ""}>
          <MedicationKnowledgeImage
            drugCharacteristic={selectedMK?.drugCharacteristic}
            className="w-96 float-left mr-3"
            showAll
            showFallback={showImgFallback}
          />
          <p>{selectedMK?.text?.div}</p>
        </div>

        {selectedMK?.administrationGuidelines && (
          <div className="my-2">
            <h3 className="uppercase font-medium">Directions:</h3>
            <div className="flex flex-col gap-2 divide-y text-sm">
              {selectedMK?.administrationGuidelines.map(({ dosage }, indexAG) => (
                <div key={`${indexAG}`} className="grid grid-cols-2 gap-2 pt-2 first:pt-0 text-gray-500">
                  {dosage?.map(({ dosage }, indexDT) =>
                    dosage.map((dosage, indexD) => <p key={`${indexAG}${indexDT}${indexD}`}>{dosage.text}</p>),
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedMK?.ingredient && (
          <div className="my-2">
            <h1 className="uppercase font-bold">Ingredients:</h1>
            <div className="grid grid-flow-row-dense grid-cols-2 gap-y-2 gap-x-6 text-gray-500">
              {selectedMK.ingredient.map(({ item, strength }, index) => (
                <span key={index}>
                  {item?.CodeableConcept?.text}:{" "}
                  {!/\d/.test(strength?.numerator?.unit ?? "") && strength?.numerator?.value}{" "}
                  {strength?.numerator?.unit}
                </span>
              ))}
            </div>
          </div>
        )}
        {selectedMK?.contraindication && (
          <div className="my-2">
            <h1 className="uppercase font-medium">Contraindications:</h1>
            <MedicationKnowledgeContraindications contraindications={selectedMK?.contraindication} />
          </div>
        )}
        {selectedMK?.monograph && (
          <div className="my-2">
            <h1 className="uppercase font-medium">Monograph</h1>
            {isLoading ? (
              <span className="text-slate-500 text-sm">
                <FontAwesomeIcon icon={faSpinner} spin />
                <span className="ml-1">loading monograph.</span>
              </span>
            ) : monograph ? (
              <span className="text-blue-500 cursor-pointer hover:underline text-sm">
                <FontAwesomeIcon icon={faLink} className="mr-1" />
                <span onClick={() => handleShowMonographPDF(monograph.content[0].attachment.url)}>
                  {monograph.content[0].attachment.title}
                </span>
              </span>
            ) : null}
          </div>
        )}
        {extraContent}
      </div>
    </Dialog>
  )
}

type Props = {
  selectedMK?: MedicationKnowledge
  showImgFallback?: boolean
  onHide(): void
  mr?: MedicationRequest
}

export { MedicationKnowledgeDetails }
