import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { faEye } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDate, isValid, parseISO } from "date-fns"
import { codeableConceptAsString, Medication } from "fhir"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { useState } from "react"

import { ModalDialog } from "commons"
import { ConfigurationItem } from "commons/procedures"
import { formatsByTypes } from "data"
import { SYSTEM_VALUES } from "system-values"

const MedicationItem = ({ item, title, isMassage, isBotox, expanded, onClick }: Props) => {
  const [showDosages, setShowDosages] = useState(false)
  const expDate = parseISO((item.medicationAdministration?.contained?.[0] as Medication)?.batch?.expirationDate ?? "")
  const bodySites = item?.bodySite?.coding?.[0]?.code?.split("|") ?? []
  const dosages =
    item?.bodySite?.coding
      ?.find(({ system }) => system === SYSTEM_VALUES.PROCEDURE_CONFIGURATION_DOSAGE)
      ?.code?.split("|") ?? []
  return (
    <div
      className={classNames(
        "flex flex-col p-4 rounded-md border transition-all ease-in-out duration-200",
        expanded ? "cursor-default border-gray-700" : "cursor-pointer border-gray-300",
      )}
      onClick={onClick}
    >
      <div className="flex flex-row justify-between items-baseline gap-1">
        <span className="font-medium text-gray-900">{title}</span>
        {!(onClick === undefined && expanded) && (
          <FontAwesomeIcon className="pl-3 mt-2" icon={expanded ? faChevronUp : faChevronDown} />
        )}
      </div>
      <div className={classNames("flex flex-col p-2 gap-2", { hidden: !expanded })}>
        {!isMassage ? (
          <>
            <div className="flex gap-2">
              <span className="text-gray-900 font-medium">Batch:</span>
              <span className="text-gray-700">
                {(item.medicationAdministration?.contained?.[0] as Medication)?.batch?.lotNumber}
              </span>
            </div>
            {isValid(expDate) && (
              <div className="flex gap-2">
                <span className="text-gray-900 font-medium">Expiration date:</span>
                <span className="text-gray-700">{formatDate(expDate, formatsByTypes.LONG_DATE)}</span>
              </div>
            )}
            <div className="flex gap-2">
              <span className="text-gray-900 font-medium">Quantity:</span>
              <span className="text-gray-700">{item.medicationRequest?.dispenseRequest?.quantity?.value}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-gray-900 font-medium">Administration site:</span>
              <span
                className={classNames("text-gray-700", { "cursor-pointer": isBotox })}
                onClick={isBotox ? () => setShowDosages(true) : undefined}
              >
                {codeableConceptAsString(item.bodySite)}
              </span>
              {isBotox && (
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faEye}
                  title="Show Dosage"
                  onClick={() => setShowDosages(true)}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-2">
              <span className="text-gray-900 font-medium">Zone:</span>
              <span className="text-gray-700">{item.zone?.display}</span>
            </div>
            <div className="flex gap-2">
              <span className="text-gray-700">{`${bodySites.length} selected ${pluralize("point", bodySites.length)}`}</span>
            </div>
          </>
        )}
      </div>
      <ModalDialog
        title={title}
        visible={showDosages}
        draggable
        showCancel
        cancelLabel="Close"
        onCancel={() => setShowDosages(false)}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 grow p-1">
          {dosages.map((dose, index) => (
            <div key={`${dose}_${index}`} className="p-fluid inline-flex flex-1 space-x-3 min-w-fit items-center">
              <span className="text-gray-900 font-medium">Point {index + 1}:</span>
              <span className="text-gray-700">{dose}</span>
            </div>
          ))}
        </div>
      </ModalDialog>
    </div>
  )
}

type Props = {
  item: ConfigurationItem
  title: string
  isMassage: boolean
  isBotox: boolean
  expanded: boolean
  onClick?(): void
}

export { MedicationItem }
