import { faCalendar, faSyringe, faUserDoctor } from "@fortawesome/pro-regular-svg-icons"
import { parseISO } from "date-fns/parseISO"
import { codeableConceptAsString, isCarePlan } from "fhir"

import { StackedListItemProps } from "commons"
import { ProcedureData } from "commons/procedures"
import { formatsByTypes } from "data"
import { formatDate, getBadgeColor } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

const proceduresItemModel = (
  procedureData: ProcedureData,
  onShowDetails?: () => void,
  onEdit?: () => void,
  onDelete?: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Type", value: codeableConceptAsString(procedureData.procedure.code) }],
    },
    {
      lineItems: [
        ...(procedureData.procedure.performed?.dateTime
          ? [
              {
                name: "Performed",
                value: formatDate(parseISO(procedureData.procedure.performed?.dateTime), formatsByTypes.LONG_DATE),
                icon: faCalendar,
              },
            ]
          : []),
        { name: "Performer", value: procedureData.performerName, icon: faUserDoctor },
        { name: "Medications", value: `${procedureData.configurationItem.length}`, icon: faSyringe },
      ],
    },
  ],
  badge: getBadgeColor(procedureData.procedure.status),
  onClick: onShowDetails,
  menu:
    procedureData.procedure.status === "preparation"
      ? defaultEditRemoveMenu(onEdit, !procedureData.procedure.basedOn?.some(isCarePlan) ? onDelete : undefined)
      : [],
})

export { proceduresItemModel }
