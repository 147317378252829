import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle } from "fhir"

import { datadogLogs, registerErrorTrace } from "logger"
import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"

import { patient360QueryKeys } from "../query-keys"

const useTransferPatientInfo = (onSuccess?: () => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const transfer = async (bundle: Bundle) => {
    return transaction(bundle)
  }

  const { mutate: transferPatient, isPending } = useMutation({
    mutationFn: transfer,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: patient360QueryKeys.observations.all })
      displayNotificationSuccess("Patient data has been imported successfully!")
      datadogLogs.logger.info("Patient data has been imported successfully!", { data })
      onSuccess?.()
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return {
    transferPatient,
    isTransfering: isPending,
  }
}

export { useTransferPatientInfo }
