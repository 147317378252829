import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useRescheduleMrOrder = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const rescheduleOrder = async ({ id, newDate }: { id: string; newDate: Date }) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "date",
          value: {
            date: newDate.toISOString(),
          },
        },
      ],
    }

    return operationRequest<Parameters>({
      endpoint: "ServiceRequest",
      method: "POST",
      operation: "reschedule",
      id,
      parameters,
    })
  }

  const { mutate: rescheduleMrOrder, isPending } = useMutation({
    mutationFn: rescheduleOrder,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: medsQueryKeys.orderDetails.details(id) })
      displayNotificationSuccess("Order rescheduled successfully!")
      datadogLogs.logger.info(`Order ${id} rescheduled successfully!`, { id })
    },
    onSettled: onSettled,
  })

  return { rescheduleMrOrder, isRescheduling: isPending }
}

export { useRescheduleMrOrder }
