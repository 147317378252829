import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { Money, ServiceRequest } from "fhir"
import { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { Accordion, StackedListItem } from "commons"
import { labOrderItemModelBuilder } from "commons/labs"
import { BILLING_TYPES_CODES, laboratoryOrderStatusCodes } from "data"
import { useLaboratoryOrders } from "laboratory-orders/hooks"

import { WrapUpSection } from "./WrapUpSection"

const LabsSection: FC<Props> = ({ patientId, organizationId, isExemptLabPayment, encounterId, icon }) => {
  const [searchParams, setParams] = useSearchParams()
  const orderStatus = laboratoryOrderStatusCodes.map(({ code }) => code)

  const { laboratoryOrders, isLoading, total } = useLaboratoryOrders({
    organizationId,
    patientId,
    statusFilter: orderStatus,
    enabled: true,
    encounter: encounterId,
  })

  const goToOrderDetails = (orderId: string) => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: "labs",
      order: orderId,
    })
    setParams(params)
  }

  const renderDetails = ({
    panels,
    showPrice,
  }: {
    panels: { profile: ServiceRequest; price?: Money }[]
    showPrice: boolean
  }) => (
    <>
      {panels.map((panel) => (
        <div
          className="py-1 border-b last:border-none text-sm flex items-center justify-between"
          key={panel.profile.id}
        >
          <span> {panel.profile.code?.coding?.[0].display}</span>
          {showPrice && (
            <span className="ml-4">
              {panel.price ? (
                `${panel.price?.value?.toFixed(2)} ${panel.price?.currency}`
              ) : (
                <small className="text-gray-400">No price</small>
              )}
            </span>
          )}
        </div>
      ))}
    </>
  )

  return (
    <WrapUpSection
      sectionTitle="Lab orders"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No orders placed during this encounter"
      className="w-10/12 self-end -mt-2 pb-2 z-50 divide-y"
    >
      <Accordion
        data={laboratoryOrders ?? []}
        headerTemplate={(labData) => (
          <StackedListItem
            className="py-4 pr-7"
            modelData={labOrderItemModelBuilder({
              data: { ...labData, order: { ...labData.order, occurrence: undefined }, billingType: "" },
              goToOrderDetails,
              showPrice: !isExemptLabPayment && labData.billingType !== BILLING_TYPES_CODES.INSURANCE,
              allowOnlyExternalAction: true,
            })}
          />
        )}
        headerClassName="paddingless @container"
        expandIcon="pi pi-plus text-sm right-0"
        collapseIcon="pi pi-minus text-sm right-0"
        contentTemplate={({ panels, combo, billingType }) =>
          renderDetails({
            panels: [...panels, ...(combo ? [{ profile: combo.laboratoryCombo, price: combo?.price }] : [])],
            showPrice: !isExemptLabPayment && billingType !== BILLING_TYPES_CODES.INSURANCE,
          })
        }
      />
    </WrapUpSection>
  )
}

type Props = {
  encounterId: string
  patientId: string
  organizationId: string
  isExemptLabPayment?: boolean
  icon: IconDefinition
}

export { LabsSection }
