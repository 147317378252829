import { ErrorMessage, Field, FieldProps } from "formik"
import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { AnyObject } from "yup/lib/object"

const NumberField: FC<Props> = ({
  field,
  label,
  className,
  min,
  max,
  minFractionDigits,
  maxFractionDigits,
  placeholder,
  prefix,
  suffix,
  showButtons = true,
  mode = "decimal",
  floatLabel,
  horizontal,
  validation,
  disabled,
  inputClassName,
  labelClassName,
  onChange: onValueChage,
  subMessage,
}) => (
  <Field name={field} validate={validation}>
    {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
      <div
        className={classNames(
          "field space-y-2 relative",
          horizontal && "inline-flex justify-between",
          floatLabel && "float-label relative block mt-1",
          !horizontal && !floatLabel && "flex flex-col",
          className,
        )}
      >
        {label && !floatLabel && (
          <label
            htmlFor={name}
            className={classNames(
              "text-sm font-medium text-gray-700",
              { "mr-3 mb-0 mt-2": horizontal },
              labelClassName,
            )}
          >
            {label}
          </label>
        )}
        <InputNumber
          inputId={name}
          name={name}
          value={value}
          onValueChange={onChange}
          onChange={(e) => {
            setFieldValue(name, e.value)
            onValueChage?.(e.value)
          }}
          mode={mode}
          currency={mode === "currency" ? "USD" : undefined}
          placeholder={placeholder}
          showButtons={showButtons}
          min={min}
          max={max}
          maxFractionDigits={maxFractionDigits}
          minFractionDigits={minFractionDigits}
          prefix={prefix}
          suffix={suffix}
          useGrouping={false}
          disabled={disabled}
          inputClassName={inputClassName}
          className={classNames("flex-1 p-inputtext-sm", {
            "p-invalid": touched && error,
            horizontal: horizontal,
          })}
        />
        {label && floatLabel && (
          <label
            htmlFor={name}
            className={classNames(
              "text-sm text-gray-400 left-3 top-2 absolute transition-all ease-in-out",
              labelClassName,
            )}
          >
            {label}
          </label>
        )}
        {subMessage && <span className="text-gray-700 text-xs">&#42;{subMessage}</span>}
        <div className="flex items-start p-error mt-1">
          {touched && error && <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>}
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  label?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  min?: number
  max?: number
  minFractionDigits?: number
  maxFractionDigits?: number
  showButtons?: boolean
  placeholder?: string
  mode?: "decimal" | "currency"
  prefix?: string
  suffix?: string
  horizontal?: boolean
  floatLabel?: boolean
  disabled?: boolean
  subMessage?: string
  validation?(value: string): void
  onChange?(value: AnyObject | number | null | undefined): void
}

export { NumberField }
