import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { Children, ReactElement, useState } from "react"

const MasterDetailSection = ({ selectedSectionId, onSectionChanged, children }: Props) => {
  const getChildId = (child: ReactElement<ItemProps>) => child?.props?.id ?? ""

  const [currentSection, setCurrentSection] = useState<string>(
    getChildId(children.find((child) => !!child.props.id) as ReactElement<ItemProps>),
  )
  const sectionId = selectedSectionId ?? currentSection
  const onChanged = onSectionChanged ?? setCurrentSection

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="w-72 xl:w-96 pl-8 pt-8 border-r overflow-y-auto">
        <ul>
          {Children.map(children, (child) =>
            child.props.title ? (
              <li
                className={classNames(
                  "p-3 text-gray-500 cursor-pointer border-l-2 hover:text-primary hover:bg-gray-100",
                  getChildId(child) === sectionId ? "text-primary border-primary bg-gray-50" : " border-transparent",
                )}
                onClick={() => onChanged(getChildId(child))}
              >
                {child.props.icon && <FontAwesomeIcon icon={child.props.icon} className="mr-2" />}
                {child.props.title}
              </li>
            ) : null,
          )}
        </ul>
      </div>
      <div className="flex flex-col flex-1 pl-8 pt-8 pr-3 overflow-hidden">
        {Children.map(children, (child) =>
          child.props.title ? (
            getChildId(child) === sectionId ? (
              <div className="flex flex-col flex-1 overflow-hidden">
                <div className={classNames("text-lg", child.props.description ? "pb-1" : "pb-4")}>
                  {child.props.title}
                </div>
                {child.props.description && <div className="text-sm pb-4 text-gray-400">{child.props.description}</div>}
                {child}
              </div>
            ) : null
          ) : (
            child
          ),
        )}
      </div>
    </div>
  )
}

const SectionItem = ({ children, className }: ItemProps) => (
  <div className={classNames("flex flex-1", className)}>{children}</div>
)

interface Props {
  selectedSectionId?: string
  onSectionChanged?(section: string): void
  sectionToUrlParam?: string
  children: ReactElement<ItemProps>[]
}

interface ItemProps {
  id?: string
  icon?: IconDefinition
  title: string
  description?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
}

export { MasterDetailSection, SectionItem }
