import { CodeableConcept, PlanDefinitionActionArrayActionArray, Reference } from "fhir"
import { useFormikContext } from "formik"
import { FC, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"

import { ReferenceDropdownField } from "../../../forms"
import { ICD10CodesField } from "../../../forms/ICD10Codes"
import { BillingField, BloodDrawField } from "../../../forms/labFields"
import { useLoadProductsPrice, useStepFormContext } from "../../../hooks"
import { getRestrictedLabPerformer, useAvailableLabs, useLaboratoryLocations } from "../../../labs"
import { PractitionerInfo } from "../../../types"
import { PLAN_ACTION_CODES, PlanContext, PlanData } from "../../types"
import { ComboSelection } from "./ComboSelection"
import { ExtraPanelField } from "./ExtraPanelField"

const LabsFormSection: FC<Props> = ({ configureActions, practitionersInfo }) => {
  const [params] = useSearchParams()
  const {
    laboratoryLocation,
    currentOrganizationId,
    isExemptLabPayment,
    performerLabsEnabled,
    organizationAnIdentifier,
  } = useOrganizationContext()
  const {
    values: { billingType, combos, performer, performers, combo, requester },
    setFieldTouched,
    setFieldValue,
    registerField,
  } = useFormikContext<PlanData>()
  const { toogleOptionalStep } = useStepFormContext()

  const labAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_LABS]

  const { requiredLabOder } = useMemo(() => {
    const subActionsRequiredBehavior = labAction.action?.reduce(
      (acc, act) => {
        const code = act.code?.[0]?.coding?.[0]?.code
        const required = act.requiredBehavior === "must"
        return { ...acc, [code as string]: required }
      },
      {} as Record<string, boolean>,
    )

    return { requiredLabOder: labAction?.requiredBehavior === "must", subActionsRequiredBehavior }
  }, [labAction])

  useEffect(() => {
    const validateRefs = (value?: Reference, message: string = "This field is required") => (!value?.id ? message : "")
    if (requiredLabOder) {
      registerField("performer", { validate: (v) => validateRefs(v, "Lab is required") })
      registerField("panels", { validate: (v) => (!v?.length && !combo ? "At least one panel is required" : "") })
    }
    toogleOptionalStep?.({ stepId: PLAN_ACTION_CODES.CONFIGURE_LABS, optional: !requiredLabOder })
  }, [requiredLabOder, combo])

  const { availableLabs } = useAvailableLabs(
    practitionersInfo,
    performerLabsEnabled.filter(({ ref }) => performers?.some(({ id }) => id === ref.id)),
    requester,
    organizationAnIdentifier,
  )

  const { mapProductsPrice, isPending } = useLoadProductsPrice(
    currentOrganizationId,
    billingType ?? BILLING_TYPES_CODES.BILL_PATIENT,
    "combo",
    (data) => setFieldValue("combos", data),
  )

  const { updateLabLocation } = useLaboratoryLocations(laboratoryLocation, (locationRefs) =>
    setFieldValue("order.locationReference", locationRefs),
  )

  useEffect(() => {
    setFieldTouched("order.reasonCode", true)
    if (!isExemptLabPayment) mapProductsPrice({ products: combos })
  }, [billingType, setFieldTouched])

  const isMCView = useMemo(() => params.get("view") === PlanContext.MC, [params])

  return (
    <>
      <ReferenceDropdownField
        field="performer"
        label="Lab"
        options={availableLabs?.map(({ ref }) => ref)}
        showFilter={false}
        handleChange={(newPerRef) => updateLabLocation(newPerRef?.id)}
        assignDefaultValue={requiredLabOder && !isMCView}
        horizontal
        className="w-full"
        isClearable={!requiredLabOder}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60"
      />
      <BloodDrawField
        horizontal
        className="w-full"
        checkboxClassName="pl-32 @lg:pl-48 @xl:pl-52 @2xl:pl-60 border-t-0 border-none inset-field"
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium"
      />
      <BillingField
        insuranceField="order.insurance[0]"
        horizontal
        className="w-full"
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium"
      />
      <ComboSelection
        horizontal
        className="w-full"
        combos={combos}
        isLoadingPrices={isPending}
        isExemptLabPayment={isExemptLabPayment}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium"
      />
      <ExtraPanelField
        horizontal
        plan={{ combos, performer }}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium py-4"
      />
      <ICD10CodesField
        field="order.reasonCode"
        label="ICD-10 Codes"
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium"
        showSuggestedPatientConditions
        containerClassName="px-3"
        orgSuggestedConditions="defaultProcedureIcd10"
        horizontal
        validate={(value?: CodeableConcept[]) => {
          const restrictedLabPerformer = getRestrictedLabPerformer(performer?.id as string)
          return (
            (billingType === BILLING_TYPES_CODES.INSURANCE && !value?.length
              ? "Specify one condition at least"
              : undefined) ||
            (restrictedLabPerformer && performer
              ? value?.length && value?.length > restrictedLabPerformer.maxAmount
                ? `You have surpassed the maximum of allowed ICD-10 codes selectable with ${performer.display} (${restrictedLabPerformer.maxAmount})`
                : undefined
              : undefined)
          )
        }}
      />
    </>
  )
}

type Props = {
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  practitionersInfo: PractitionerInfo[]
}

export { LabsFormSection }
