import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Account, ChargeItemDefinition, Coverage, RequestGroup, ResourceObject } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { ordersQueryKeys } from "../query-keys"
import { ActionGroupCode } from "../types"
import { buildAction } from "../utils"

const useUpdateCpoeCids = (onSuccess?: () => void, onSettled?: () => void) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedRequestGroup = async ({
    shippingMethod,
    discounts,
    requestGroup,
    readerAccount,
    coverageByType,
  }: {
    shippingMethod?: ChargeItemDefinition[]
    discounts?: ChargeItemDefinition[]
    requestGroup: RequestGroup
    readerAccount?: Account
    coverageByType?: Record<ActionGroupCode, Coverage | undefined>
  }) => {
    const cidAction = shippingMethod?.map((cid) => buildAction(cid, "add-fee")) ?? []

    const contained = [] as ResourceObject[]

    if (shippingMethod) {
      contained.push(...shippingMethod)
    }

    if (coverageByType) {
      Object.entries(coverageByType).forEach(([type, coverage]) => {
        if (coverage) {
          cidAction.push(buildAction(coverage, `billing-type-${type}`))
          contained.push(coverage)
        }
      })
    }

    if (discounts) {
      const discountAct = discounts.map((discount) => buildAction(discount, "add-discount"))
      cidAction.push(...discountAct)
      contained.push(...discounts)
    }

    if (readerAccount) {
      cidAction.push(buildAction(readerAccount, "use-reader"))
      contained.push(readerAccount)
    }

    const newAction = { ...requestGroup?.action?.[1], action: cidAction }

    return patch(
      "RequestGroup",
      requestGroup.id as string,
      {
        contained,
        action: [requestGroup?.action?.[0], newAction, requestGroup?.action?.[2]],
        meta: requestGroup.meta,
      } as ResourceObject,
    )
  }
  const { mutate: updateCpoeCidsAD, isPending: isUpdatingCids } = useMutation({
    mutationFn: updatedRequestGroup,
    onSuccess: (_, { requestGroup }) => {
      queryClient.refetchQueries({
        queryKey: ordersQueryKeys.list(requestGroup.subject?.id),
      })
      onSuccess?.()
    },
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey: ordersQueryKeys.list(context?.requestGroup?.subject?.id) })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSettled,
  })

  return { updateCpoeCidsAD, isUpdatingCids }
}

export { useUpdateCpoeCids }
