import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address, getAddress } from "fhir"
import { Form, Formik, FormikProps, useFormikContext } from "formik"
import { Dialog } from "primereact/dialog"
import { Message } from "primereact/message"
import { Tooltip } from "primereact/tooltip"
import { FC, useRef, useState } from "react"

import { AddFieldArrayItemButton, AddressField, Button, SkeletonLoader } from "commons"
import { emptyAddress } from "data"
import { useUpdatePatientShippingAddress } from "patients"
import { getAddressSchema, getHomeAddress, isPoBoxAddress } from "utils"

import { useCPOERequestsContext } from "../hooks"

const OrdersShippingAddress: FC<Props> = ({
  nutrasShippingAddress,
  labsShippingAddress,
  showMedsAddress,
  showLabsAddress,
  isLoading,
  className,
}) => {
  const formikRef = useRef<FormikProps<Address>>(null)
  const { setIsProcessingActions, patient, addressErrorMessage, isProcessingActions } = useCPOERequestsContext()

  const defaultAddress = getHomeAddress(patient?.address)
  const [initialValues, setInitialValues] = useState<Address | null>(null)

  const handleMedsButtonClick = () => {
    setInitialValues({ ...emptyAddress, ...(nutrasShippingAddress ?? defaultAddress), type: "postal", country: "US" })
  }

  const handleLabsButtonClick = () => {
    setInitialValues({ ...emptyAddress, ...(labsShippingAddress ?? defaultAddress), type: "physical", country: "US" })
  }

  const hideDialog = () => {
    setInitialValues(null)
  }

  const { updateShippingAddress, isUpdating: isUpdatingShippingAddress } = useUpdatePatientShippingAddress(
    hideDialog,
    (_, error) => {
      setIsProcessingActions(false)
      if (error) formikRef?.current?.setSubmitting(false)
    },
  )

  const onSubmitAddress = (address: Address) => {
    updateShippingAddress({ address, patient: patient })
    setIsProcessingActions(true)
  }

  const showMultipleAddress = showLabsAddress && showMedsAddress

  if (isLoading) return <SkeletonLoader repeats={4} loaderType="one-line" />
  return (
    <>
      <div className={className}>
        {showMultipleAddress && (
          <h4 className="text-sm font-medium inline-flex justify-between w-full items-center mb-3">
            Address <hr className="flex-1 border ml-3" />
          </h4>
        )}
        {showMedsAddress && (
          <ShippingAddressItem
            emptyMessage="Add new address"
            address={nutrasShippingAddress}
            isDisabled={isUpdatingShippingAddress || isProcessingActions}
            onButtonClick={handleMedsButtonClick}
            label={showMultipleAddress ? "For Nutraceuticals" : "Address"}
          />
        )}
        {showLabsAddress && (
          <ShippingAddressItem
            emptyMessage="Add new address"
            address={labsShippingAddress}
            isDisabled={isUpdatingShippingAddress || isProcessingActions}
            onButtonClick={handleLabsButtonClick}
            label={showMultipleAddress ? "For Laboratories" : "Address"}
          />
        )}
      </div>
      {!!addressErrorMessage && (
        <Message
          severity="error"
          className="my-3 w-full px-3 py-1.5 text-sm"
          pt={{ text: { className: "text-sm" } }}
          text={addressErrorMessage}
        />
      )}
      <Dialog
        className="shipping-address-form min-w-[500px] w-[40vw] paddingless"
        visible={!!initialValues}
        onHide={() => {
          hideDialog()
        }}
        header={`${!initialValues?.line?.[0] ? "Add" : "Edit"} ${
          initialValues?.type === "physical" ? "physical" : "shipping"
        } address`}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues ?? {}}
          onSubmit={onSubmitAddress}
          validationSchema={getAddressSchema()}
        >
          <AddressForm onCancel={hideDialog} />
        </Formik>
      </Dialog>
    </>
  )
}

const AddressForm = ({ onCancel }: { onCancel: () => void }) => {
  /* const { recommendations, setRecommendations } = useAddressContext() */
  const { isSubmitting } = useFormikContext()

  return (
    <Form autoComplete="off" aria-autocomplete="none" className="w-full px-5">
      <div className="flex flex-col justify-between divide-y divide-gray-300">
        <div className="relative p-fluid grid gap-4">
          <fieldset className="relative p-fluid grid grid-cols-2 gap-4">
            <AddressField showTypeUseField={false} />
          </fieldset>
        </div>
        {/* {recommendations &&
          (recommendations.missingTypes.length > 0 ||
            recommendations.fixableTypes.length > 0 ||
            recommendations.wrongTypes.length > 0) && (
            <div className="relative w-full flex flex-col justify-between mt-3 p-3">
              <h3 className="text-sm font-semibold">Recommendations:</h3>
              {recommendations.missingTypes.length > 0 && (
                <p className="text-gray-600">
                  - Missing {pluralize("part", recommendations.missingTypes.length)} in address:
                  <span className="text-red-400"> {recommendations.missingTypes.join(" , ")}</span>
                </p>
              )}
              {recommendations.wrongTypes.length > 0 && (
                <p className="text-gray-600">
                  - Wrong {pluralize("value", recommendations.wrongTypes.length)} provided in:
                  <span className="text-red-400"> {recommendations.wrongTypes.join(" , ")}</span>
                </p>
              )}
              {recommendations.fixableTypes.length > 0 && (
                <>
                  <p className="text-gray-600">
                    - Possible {pluralize("fix", recommendations?.fixableTypes.length)} in address:
                  </p>
                  {recommendations.fixableTypes?.map(({ componentType, value }) => (
                    <p key={componentType} className="text-gray-600 ml-4">
                      - {componentType} could be: <span className="text-gray-600 font-semibold">{value}</span>
                    </p>
                  ))}
                </>
              )}
            </div>
          )} */}
      </div>

      <div className="flex w-full justify-end mt-4 border-t-2 border-slate-200 py-4 lg:pt-4 bg-white">
        <Button
          label="Close"
          type="button"
          buttonStyle="default"
          disabled={isSubmitting}
          onClick={() => {
            //setRecommendations(undefined)
            onCancel()
          }}
          className="mr-3"
        />
        <Button type="submit" label="Save" buttonStyle="primary" loading={isSubmitting} disabled={isSubmitting} />
      </div>
    </Form>
  )
}

const ShippingAddressItem = ({
  address,
  label,
  isDisabled,
  emptyMessage,
  isRequired = true,
  onButtonClick,
}: {
  label: string
  address?: Address
  isDisabled?: boolean
  emptyMessage: string
  isRequired?: boolean
  onButtonClick: () => void
}) => (
  <>
    <h4 className="font-medium text-sm inline-flex justify-between w-full items-center">
      {label} <hr className="flex-1 border ml-3" />
    </h4>

    {address ? (
      <div className="flex justify-between items-center pl-5 my-3">
        <p className="bg-white text-sm">
          {isPoBoxAddress(address) && (
            <>
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be suitable for receiving deliveries"
              />
              <span className="cursor-pointer tooltiped mr-4">
                <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
              </span>
            </>
          )}
          {getAddress([address])}
        </p>
        <Button
          icon={faEdit}
          className="p-button-text p-button-sm max-w-fit no-focusable"
          type="button"
          buttonStyle="text"
          onClick={onButtonClick}
          disabled={isDisabled}
          title="Edit"
        />
      </div>
    ) : (
      <>
        {isRequired && (
          <Message
            severity="error"
            className="w-full px-3 py-1.5 text-sm"
            pt={{ text: { className: "text-sm" } }}
            text="Please provide this required address"
          />
        )}
        <AddFieldArrayItemButton label={emptyMessage} onClick={onButtonClick} />
      </>
    )}
  </>
)

type Props = {
  nutrasShippingAddress?: Address
  labsShippingAddress?: Address
  showMedsAddress: boolean
  showLabsAddress: boolean
  isLoading?: boolean
  className?: string
}

export { OrdersShippingAddress }
