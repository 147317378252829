import { useId } from "react"
import { AllergyIntolerance } from "fhir"

import {
  ConfirmDialog,
  SkeletonLoader,
  InfiniteScroll,
  DataContainerSlideoverForm,
  StackedListContainer,
  useCrudReducer,
  ModulesId,
} from "commons"
import { usePatientContext } from "patients"
import { useAppModuleContext } from "internals"
import { useOpenEncounter } from "encounter"

import { useAllergies, useCreateAllergy, useUpdateAllergy, useDeleteAllergy } from "../hooks"
import { allergyValidationSchema, getInitialValues, sanitize } from "./validations"
import { AllergiesForm } from "./AllergiesForm"
import { allergyModelBuilder } from "./allergyModelBuilder"

const AllergiesContainer = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { initialValue, isNew, showSlide, deleteIndex, reset, add, edit, setDeleteIndex } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { allergies, isLoading, hasNextPage, fetchNextPage } = useAllergies(patientId)
  const { createAllergy } = useCreateAllergy(reset)
  const { updateAllergy } = useUpdateAllergy(reset)
  const { removeAllergy, isDeleting } = useDeleteAllergy(() => setDeleteIndex(undefined))

  const onSubmit = (allergy: AllergyIntolerance) => {
    isNew ? createAllergy(sanitize(allergy)) : updateAllergy(sanitize(allergy))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <DataContainerSlideoverForm
      hasData={!!allergies?.length}
      showSlide={showSlide}
      formTitle="Allergy"
      formInitialValue={initialValue}
      validationSchema={allergyValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<AllergiesForm isEditing={!isNew} />}
      onButtonAddClick={add}
      iconDataNotFound={appModules[ModulesId.ALLERGY].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={allergies ?? []}
            itemModelBuilder={(item) =>
              allergyModelBuilder(
                item,
                () => edit(item),
                () => setDeleteIndex(item.id),
              )
            }
          />
        </InfiniteScroll>
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to remove this allergy?`}
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() => removeAllergy(deleteIndex as string)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </DataContainerSlideoverForm>
  )
}

export { AllergiesContainer }
