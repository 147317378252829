import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues, useFormikContext } from "formik"
import { Stepper } from "primereact/stepper"
import { StepperPanel } from "primereact/stepperpanel"
import { classNames } from "primereact/utils"
import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { ObjectSchema } from "yup"

import { Button } from "../components/Buttons"
import { StepFormContext, useStepFormContext } from "../hooks"
import { FieldErrorType } from "../types"
import { getFieldError } from "../utils"
import "./StepFormContainer.css"

const StepFormContainer = <T extends FormikValues>({
  cancelButton,
  title,
  subtitle,
  showCloseIcon,
  customSaveButton,
  disableSave,
  saveLabel = "Save",
  initialValue,
  className,
  onSubmit,
  onClose,
  steps,
  footerClassName,
  validationSchema,
}: PropsWithChildren<Props<T>>) => {
  const [activeStep, setActiveStep] = useState(0)
  const [hiddenSteps, setHiddenSteps] = useState<string[]>()
  const [optionalSteps, setOptionalSteps] = useState<string[]>(
    steps.filter(({ optional }) => !!optional).flatMap(({ id }) => id),
  )

  const toogleHideStep = (stepId: string, hidden?: boolean) =>
    setHiddenSteps((steps) => {
      const stepIndex = steps?.findIndex((step) => step === stepId) ?? -1
      if (!hidden) return stepIndex >= 0 ? steps?.toSpliced(stepIndex, 1) : steps
      else return stepIndex >= 0 ? steps?.toSpliced(stepIndex, 1, stepId) : [...(steps ?? []), stepId]
    })

  const toogleOptionalStep = ({ stepId, optional }: { stepId: string; optional?: boolean }) =>
    setOptionalSteps((steps) => {
      const stepIndex = steps.findIndex((step) => step === stepId)

      if (optional) return stepIndex >= 0 ? steps.toSpliced(stepIndex, 1, stepId) : [...steps, stepId]
      else return stepIndex >= 0 ? steps.toSpliced(stepIndex, 1) : steps
    })

  const isHiddenStep = useCallback((stepId: string) => hiddenSteps?.includes(stepId) ?? false, [hiddenSteps])
  const isOptionalStep = useCallback((stepId: string) => optionalSteps?.includes(stepId) ?? false, [optionalSteps])
  const visibleSteps = useMemo(() => steps.filter(({ id }) => !isHiddenStep(id)), [steps, isHiddenStep])

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      {(title || subtitle) && (
        <div className="px-4 sm:px-6 py-6">
          {title && (
            <div className="inline-flex flex-1 justify-between">
              <div className="inline-flex space-x-3 font-semibold items-center">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="cursor-pointer hover:text-primary-hover"
                  onClick={onClose}
                />
                <h6 className="font-semibold leading-6">{title}</h6>
              </div>

              {showCloseIcon && (
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  title="Close"
                  className="hover:bg-primary-hover/10 rounded-full p-1 px-1.5 text-primary focus:outline-primary cursor-pointer"
                  onClick={onClose}
                />
              )}
            </div>
          )}
          {subtitle && <p className="text-gray-300 text-sm mt-1">{subtitle}</p>}
        </div>
      )}

      <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, submitForm, setValues }: FormikProps<T>) => (
          <div
            className={classNames("stepper-form divide-gray-200 flex flex-col h-full overflow-hidden", className)}
            aria-autocomplete="none"
          >
            <StepFormContext.Provider
              value={{
                hiddenSteps,
                activeStep,
                optionalSteps,
                setActiveStep,
                setHiddenSteps,
                isHiddenStep,
                isOptionalStep,
                setOptionalSteps,
                toogleHideStep,
                toogleOptionalStep,
              }}
            >
              <Stepper activeStep={activeStep} linear key={"stepper-form"}>
                {visibleSteps.map((step, index) => (
                  <StepperPanel header={`${step.header}${isOptionalStep(step.id) ? "(optional)" : ""}`} key={step.id}>
                    <StepItem
                      key={step.id}
                      step={step}
                      isLastStep={index + 1 === visibleSteps.length}
                      isFirstStep={index === 0}
                      goPrevStep={() => (index === 0 ? onClose?.() : setActiveStep((s) => (s > 0 ? --s : 0)))}
                      footerClassName={footerClassName}
                      onSubmit={(data) => {
                        if (index + 1 < visibleSteps.length) {
                          // Set partial values as part of full form value
                          setValues({ ...values, ...data })
                          setActiveStep(index + 1)
                        } else {
                          setValues({ ...values, ...data }).finally(submitForm)
                        }
                      }}
                      cancelButton={cancelButton}
                      customSaveButton={customSaveButton}
                      disableSave={disableSave}
                      saveLabel={saveLabel}
                    />
                  </StepperPanel>
                ))}
              </Stepper>
              <StepToError steps={visibleSteps} />
            </StepFormContext.Provider>
          </div>
        )}
      </Formik>
    </div>
  )
}

const StepItem = <T extends FormikValues>({
  step: {
    id,
    innerContainerClassName = "px-4 sm:px-6 py-6 space-y-6",
    valueKeys,
    className,
    enableReinitialize,
    validationSchema,
    children: formChildren,
  },
  isLastStep,
  isFirstStep,
  disableSave,
  saveLabel,
  footerClassName,
  cancelButton,
  customSaveButton,
  onSubmit,
  goPrevStep,
}: {
  step: Step<Partial<T>>
  isLastStep: boolean
  isFirstStep: boolean
  onSubmit(data: Partial<T>, formikHelpers?: FormikHelpers<Partial<T>>): void
  goPrevStep(): void
} & Pick<Props<T>, "cancelButton" | "customSaveButton" | "disableSave" | "footerClassName" | "saveLabel">) => {
  const { values } = useFormikContext<T>()
  const initialValue = Object.fromEntries(Object.entries(values).filter(([key]) => valueKeys.includes(key)))

  return (
    <Formik
      key={`formik-step-${id}-instance`}
      initialValues={initialValue as Partial<T>}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<Partial<T>>) => (
        <Form
          className={classNames("divide-gray-200 flex flex-col flex-1 overflow-hidden", className)}
          aria-autocomplete="none"
          autoComplete="off"
        >
          <div className="flex flex-col flex-1 overflow-y-auto">
            <div className={classNames("grow pb-5", innerContainerClassName)}>
              {typeof formChildren === "function" ? formChildren(formikProps) : formChildren}
            </div>
          </div>

          <div className={classNames("flex flex-shrink-0 border-t-2 justify-end gap-3 px-1 py-4", footerClassName)}>
            {cancelButton}
            <Button
              label={isFirstStep ? "Close" : "Previous"}
              buttonStyle="default"
              size="lg"
              disabled={formikProps.isSubmitting}
              onClick={goPrevStep}
            />
            {!!isLastStep && customSaveButton ? (
              typeof customSaveButton === "function" ? (
                customSaveButton({
                  validate: formikProps.validateForm,
                  isSubmitting: formikProps.isSubmitting,
                  values: formikProps.values as T,
                  setSubmitting: formikProps.setSubmitting,
                })
              ) : (
                customSaveButton
              )
            ) : (
              <Button
                label={isLastStep ? saveLabel : "Next"}
                size="lg"
                loading={formikProps.isSubmitting}
                disabled={formikProps.isSubmitting || disableSave}
                onClick={formikProps.submitForm}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StepToError = ({ steps }: { steps: { id: string; valueKeys: ValueKey<any>[] }[] }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext()
  const { setActiveStep } = useStepFormContext()

  useEffect(() => {
    if (isSubmitting && !isValidating && errors) {
      const fields = Object.entries(errors) as FieldErrorType[]

      if (fields.length > 0) {
        const fieldError = getFieldError(fields)
        const firstStepOnError = steps.findIndex(({ valueKeys }) =>
          valueKeys.some((key) => fieldError.includes(String(key))),
        )
        if (firstStepOnError !== -1) {
          setActiveStep?.(firstStepOnError)
        } else setActiveStep?.(0)
      }
    }
  }, [errors, isSubmitting, isValidating])
  return null
}

type Props<T> = {
  title?: string
  subtitle?: string
  disableSave?: boolean
  saveLabel?: string
  cancelButton?: ReactNode
  showCloseIcon?: boolean
  className?: string
  initialValue: T
  onSubmit(data: T, formikHelpers?: FormikHelpers<T>): void
  onClose?(): void
  steps: Step<Partial<T>>[]
  customSaveButton?:
    | ReactNode
    | (({
        setSubmitting,
        validate,
        isSubmitting,
        values,
      }: {
        validate: (values?: unknown) => Promise<FormikErrors<T>>
        isSubmitting?: boolean
        values: T
        setSubmitting: (isSubmitting: boolean) => void
      }) => ReactNode)
  footerClassName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
}

export type Step<T> = {
  id: string
  header: string
  valueKeys: ValueKey<T>[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
  enableReinitialize?: boolean
  children?: ((props: FormikProps<T>) => ReactNode) | ReactNode
  className?: string
  innerContainerClassName?: string
  optional?: boolean
}

type ValueKey<T> = keyof T

export { StepFormContainer }
