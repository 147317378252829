import { useQuery } from "@tanstack/react-query"
import { AuditEvent, getResources, getResourcesByTypeAsIndex, humanNameAsString, Patient, Practitioner } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { parseISO } from "date-fns"
import { medicationRequestsQueryKeys } from "../query-keys"
import { AgentData, RescheduleEventData } from "../types"

const useRescheduleAuditEvents = (organizationId: string, patientId: string, orderId: string) => {
  const { search } = useClient()
  const queryKey = medicationRequestsQueryKeys.auditEvents(organizationId, patientId, orderId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        patient: patientId,
        entity: `ServiceRequest/${orderId}`,
        _include: "agent",
        _sort: "-date",
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/AuditEvent`, filters, signal })

      const events = getResources<AuditEvent>(bundle, "AuditEvent")
      const patients = getResourcesByTypeAsIndex<Patient>(bundle, "Patient")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")

      return { events, patients, practitioners }
    },
    meta: { context: { queryKey, organizationId, patientId, orderId } },
  })

  const events = useMemo(
    () =>
      data?.events?.reduce<RescheduleEventData[]>((events, event) => {
        const participants = event.agent.reduce<AgentData[]>((agents, agent) => {
          let result: AgentData | undefined
          if (agent.who?.resourceType === "Patient") {
            const patient = data?.patients?.[agent.who?.id as string]
            result = { name: humanNameAsString(patient?.name?.[0]), type: "Patient", requestor: agent.requestor }
          } else if (agent.who?.resourceType === "Practitioner") {
            const practitioner = data?.practitioners?.[agent.who?.id as string]
            result = {
              name: humanNameAsString(practitioner?.name?.[0]),
              type: "Practitioner",
              requestor: agent.requestor,
            }
          } else if (agent.who?.type === "Client") {
            result = { name: agent.who.display as string, type: "Client", requestor: agent.requestor }
          } else if (!agent.who?.type && !agent.who?.resourceType) {
            result = { name: agent?.who?.display as string, type: "", requestor: agent.requestor }
          }

          if (result) {
            if (!result.name) result.name = agent.name ?? (agent.who?.display as string)
            return [...agents, result]
          }
          return agents
        }, [])

        const reschedule = event.entity?.find((e) => e.what?.id === orderId)?.detail
        const prevDate = reschedule?.find((d) => d.type === "previousDate")?.value?.string as string
        const newDate = reschedule?.find((d) => d.type === "newDate")?.value?.string as string

        return [
          ...events,
          {
            ...event,
            from: parseISO(prevDate),
            to: parseISO(newDate),
            participants: participants.sort((a) => (a.requestor ? -1 : 1)),
          },
        ]
      }, []) ?? [],
    [data],
  )

  return {
    auditEvents: events,
    isLoading,
  }
}

export { useRescheduleAuditEvents }
