import { faCalendarDays } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"

import { CardListItem } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { TrackingData } from "../types"

const TrackingCodeItem = ({ trackinCode, className }: Props) => {
  const head = (
    <>
      <div className="font-bold" title="Trackin code number">
        {`#${trackinCode.identifier}`}
      </div>
      <span title="Status">
        <Chip
          label={trackinCode.status.replace(/-/g, " ")}
          className={classNames("ml-2 custom-chip capitalize", {
            "is-danger": ["stopped", "on-hold", "cancelled"].includes(trackinCode.status),
            "is-warning": trackinCode.status === "entered-in-error",
            "is-success": trackinCode.status === "completed",
            "is-info": trackinCode.status === "in-progress",
          })}
        />
      </span>
    </>
  )

  const dates = [
    { date: trackinCode.datePrepared, title: "Given out date" },
    { date: trackinCode.dateShipped, title: "Shipped date" },
  ]

  const details = (
    <div className="grid gap-1">
      <div className="">
        {dates.map(({ date, title }, index) => (
          <span key={index} title={title}>
            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-2" />
            {date && formatDate(new Date(date), formatsByTypes.LONG_DATE)} {dates.length - 1 !== index && " | "}
          </span>
        ))}
      </div>
      <span title="Medications">{trackinCode.medications.join(", ")}</span>
    </div>
  )

  const googlingCode = () => {
    window.open(`https://www.google.com/search?q=${trackinCode.identifier}`, "_blank")
  }

  return (
    <CardListItem
      contentHeader={head}
      contentSubheader={details}
      className={classNames("cursor-pointer", className)}
      onContentCliked={googlingCode}
      lastElementClass="last:mb-0 last:border-b-0 border-b"
    />
  )
}

type Props = {
  trackinCode: TrackingData
  className?: string
}

export { TrackingCodeItem }
