import { faEye, faTag } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Consent } from "fhir"

import { MenuStyles, StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { formatDate, getBadgeColor } from "utils"

const consentModelBuilder = ({
  consent,
  download,
  isLoading,
  showAsExternalAction,
}: ConsentModelBuilderArgs): StackedListItemProps => {
  const createdAt = consent.meta?.createdAt && formatDate(new Date(consent.meta.createdAt), formatsByTypes.SHORT_DATE)
  const category = consent.category?.[0].coding?.[0].display ?? consent.category?.[0].coding?.[0].code ?? "No category"

  return {
    isLoading,
    leftData: [
      {
        lineItems: [{ name: "Date", value: createdAt ?? "Unspecified date" }],
      },
      {
        lineItems: [{ name: "Category", value: category, icon: faTag }],
      },
    ],
    badge: {
      ...getBadgeColor(consent.status),
    },
    menuStyle: showAsExternalAction ? MenuStyles.ExternalAction : undefined,
    menu: [
      {
        template: () => {
          return (
            <>
              <Tooltip target=".view-button" position="left" />
              <div
                className={classNames(
                  "flex px-3 py-2 view-button items-center gap-2 hover:bg-slate-200",
                  consent.status !== "active" ? "opacity-60" : "cursor-pointer",
                )}
                onClick={consent.status !== "active" ? undefined : download}
                data-pr-tooltip="Only signed consent can be shown"
                data-pr-disabled={consent.status === "active"}
                data-pr-showdelay={1000}
              >
                <FontAwesomeIcon icon={faEye} />
                <span>View</span>
              </div>
            </>
          )
        },
        disabled: consent.status !== "active",
      },
    ],
  }
}

interface ConsentModelBuilderArgs {
  consent: Consent
  download: () => void
  resend?: () => void
  isLoading?: boolean
  showAsExternalAction?: boolean
}

export { consentModelBuilder }
