import { RelatedPerson } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError, sanitizeAddress } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { coverageQueryKeys } from "../query-key"

const useCreateRelatedPerson = (onSuccess?: (r: RelatedPerson) => void, onSettled?: () => void) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const createFn = async (relatedPerson: RelatedPerson) =>
    relatedPerson.id
      ? update("RelatedPerson", relatedPerson.id, sanitizeAddress(relatedPerson) as RelatedPerson)
      : create("RelatedPerson", sanitizeAddress(relatedPerson) as RelatedPerson)

  const { mutate: createRelatedPerson, isPending: isAdding } = useMutation({
    mutationFn: createFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (relatedPerson, { id }) => {
      await queryClient.invalidateQueries({ queryKey: coverageQueryKeys.relatedPerson.all })
      onSuccess?.(relatedPerson)
      displayNotificationSuccess(id ? "Related person updated successfully!" : "Related person created successfully!")
      datadogLogs.logger.info(id ? "Related person updated successfully!" : "Related person created successfully!", {
        relatedPerson,
      })
    },
    onSettled,
  })

  return { createRelatedPerson, isAdding }
}

export { useCreateRelatedPerson }
