import { add } from "date-fns/add"
import {
  asReference,
  CodeableConcept,
  codeableConceptAsString,
  Dosage,
  Duration,
  MedicationKnowledge,
  MedicationKnowledgeAdministrationGuidelinesArray,
  MedicationRequestMedication,
  Patient,
  PractitionerRole,
  Quantity,
  Reference,
  Timing,
} from "fhir"

import { PractitionerInfo } from "commons/types"
import { ADMINISTRATION_GUIDELINE_DOSAGE_TYPE, mrCategoryCodes, unitOfTime } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getCommonCode, isRefrigeratedMedicationKnowledge, medicationKnowledgeRegulations } from "utils"

import { dispenseInterval, dosageTimingRepeats, DOSE_RANGE_REGEX, treatmentFrequencies } from "./data"
import { MedicationRequestInfo } from "./types"

const getMRInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  patient: Patient,
  loggedInPractitionerRole: PractitionerRole,
  practitionersInfo: PractitionerInfo[],
  catalogAuthor: Reference,
  encounter?: Reference | null,
  requesterPractitionerRole?: Reference,
): MedicationRequestInfo => {
  const mkDefaultDosages = medicationKnowledge.administrationGuidelines?.[0]?.dosage?.reduce((prev, dosageArray) => {
    return [...prev, ...dosageArray.dosage]
  }, [] as Dosage[])

  const defaultQuantity = {
    value: dispenseQuantity,
    code: medicationKnowledge.packaging?.type?.coding?.[0].code,
    unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
    system: SYSTEM_VALUES.MK_PACKAGE_TYPE,
  } as Quantity
  const currentDate = new Date().toISOString()

  const mrCategory = [{ coding: [mrCategoryCodes.nutraceutical], text: mrCategoryCodes.nutraceutical.display }]

  const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationKnowledge)
  const regulations = medicationKnowledgeRegulations(medicationKnowledge)

  if (isMKRefrigerated) {
    mrCategory.push({ coding: [mrCategoryCodes.refrigerated], text: mrCategoryCodes.refrigerated.display })
  }

  if (regulations?.length) {
    mrCategory.push(
      ...regulations.map((code) => ({
        coding: [code],
        text: code.display ?? code.code,
      })),
    )
  }

  const patientGpRole =
    requesterPractitionerRole ??
    practitionersInfo.find(
      ({ practitioner, practitionerRole }) =>
        practitioner.id === patient.generalPractitioner?.[0]?.id && !!practitionerRole,
    )?.practitionerRole

  const requester = patientGpRole ? asReference(patientGpRole) : undefined

  return {
    medication: { CodeableConcept: medicationKnowledge.code },
    status: "draft",
    intent: "order",
    category: mrCategory,
    authoredOn: currentDate,
    subject: asReference(patient),
    encounter: encounter ?? undefined,
    requester,
    recorder: asReference(loggedInPractitionerRole),
    performer: asReference(patient),
    dosageInstruction: mkDefaultDosages,
    dispenseRequest: {
      initialFill: {
        quantity: defaultQuantity,
        duration: dispenseInterval[1].value,
      },
      nextRefillDate: currentDate,
      numberOfRepeatsAllowed: 0,
      dispenseInterval: dispenseInterval[1].value,
      expectedSupplyDuration: dispenseInterval[1].value,
      quantity: defaultQuantity,
      performer: catalogAuthor,
    },
    doseQuantity: "1",
    medicationUnit: medicationKnowledge.amount?.unit,
    treatmentFrequency: treatmentFrequencies[0].value,
    unitsByRecipient: medicationKnowledge.amount?.value,
    resourceType: "MedicationRequest",
  }
}

const getMedCommonCode = ({
  medication,
  medicationKnowledge,
}: {
  medicationKnowledge?: MedicationKnowledge
  medication?: MedicationRequestMedication
}) => getCommonCode({ codes: medication?.CodeableConcept?.coding ?? medicationKnowledge?.code?.coding })

const getAdministrationGuideline = (
  med?: { code?: CodeableConcept },
  medRecommendedDosage?: Record<string, Dosage[]>,
) => {
  const medCode = getCommonCode({ codes: med?.code?.coding })
  const dosage = medRecommendedDosage?.[medCode]

  return dosage
    ? ({
        dosage: [{ dosage, type: ADMINISTRATION_GUIDELINE_DOSAGE_TYPE }],
      } as MedicationKnowledgeAdministrationGuidelinesArray)
    : undefined
}

const getMKDisplayText = (e?: MedicationKnowledge) => {
  const textCode = codeableConceptAsString(e?.code)
  const packagingText =
    !!e?.packaging?.type?.coding?.[0]?.display &&
    !!e?.packaging?.quantity?.value &&
    `${e?.packaging?.type?.coding?.[0]?.display}`
  const strength = e?.ingredient?.length === 1 && e?.ingredient[0]?.strength?.numerator?.unit
  const doseForm = e?.doseForm?.coding?.[0]?.display

  const displayArray = [textCode, strength, doseForm, packagingText].filter(Boolean)

  return `${displayArray.join(" - ")}`
}

const sanitizeMR = ({ ...medicationReq }: MedicationRequestInfo, mrFromMK?: boolean) => {
  const currentDate = new Date().toISOString()
  if (!medicationReq.authoredOn) medicationReq.authoredOn = currentDate
  if (!medicationReq.dispenseRequest?.nextRefillDate)
    medicationReq.dispenseRequest = { ...medicationReq.dispenseRequest, ...{ nextRefillDate: currentDate } }

  const quantity = medicationReq.dispenseRequest?.initialFill?.quantity as Quantity
  const duration = medicationReq.dispenseRequest?.initialFill?.duration as Duration
  const interval = medicationReq.dispenseRequest.dispenseInterval?.value

  const repeats = medicationReq.dispenseRequest?.numberOfRepeatsAllowed

  medicationReq.dispenseRequest = {
    ...medicationReq.dispenseRequest,
    quantity,
    ...(repeats !== undefined ? { numberOfRepeatsAllowed: repeats } : undefined),
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: medicationReq.dispenseRequest?.validityPeriod?.start ?? new Date().toISOString(),
      ...(!interval
        ? {
            end: add(
              medicationReq.dispenseRequest?.validityPeriod?.start
                ? new Date(medicationReq.dispenseRequest.validityPeriod.start)
                : new Date(),
              { [`${duration.unit ?? "second"}s`]: (duration.value ?? 0) * ((repeats ?? 0) + 1) },
            ).toISOString(),
          }
        : undefined),
    },
  }

  const frequency = getTimingFrequency(medicationReq.treatmentFrequency)

  if ((mrFromMK && !medicationReq.dosageInstruction?.length) || !mrFromMK) {
    medicationReq.dosageInstruction = [
      {
        timing: { ...frequency },
        text: `Take ${medicationReq.doseQuantity} ${medicationReq.medicationUnit?.toLowerCase()} ${treatmentFrequencies
          .find((f) => f.value === medicationReq.treatmentFrequency)
          ?.label?.toLowerCase()}`,
      },
      ...(medicationReq.dosageInstruction?.slice(1) ?? []),
    ]

    const matches = medicationReq.doseQuantity?.match(DOSE_RANGE_REGEX)
    if (matches?.length === 2) {
      medicationReq.dosageInstruction[0].doseAndRate = [
        {
          dose: {
            Range: {
              low: { value: Number.parseInt(matches[0].replace("-", "")), unit: medicationReq.medicationUnit },
              high: { value: Number.parseInt(matches[1]), unit: medicationReq.medicationUnit },
            },
          },
        },
      ]
    } else {
      medicationReq.dosageInstruction[0].doseAndRate = [
        {
          dose: {
            Quantity: { value: Number.parseInt(medicationReq.doseQuantity ?? "1"), unit: medicationReq.medicationUnit },
          },
        },
      ]
    }
  }

  if (!medicationReq.encounter) delete medicationReq.encounter
  if (!medicationReq.recorder) delete medicationReq.recorder
  if (!medicationReq.requester) delete medicationReq.requester
  if (!medicationReq.performer) delete medicationReq.performer
  if (!medicationReq.note?.[0].text) delete medicationReq.note

  delete medicationReq.unitsByRecipient
  delete medicationReq.medicationUnit
  delete medicationReq.treatmentFrequency
  delete medicationReq.doseQuantity

  return medicationReq
}

const getTimingFrequency = (frequencyKeyValue?: string) => {
  const selectedfrequency = dosageTimingRepeats.find((option) => option.id === frequencyKeyValue)

  const frequency = { code: selectedfrequency?.code, repeat: selectedfrequency?.repeat }

  const defaultTimingFreq = {
    code: { coding: [{ code: "QD", system: SYSTEM_VALUES.V3_GTSABB }] },
    repeat: { periodUnit: unitOfTime[3].code, period: 0, frequency: 0 },
  } as Timing

  return frequency ?? defaultTimingFreq
}

export { getAdministrationGuideline, getMedCommonCode, getMKDisplayText, getMRInitialValues, sanitizeMR }
