import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorMessage, Field, FieldProps } from "formik"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { FC, useState } from "react"

import { useCheckEmailExists } from "hooks"

const EmailField: FC<Props> = ({
  field,
  label,
  horizontal,
  floatLabel,
  className,
  inputClassName,
  disabled,
  autocomplete,
  initialValue,
  handleChange,
  validateWithBrowser = false,
  validateDuplicate = false,
  isPractitionerRole = false,
}) => {
  const { checkEmailExists, isCheckingEmail } = useCheckEmailExists()
  const [prevValue, setPrevValue] = useState<string>()

  const validateField = async (value: string) => {
    const { isValid, msg } = emailValidation(value)
    if (!isValid) return msg

    if (validateDuplicate && initialValue !== value) {
      const existsMail = await checkEmailExists({ emailToCheck: value, isPractitionerRole })
      if (existsMail) return "This email address is in use"
    }

    return undefined
  }

  return (
    <Field name={field} validate={validateField}>
      {({
        field: { name, value, onChange },
        meta: { touched, error },
        form: { isSubmitting, setFieldTouched },
      }: FieldProps) => (
        <div
          className={classNames(
            "field relative",
            horizontal && "inline-flex justify-between",
            floatLabel && "float-label relative block mt-1",
            !horizontal && !floatLabel && "flex flex-col",
            className,
          )}
        >
          {label && !floatLabel && (
            <label
              htmlFor={name}
              className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}
            >
              {label}
            </label>
          )}
          <InputText
            autoComplete={autocomplete}
            aria-autocomplete="none"
            type={validateWithBrowser ? "email" : "text"}
            id={name}
            name={name}
            onChange={(e) => handleChange?.(e.target.value) ?? onChange(e)}
            onFocus={() => setPrevValue(value)}
            onBlur={() => {
              if (validateDuplicate) {
                const { isValid } = emailValidation(value)

                if (isValid && prevValue !== value) {
                  setFieldTouched(field, true)
                  checkEmailExists({ emailToCheck: value, isPractitionerRole })
                }
              }
            }}
            value={value}
            disabled={disabled}
            className={classNames(
              "p-inputtext-sm",
              { "p-invalid": touched && error, horizontal: horizontal },
              inputClassName,
            )}
          />

          {label && floatLabel && (
            <label htmlFor={name} className="text-sm text-gray-400 left-3 top-2 absolute transition-all ease-in-out">
              {label}
            </label>
          )}

          <div className="flex items-start h-2 mt-1">
            <small>
              {error ? (
                <ErrorMessage name={field}>{(msg) => <span className="p-error mr-2">{msg}</span>}</ErrorMessage>
              ) : isCheckingEmail && (isSubmitting || prevValue !== value) ? (
                <span className="text-slate-500">
                  <FontAwesomeIcon icon={faSpinner} spin />
                  <span className="ml-1">checking availability.</span>
                </span>
              ) : null}
            </small>
          </div>
        </div>
      )}
    </Field>
  )
}

const emailValidation = (value: string) => {
  const check = { isValid: true, msg: "" }

  if (!value) {
    check.isValid = false
    check.msg = "Email address is required"
  } else {
    if (!/^([a-zA-Z0-9]([_]*|[.+][a-zA-Z0-9]+|[-]+[a-zA-Z0-9]+)*)+@[a-zA-Z0-9]+((\.|-){0,1}[a-zA-Z])*$/.test(value)) {
      check.isValid = false
      check.msg = "Invalid email address"
    } else if (/@evexias.com/.test(value)) {
      check.isValid = false
      check.msg = "This email cannot be used"
    }
  }

  return check
}

type Props = {
  field: string
  label?: string
  className?: string
  horizontal?: boolean
  floatLabel?: boolean
  inputClassName?: string
  validation?(value: string): void
  disabled?: boolean
  autocomplete?: string
  fieldName?: string
  handleChange?(email: string): void
  validateWithBrowser?: boolean
  validateDuplicate?: boolean
  validateRules?: boolean
  initialValue?: string
  isPractitionerRole?: boolean
}

export { EmailField }
