import { codeableConceptAsString, Dosage, MedicationKnowledge, MedicationRequest } from "fhir"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"

import { dispenseInterval } from "../data"
import { getMKDisplayText } from "../utils"

const MedicationRequestItem = ({
  medicationKnowledge,
  medicationRequest,
  showPackagingType,
  dosageInstructions,
  className,
  onClick,
  amount,
}: Props) => {
  const frecuency = dispenseInterval.find(
    (i) =>
      i.value.value !== 0 &&
      i.value.value === medicationRequest?.dispenseRequest?.dispenseInterval?.value &&
      i.value.unit === medicationRequest?.dispenseRequest?.dispenseInterval?.unit,
  )
  const refillsCount = medicationRequest?.dispenseRequest?.numberOfRepeatsAllowed ?? 0

  return (
    <>
      <div className={classNames("w-full flex flex-col gap-2", className)}>
        <div
          title={codeableConceptAsString(medicationKnowledge?.code)}
          className={classNames(
            "text-base font-bold text-ellipsis line-clamp-3 text-gray-600",
            onClick && "cursor-pointer",
          )}
          onClick={onClick}
        >
          {getMKDisplayText(medicationKnowledge)}
        </div>
        <div className="flex gap-4 items-baseline">
          {medicationKnowledge?.manufacturer?.id && (
            <div title="Manufacturer" className="font-semibold text-ellipsis text-gray-500">
              {medicationKnowledge.manufacturer.display}
            </div>
          )}
        </div>
        {dosageInstructions?.length && (
          <div title="Instructions" className="inline-flex text-ellipsis overflow-hidden max-w-sm">
            {dosageInstructions?.map((instruction, index) => <p key={instruction.id ?? index}>{instruction.text}</p>)}
          </div>
        )}
        <div className="flex flex-wrap gap-2">
          {showPackagingType && !!medicationKnowledge?.packaging?.quantity?.unit && (
            <div title="Schedule">
              {`${amount} ${pluralize(medicationKnowledge.packaging.quantity.unit?.replace(/{|}/g, "") ?? "unit", amount)}`}
              {frecuency && <span className="ml-1">{frecuency.label.toLocaleLowerCase()}</span>}
            </div>
          )}
          {!!refillsCount && (
            <div title="Refills" className="font-medium">{`${refillsCount} ${pluralize("refill", refillsCount)}`}</div>
          )}
        </div>
      </div>
    </>
  )
}

type Props = {
  medicationKnowledge: MedicationKnowledge
  medicationRequest?: MedicationRequest
  className?: string
  dosageInstructions?: Dosage[]
  showInstructions?: boolean
  showPackagingType?: boolean
  amount?: number
  onClick?(): void
}

export { MedicationRequestItem }
