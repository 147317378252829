import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { organizationQueryKeys } from "../query-keys"

const useOrganizationPaymentSetup = (onSettled?: () => void, sendEmail = false) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const paymentSetup = async (orgId: string) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "send-email",
          value: {
            boolean: sendEmail,
          },
        },
      ],
    }

    const result = await operationRequest<Parameters>({
      endpoint: "Organization",
      method: "POST",
      operation: "payment-setup",
      id: orgId,
      parameters,
    })
    const resp = result.parameter?.find((param) => param.name === "link")

    return resp?.value?.string
  }

  const {
    mutate: sendPaymentSetup,
    isPending: isSending,
    data,
  } = useMutation({
    mutationFn: paymentSetup,
    onSuccess: (link) => {
      displayNotificationSuccess(`The payment setup link has been created successfully.`)
      datadogLogs.logger.info(`The payment setup link has been created successfully.`, { link })
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.all })
      onSettled?.()
    },
    onError: (error: CustomError, patientId) => {
      displayNotificationError(registerErrorTrace(error, { patientId }))
    },
  })

  return { link: data, sendPaymentSetup, isSending }
}

export { useOrganizationPaymentSetup }
