import { useInfiniteQuery } from "@tanstack/react-query"
import { ChargeItemDefinition, getResources, PlanDefinition, Questionnaire } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { BILLING_TYPES_CODES } from "data"
import { convertIdentifiersToCodings, getBasePrice, getCidIdentifier, getCommonCode, getIndexedCID } from "utils"

import { useCIDQueryFunction } from "../../hooks/useChargeItemDefinitions"
import { commonsQueryKeys } from "../../query-keys"
import { LaboratoryTest } from "../../types"

const useLaboratoryTests = ({
  organizationId,
  count = 50,
  canonicals,
  catalogAuthorId,
  searchText,
  enabled,
  billingType,
  withPrice = false,
}: {
  organizationId: string
  catalogAuthorId?: string
  searchText?: string
  count?: number
  canonicals?: string[]
  enabled?: boolean
  billingType?: BILLING_TYPES_CODES
  withPrice: boolean
}) => {
  const { search } = useClient()
  const queryKey = commonsQueryKeys.laboratoryTests(
    organizationId,
    catalogAuthorId,
    searchText,
    count,
    canonicals,
    billingType,
    withPrice,
  )

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    LaboratoryTestsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { _filter: searchText, identifier: searchText } : {}),
        _query: "laboratory-tests",
        ...(catalogAuthorId ? { catalogAuthor: catalogAuthorId } : {}),
        ...(canonicals ? { canonical: canonicals.join(",") } : {}),
        organization: organizationId,
        _count: count.toString(),
        _page: `${pageParam}`,
        hascid: `${withPrice}`,
      })

      const respBundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinitions = getResources<PlanDefinition>(respBundle, "PlanDefinition")
      const codes = convertIdentifiersToCodings(planDefinitions)
      const questionnaires = getResources<Questionnaire>(respBundle, "Questionnaire")

      const { billToPracticeOrInsuranceCIDs, billToPatientCIDs } = await getChargeItemDefinitions(
        organizationId,
        billingType === BILLING_TYPES_CODES.BILL_PATIENT
          ? { billToPatientCIDs: codes }
          : { billToPracticeOrInsuranceCIDs: codes },
      )

      const next = respBundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        planDefinitions,
        next,
        chargeItemDefinitions: {
          billToPracticeOrInsuranceCIDs: Object.values(billToPracticeOrInsuranceCIDs),
          billToPatientCIDs: Object.values(billToPatientCIDs),
        },
        questionnaires,
        total: planDefinitions?.length ?? 0,
      }
    },

    enabled: !!catalogAuthorId && enabled,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    throwOnError: true,
    meta: { context: { queryKey, catalogAuthorId, searchText } },
  })

  const labTests = useMemo(() => {
    const chargeItemDefinitions = data?.pages.reduce(
      (acc, curr) => {
        return {
          billToPracticeOrInsuranceCIDs: [
            ...acc.billToPracticeOrInsuranceCIDs,
            ...curr.chargeItemDefinitions.billToPracticeOrInsuranceCIDs,
          ],
          billToPatientCIDs: [...acc.billToPatientCIDs, ...curr.chargeItemDefinitions.billToPatientCIDs],
        }
      },
      { billToPracticeOrInsuranceCIDs: [] as ChargeItemDefinition[], billToPatientCIDs: [] as ChargeItemDefinition[] },
    )
    const planDefinitions = data?.pages.flatMap((page) => page.planDefinitions)
    const questionnaires = data?.pages.flatMap((page) => page.questionnaires)

    const cids =
      billingType === BILLING_TYPES_CODES.BILL_PATIENT
        ? getIndexedCID(chargeItemDefinitions?.billToPatientCIDs)
        : getIndexedCID(chargeItemDefinitions?.billToPracticeOrInsuranceCIDs)

    const indexedQuestionnaires = questionnaires?.reduce<Record<string, Questionnaire>>((acc, cur) => {
      return { ...acc, [`${cur?.url}|${cur.version}`]: cur }
    }, {})

    const result = planDefinitions?.reduce((acc, pd) => {
      if (cids) {
        const codes = convertIdentifiersToCodings(pd ? [pd] : [])
        const cid = cids[getCidIdentifier(getCommonCode({ codes }))]

        const questionnaireActions = pd.action?.filter((def) => def.definition?.canonical?.includes("Questionnaire"))
        const pdQuestionnaires = questionnaireActions?.flatMap((qa) =>
          indexedQuestionnaires?.[qa.definition?.canonical as string]
            ? indexedQuestionnaires?.[qa.definition?.canonical as string]
            : [],
        )

        const newLabTest = {
          planDefinition: pd,
          price: getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount,
          display: pd.title ?? pd.name ?? "Unknown",
          questionnaires: pdQuestionnaires,
        }

        return [...acc, newLabTest]
      }
      return acc
    }, new Array<LaboratoryTest>())

    return result
  }, [data])

  return {
    labTests,
    total: data?.pages?.[0]?.total ?? 0,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage: hasNextPage,
    fetchNextPage,
  }
}

type LaboratoryTestsQueryData = {
  planDefinitions: PlanDefinition[]
  next: number | undefined
  chargeItemDefinitions: {
    billToPracticeOrInsuranceCIDs: ChargeItemDefinition[]
    billToPatientCIDs: ChargeItemDefinition[]
  }
  questionnaires: Questionnaire[]
  total: number
}
export { useLaboratoryTests }
