import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { usePatientContext } from "patients"

import { medicationStatementQueryKeys } from "../query-keys"

const useDeleteMedication = (onSettled?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const deleteMedicationStatement = async (medStatementId: string) => remove("MedicationStatement", medStatementId)

  const { mutate: removeMedicationStatement, isPending: isDeleting } = useMutation({
    mutationFn: deleteMedicationStatement,
    onError: (error: CustomError, medStatementId) => {
      displayNotificationError(registerErrorTrace(error, { medStatementId }))
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: medicationStatementQueryKeys.list(patientId),
        refetchType: "all",
      })
      displayNotificationSuccess("Medication Statement deleted successfully!")
      datadogLogs.logger.info("Medication Statement deleted successfully!", { data })
    },
    onSettled,
  })

  return { removeMedicationStatement, isDeleting }
}

export { useDeleteMedication }
