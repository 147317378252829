import { AutocompleteCodeableConceptField, DateField, SwitchField, TextAreaField, ValueSetIds } from "commons"

const MedicationStatementForm = ({ isEditing }: Props) => (
  <div className="relative p-fluid grid gap-4 mb-3">
    <span className="flex justify-between space-x-6">
      <AutocompleteCodeableConceptField
        field="medication.CodeableConcept"
        label="Medication"
        valueSetId={ValueSetIds.RXNORM}
        disabled={isEditing}
        className="flex-1"
      />
      <SwitchField field="status" label="Currently Taken" trueValue={"active"} falseValue={"unknown"} />
    </span>
    <AutocompleteCodeableConceptField field="reasonCode[0]" label="Reason" valueSetId={ValueSetIds.ICD10} />
    <DateField
      field="effective.dateTime"
      label="Effective"
      stringFormatType="ISO_8601_DATETIME"
      className="grow mr-3"
      showTime={true}
    />
    <TextAreaField field="note[0].text" label="Note" rows={5} />
  </div>
)

type Props = {
  isEditing?: boolean
}

export { MedicationStatementForm }
