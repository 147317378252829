import { parseISO } from "date-fns"
import { format } from "date-fns-tz"
import { Task } from "fhir"
import isEqual from "lodash/isEqual"

import { formatsByTypes } from "data"

export const getTaskDate = (task: Task, showExcecutionPeriod = true) => {
  const startDate = showExcecutionPeriod
    ? (task.executionPeriod?.start ?? task.restriction?.period?.start)
    : task.restriction?.period?.start
  if (!startDate) return "Not specified date"
  const endDate = showExcecutionPeriod
    ? (task.executionPeriod?.end ?? task.restriction?.period?.end)
    : task.restriction?.period?.end

  const startStr = taskDateToString(startDate)
  const endStr = endDate && taskDateToString(endDate)

  return isEqual(startStr, endStr) ? endStr : `${startStr}${endStr ? ` - ${endStr}` : ""}`
}

export const taskDateToString = (fieldDate: string) =>
  format(
    parseISO(fieldDate),
    fieldDate.includes("T") ? formatsByTypes.LONG_DATETIME_WITH_TIMEZONE : formatsByTypes.LONG_DATE,
  )
