import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { coverageQueryKeys } from "../query-key"

const useDeleteCoverage = (hideForm: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const removeFn = async (coverageId: string) => remove("Coverage", coverageId)

  const { mutate: deleteCoverage, isPending: isDeleting } = useMutation({
    mutationFn: removeFn,
    onError: (error: CustomError, id) => {
      displayNotificationError(registerErrorTrace(error, { coverageId: id }))
    },
    onSuccess: async (data) => {
      displayNotificationSuccess("Insurance deleted successfully!")
      datadogLogs.logger.info("Insurance deleted successfully!", { data })
      await queryClient.invalidateQueries({ queryKey: coverageQueryKeys.all })
    },
    onSettled: hideForm,
  })

  return { deleteCoverage, isDeleting }
}

export { useDeleteCoverage }
