import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, CarePlan, ResourceObject, getResource } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { CustomError } from "../../types"
import { plansQueryKeys } from "../query-keys"

const useCancelPlan = (
  onSettled?: (data: ResourceObject | undefined, error: CustomError | null, variables: string) => void,
) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const cancel = async (planId: string) =>
    operationRequest({ endpoint: "CarePlan", method: "POST", operation: "cancel", id: planId })

  const { mutate: cancelPlan, isPending } = useMutation({
    mutationFn: cancel,
    onError: (error: CustomError, planId) => {
      displayNotificationError(registerErrorTrace(error, { planId }))
    },
    onSuccess: async (data) => {
      const careplan = getResource<CarePlan>(data as Bundle, "CarePlan")
      const isMCPlan = careplan?.instantiatesCanonical?.includes("mc")

      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.all })
      queryClient.refetchQueries({
        queryKey: ordersQueryKeys.count.withPatientId(patientId),
        type: "all",
      })
      if (isMCPlan) {
        displayNotificationSuccess("Plan canceled successfully!")
        datadogLogs.logger.info(`Plan ${careplan.id} canceled successfully!`)
      }
    },
    onSettled,
  })

  return { cancelPlan, isCanceling: isPending }
}

export { useCancelPlan }
