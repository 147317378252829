import { ModulesId } from "commons"

const INVOICE_ORDER_TYPE_MAP = {
  nutraceutical: {
    module: ModulesId.MEDICATIONR,
    subview: { completed: "history", default: "orders" },
    orderParam: "order",
  },
  pharmaceutical: {
    module: ModulesId.EPRESCRIBE,
    subview: { completed: "history", default: "orders" },
    orderParam: "order",
  },
  laboratory: { module: ModulesId.LABSORDER, subview: undefined, orderParam: "order" },
  procedure: {
    module: ModulesId.PROCEDURES,
    subview: undefined,
    orderParam: "procedureId",
  },
  plan: {
    module: ModulesId.PLANS,
    subview: { completed: ModulesId.PLAN_DETAILS, default: ModulesId.PLAN_DETAILS },
    orderParam: "planId",
  },
}

const INVOICE_PRODUCT_TYPE_MAP = {
  nutraceutical: {
    tabId: "nutraceuticals",
  },
  pharmaceutical: {
    tabId: "pharmaceuticals",
  },
  laboratory: { tabId: "labs" },
  procedure: {
    tabId: "recommendations",
  },
  plan: {
    tabId: "questionnaires",
  },
}

export { INVOICE_ORDER_TYPE_MAP, INVOICE_PRODUCT_TYPE_MAP }
