import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, BundleEntryArray, CarePlan, MedicationAdministration, asReference, getResource } from "fhir"
import { v4 } from "uuid"

import { useClient } from "api"
import { CustomError } from "commons"
import { plansQueryKeys } from "commons/care-plans"
import { ProcedureData } from "commons/procedures"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { displayNotificationSuccess } from "utils"

import { proceduresQueryKeys } from "../query-keys"

const useCreateProcedure = (patientId: string, onSettled?: () => void) => {
  const { transaction, read } = useClient()
  const queryClient = useQueryClient()

  const newProcedure = async ({ procedure, configurationItem, deletedMedications }: ProcedureData) => {
    const procUrl = `urn:uuid:${v4()}`
    let carePlan: CarePlan | undefined = undefined
    const carePlanId = procedure.basedOn?.find(({ resourceType }) => resourceType === "CarePlan")?.id

    if (carePlanId) {
      const filter = new URLSearchParams({ _id: carePlanId })
      const cpBundle = await read<Bundle>({ endpoint: `Patient/${patientId}/CarePlan`, filters: filter })
      carePlan = getResource<CarePlan>(cpBundle, "CarePlan")
    }

    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [
        {
          ...(!procedure.id ? { fullUrl: procUrl } : {}),
          request: {
            method: procedure.id ? "PUT" : "POST",
            url: `Procedure${procedure.id ? `/${procedure.id}` : ""}`,
          },
          resource: { ...procedure, resourceType: "Procedure" },
        },
      ],
    }

    if (carePlan) {
      const procedureActivityIndex =
        carePlan.activity?.findIndex(
          ({ outcomeCodeableConcept }) => outcomeCodeableConcept?.[0]?.coding?.[0]?.code === "procedure",
        ) ?? -1

      if (carePlan.activity && procedureActivityIndex !== -1) {
        carePlan.activity[procedureActivityIndex] = {
          ...carePlan.activity[procedureActivityIndex],
          outcomeReference: [...(carePlan.activity[procedureActivityIndex]?.outcomeReference ?? []), { uri: procUrl }],
        }
      }

      bundle.entry?.push({
        request: {
          method: "PATCH",
          url: `CarePlan/${carePlanId}`,
        },
        resource: { ...carePlan, resourceType: "CarePlan" },
      })
    }

    const medData = configurationItem.reduce<Array<BundleEntryArray>>(
      (acc, { medicationRequest, medicationAdministration }) => {
        const mrUrl = `urn:uuid:${v4()}`

        const mrEntry = medicationRequest && {
          ...(!medicationRequest.id ? { fullUrl: mrUrl } : {}),
          request: {
            method: medicationRequest.id ? "PUT" : "POST",
            url: `MedicationRequest${medicationRequest.id ? `/${medicationRequest.id}` : ""}`,
          },
          resource: { ...medicationRequest, resourceType: "MedicationRequest" },
        }

        const maEntry = medicationAdministration && {
          request: {
            method: medicationAdministration?.id ? "PUT" : "POST",
            url: `MedicationAdministration${medicationAdministration?.id ? `/${medicationAdministration.id}` : ""}`,
          },
          resource: {
            ...medicationAdministration,
            ...(medicationRequest
              ? { request: medicationRequest.id ? asReference(medicationRequest) : { uri: mrUrl } }
              : {}),
            resourceType: "MedicationAdministration",
            partOf: [procedure.id ? asReference(procedure) : { uri: procUrl }],
          } as MedicationAdministration,
        }

        return [...acc, ...(mrEntry ? [mrEntry] : []), ...(maEntry ? [maEntry] : [])]
      },
      [],
    )
    bundle.entry?.push(...medData)

    if (deletedMedications.length > 0) {
      const removeMed = deletedMedications.reduce<Array<BundleEntryArray>>((acc, { mrId, maId }) => {
        if (maId)
          acc.push({
            request: {
              method: "DELETE",
              url: `MedicationAdministration/${maId}`,
            },
          })
        if (mrId)
          acc.push({
            request: {
              method: "DELETE",
              url: `MedicationRequest/${mrId}`,
            },
          })
        return acc
      }, [])
      bundle.entry?.push(...removeMed)
    }

    return transaction(bundle)
  }

  const { mutateAsync: createProcedure, isPending } = useMutation({
    mutationFn: newProcedure,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { procedure }) => {
      await queryClient.invalidateQueries({ queryKey: proceduresQueryKeys.list(patientId) })
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      queryClient.refetchQueries({ queryKey: plansQueryKeys.details(patientId), type: "all" })
      displayNotificationSuccess("Procedure added successfully!")
      datadogLogs.logger.info(`Procedure ${procedure.id} added successfully!`, { procedure })
    },
    onSettled,
  })

  return { createProcedure, isCreating: isPending }
}

export { useCreateProcedure }
