import { HubConnection } from "@microsoft/signalr"
import { checkScreenSharingCapability } from "@opentok/client"
import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useEffect, useState } from "react"
import { useSignalR } from "signalR"

const VideoCallContext = createContext({} as TVideoCallContext)

const VideoCallProvider: FC<PropsWithChildren> = ({ children }) => {
  const { connection } = useSignalR({ url: window.VITE_APP_SIGNALR_CALL_NOTIFICATION })
  const [destroyedSession, setDestroyedSession] = useState(false)
  const [warningBeforeUnload, setWarningBeforeUnload] = useState(false)
  const browserNotSupportedPIP = !("pictureInPictureElement" in document) || !document.pictureInPictureEnabled
  const [browserNotSupportedShareScreen, setBrowserNotSupportedShareScreen] = useState(false)

  const handleDestroyedVCEvent = () =>
    connection?.on("FinishVideoCall", (data: { sessionId: string }) => setDestroyedSession(!!data.sessionId))

  useEffect(() => {
    handleDestroyedVCEvent()
  }, [connection])

  useEffect(() => {
    checkScreenSharingCapability((response) =>
      setBrowserNotSupportedShareScreen(!response.supported || response.extensionRegistered === false),
    )
  }, [])

  const data = {
    destroyedSession,
    setDestroyedSession,
    connection,
    browserNotSupportedPIP,
    setWarningBeforeUnload,
    warningBeforeUnload,
    browserNotSupportedShareScreen,
  }

  return <VideoCallContext.Provider value={data}>{children}</VideoCallContext.Provider>
}

type TVideoCallContext = {
  connection?: HubConnection
  destroyedSession: boolean
  setDestroyedSession: (destroyed: boolean) => void
  browserNotSupportedPIP: boolean
  setWarningBeforeUnload: Dispatch<SetStateAction<boolean>>
  warningBeforeUnload: boolean
  browserNotSupportedShareScreen: boolean
}

export { VideoCallContext, VideoCallProvider }
