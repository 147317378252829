import { useInfiniteQuery } from "@tanstack/react-query"
import { ChargeItemDefinition, Coding, MedicationKnowledge, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import { MEDICATION_CATALOG } from "data"
import { getBasePrice, getBillToPatientFeePrice, getCidIdentifier, getCommonCode, getMedCodes } from "utils"

import { allowedMKDoseFormsToGetReferencePrices, referencePriceQuantities } from "administration/data"
import { settingsQueryKeys } from "../../query-keys"
import { MedicationsAdvanceFilter, ReferencePriceItem } from "../../types"

const useMksByCategory = ({
  category,
  enabled = true,
  filters,
  organizationId,
  useReferencePriceQuantities,
}: {
  organizationId: string
  category: MEDICATION_CATALOG
  filters: Filters
  enabled: boolean
  useReferencePriceQuantities?: boolean
}) => {
  const { search } = useClient()
  const { mkCatalogs: catalogs, searchText, medsClassificationCodes } = filters
  const queryKey = settingsQueryKeys.meds({
    organizationId,
    category,
    catalogs,
    medsClassificationCodes,
    archived: false,
    searchText,
  })
  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(catalogs?.length ? { catalogHeader: catalogs.join(",") } : {}),
        ...(searchText ? { termsearch: searchText } : {}),
        "catalogHeader:Composition.author:Organization.type": category,
        ...(medsClassificationCodes?.length ? { classification: medsClassificationCodes.join(",") } : {}),
        _count: "50",
        _page: `${pageParam}`,
        _sort: "-code",
        status: "active",
        "has-cid-in-org": organizationId,
      })

      const bundle = await search({ endpoint: "MedicationKnowledge", filters, signal })
      const medicationKnowledge = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined
      const total = bundle.total ?? 0
      const mkCodes = getMedCodes({
        meds: medicationKnowledge,
        withQty: true,
        referenceQuantities: useReferencePriceQuantities ? referencePriceQuantities : [],
      })

      const chargeItemDefinitions = await getChargeItemDefinitions(organizationId, {
        billToPracticeOrInsuranceCIDs: mkCodes,
      })

      return { medicationKnowledge, mkCodes, cids: chargeItemDefinitions.billToPracticeOrInsuranceCIDs, next, total }
    },
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, ...filters } },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
    enabled,
  })

  const { medicationKnowledge, mkCodes, medsWithCID } = useMemo(() => {
    const { medicationKnowledge, mkCodes, cids } = data?.pages.reduce(
      (acc, page) => {
        return {
          ...acc,
          medicationKnowledge: [...acc.medicationKnowledge, ...page.medicationKnowledge],
          mkCodes: [...acc.mkCodes, ...(page.mkCodes ?? [])],
          cids: { ...acc.cids, ...page.cids },
        }
      },
      {
        medicationKnowledge: Array<MedicationKnowledge>(),
        mkCodes: Array<Coding>(),
        cids: {} as Record<string, ChargeItemDefinition>,
      },
    ) ?? { medicationKnowledge: [], mkCodes: [], cids: {} }

    const medsWithCID = medicationKnowledge.reduce((acc, mk) => {
      const mkCode = getCommonCode({ codes: mk.code?.coding })
      const cidIdentifier = getCidIdentifier(mkCode)

      const pc = cids?.[cidIdentifier]?.propertyGroup?.[0]?.priceComponent
      const basePrice = getBasePrice(pc)?.amount
      const fee = getBillToPatientFeePrice(pc)?.amount

      const isAllowedToGetReferencePrices =
        !!allowedMKDoseFormsToGetReferencePrices[mk.doseForm?.coding?.[0]?.code ?? ""]

      const referencePrices =
        isAllowedToGetReferencePrices && useReferencePriceQuantities
          ? referencePriceQuantities.map<ReferencePriceItem>((qty) => {
              const cidIdentifier = getCidIdentifier(mkCode, qty)
              const pc = cids?.[cidIdentifier]?.propertyGroup?.[0]?.priceComponent

              return { qty, price: getBasePrice(pc)?.amount?.value ?? 0 }
            })
          : []

      return [...acc, { mk, price: basePrice?.value ?? 0, fee: fee?.value ?? 0, referencePrices }]
    }, Array<{ mk: MedicationKnowledge; price: number; fee: number; referencePrices: ReferencePriceItem[] }>())

    return { medicationKnowledge, mkCodes, medsWithCID }
  }, [data?.pages])

  return {
    medicationKnowledge: medicationKnowledge ?? [],
    medicationKnowledgeCodes: mkCodes,
    medsWithCID,
    isLoading: isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type Filters = MedicationsAdvanceFilter & { searchText?: string }

export { useMksByCategory }
