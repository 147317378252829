import { useMutation, useQueryClient } from "@tanstack/react-query"
import { List, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { SYSTEM_VALUES } from "system-values"
import { displayNotificationSuccess } from "utils"

const useCancelMrOrder = (onSuccess?: () => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const cancelOrder = ({
    serviceRequestId,
    patientId,
    cancelReason,
    cancel_mode,
  }: {
    serviceRequestId: string
    patientId: string
    cancelReason: string
    cancel_mode: string
  }) => {
    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: [
        {
          item: {
            resourceType: "ServiceRequest",
            id: serviceRequestId,
          },
          flag: {
            coding: [
              {
                code: cancel_mode,
                system: SYSTEM_VALUES.ORDER_CANCEL_MODE,
              },
            ],
          },
        },
      ],
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "orders",
          resource: list,
        },
        {
          name: "reason",
          value: {
            string: cancelReason,
          },
        },
      ],
    }

    return operationRequest({ endpoint: `Patient/${patientId}/cpoe`, method: "POST", operation: "cancel", parameters })
  }

  const { mutate: cancelMrOrder, isPending } = useMutation({
    mutationFn: cancelOrder,
    onError: (error: CustomError, serviceRequestId) => {
      displayNotificationError(registerErrorTrace(error, { serviceRequestId }))
    },
    onSuccess: (_, { serviceRequestId }) => {
      queryClient.refetchQueries({
        queryKey: medsQueryKeys.orderDetails.details(serviceRequestId),
        type: "all",
      })
      displayNotificationSuccess("Order cancelled successfully")
      datadogLogs.logger.info(`Order with id ${serviceRequestId} cancelled successfully`)
      onSuccess?.()
    },
    onSettled: onSettled,
  })

  return { cancelMrOrder, isCancelling: isPending }
}

export { useCancelMrOrder }
