import * as Yup from "yup"
import { AllergyIntolerance, CodeableConcept, Reference } from "fhir"

const getInitialValues = (patientRef: Reference, encounter?: Reference): AllergyIntolerance => ({
  code: { coding: undefined },
  clinicalStatus: undefined,
  type: undefined,
  category: undefined,
  criticality: undefined,
  recordedDate: new Date().toISOString(),
  lastOccurrence: new Date().toISOString(),
  onset: { dateTime: new Date().toISOString() },
  // recorder: asReference(practitioner as Practitioner) ?? undefined,
  patient: patientRef,
  note: [{ text: "" }],
  reaction: [
    {
      manifestation: [{ text: undefined }],
    },
  ],
  encounter,
})

const allergyValidationSchema = Yup.object().shape({
  code: Yup.object().test(
    "test-codeable-concept",
    "Code is required",
    (value: CodeableConcept) => value?.coding !== undefined,
  ),
  clinicalStatus: Yup.object().required("Clinical status is required"),
  type: Yup.string().required("Type is required"),
  category: Yup.array().min(1, "At least one category is required").required("Category is required"),
  criticality: Yup.string().required("Criticality is required"),
  lastOccurrence: Yup.date().typeError("Last occurence date is required").required("Last occurence date is required"),
  patient: Yup.object().required("Patient is required"),
  reaction: Yup.array(
    Yup.object().shape({
      manifestation: Yup.array(Yup.object().shape({ text: Yup.string().required("Manifestation is required") })),
    }),
  ),
})

const sanitize = ({ ...allergy }: AllergyIntolerance) => {
  if (!allergy.note?.[0]?.text) delete allergy.note
  if (!allergy.encounter) delete allergy.encounter

  allergy.lastOccurrence = new Date(allergy.lastOccurrence as string).toISOString()
  allergy.onset = { dateTime: new Date(allergy.onset?.dateTime as string).toISOString() }

  return allergy
}

export { allergyValidationSchema, getInitialValues, sanitize }
