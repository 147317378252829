import { useFormikContext } from "formik"
import { useState } from "react"

import { BillingField } from "commons"
import { LaboratoryOrder } from "commons/labs"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"

import { RemovePanelsDialog } from "./RemovePanelsDialog"

const BillingDropdownField = ({ field, label }: Props) => {
  const { isExemptLabPayment } = useOrganizationContext()
  const [showDeletePanelsConfirm, setShowDeletePanelsConfirm] = useState(false)

  const {
    values: { panels, deletedPanels },
    setFieldValue,
  } = useFormikContext<LaboratoryOrder>()

  const panelsWithouhtPrice = panels.filter((panel) => !panel.price)

  const handleChange = (value: string) => {
    setShowDeletePanelsConfirm(
      value !== BILLING_TYPES_CODES.INSURANCE && !isExemptLabPayment && panelsWithouhtPrice.length > 0,
    )
  }

  const cancelRemovePanels = () => {
    setFieldValue("billingType", "insurance")
    setShowDeletePanelsConfirm(false)
  }

  const removeNoPricePanels = () => {
    const panelsToDelete = panels.filter((panel) => panel.profile.id && !panel.price)
    setFieldValue("deletedPanels", [
      ...(deletedPanels ?? []),
      ...panelsToDelete.filter((panel) => panel.profile.id !== undefined),
    ])

    setFieldValue(
      "panels",
      panels.filter((panel) => !!panel.price),
    )

    setShowDeletePanelsConfirm(false)
  }

  return (
    <>
      <BillingField insuranceField={field} insuranceLabel={label} onBillingChange={handleChange} />
      {showDeletePanelsConfirm && (
        <RemovePanelsDialog panels={panelsWithouhtPrice} onCancel={cancelRemovePanels} onRemove={removeNoPricePanels} />
      )}
    </>
  )
}

type Props = {
  label?: string
  field: string
}

export { BillingDropdownField }
