import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address, Coding } from "fhir"
import { useFormikContext } from "formik"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { useEffect, useMemo, useRef } from "react"

import { useValueSet } from "value-set"

import { isPoBoxAddress } from "utils"
import { ValueSetIds } from "../types"
import { DropdownField } from "./DropdownField"
import { InputField } from "./InputField"
import { addressTypes } from "./data"

const AddressField = ({
  parentFieldName,
  showAutoComplete = true,
  disabled = false,
  limitToContinentalUS = false,
  showTypeUseField = true,
  validAddressTypes,
}: Props) => {
  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""
  const { codes: availableStates } = useValueSet({ valueSetId: ValueSetIds.CONTINENTAL_USA_STATES })
  const { setFieldValue, values } = useFormikContext<Address>()

  const inputRef = useRef<HTMLInputElement>(null)

  const isAddressPoBox = useMemo(() => isPoBoxAddress(values), [values])

  useEffect(() => {
    if ("google" in window) {
      const options: {
        componentRestrictions: { country: string[] }
        fields: string[]
        types: string[]
        bounds?: google.maps.LatLngBounds
        strictBounds?: boolean
      } = {
        componentRestrictions: { country: ["pr", "us"] },
        fields: ["address_components"],
        types: ["address"],
      }

      if (limitToContinentalUS) {
        options.bounds = getUSContinentalBounds()
        options.componentRestrictions.country.shift()
        options.strictBounds = true
      }

      try {
        const autoComplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
          inputRef.current as HTMLInputElement,
          options,
        )

        autoComplete.addListener("place_changed", () => {
          const place = autoComplete?.getPlace()
          let route = ""
          let streetNumber = ""

          if (place) {
            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
              const componentType = component.types[0]

              switch (componentType) {
                case "route": {
                  route = component.long_name
                  break
                }

                case "street_number": {
                  streetNumber = component.long_name
                  break
                }

                case "postal_code": {
                  setFieldValue(`${parentFieldFullName}postalCode`, component.long_name)
                  break
                }

                case "locality":
                  setFieldValue(`${parentFieldFullName}city`, component.long_name)
                  break

                case "administrative_area_level_1": {
                  setFieldValue(`${parentFieldFullName}state`, component.short_name)
                  break
                }
              }
            }

            setFieldValue(`${parentFieldFullName}line[0]`, `${streetNumber} ${route}`)
            setFieldValue(`${parentFieldFullName}line[1]`, "")
          }
        })
      } catch {
        /* empty */
      }
    }
  }, [parentFieldFullName, setFieldValue, limitToContinentalUS])

  return (
    <>
      <IconField iconPosition="right">
        <InputField
          field={`${parentFieldFullName}line[0]`}
          label="Address Line 1"
          placeholder={`Enter street ` + `\u0430` + `ddress`}
          disabled={disabled}
          autocomplete="off"
          spellcheck={false}
          type="text"
          ref={showAutoComplete && "google" in window ? inputRef : null}
          className="relative"
        />
        {!!isAddressPoBox && (
          <InputIcon>
            <Tooltip
              target=".tooltiped"
              event="hover"
              position="left"
              content="It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be suitable for receiving deliveries"
            />
            <span className="cursor-pointer tooltiped">
              <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
            </span>
          </InputIcon>
        )}
      </IconField>

      <InputField
        field={`${parentFieldFullName}line[1]`}
        label="Address Line 2"
        disabled={disabled}
        type="text"
        autocomplete="off"
        spellcheck={false}
      />

      <div className="@container col-span-2 w-full">
        <div
          className={classNames(
            "p-fluid grid grid-cols-1 gap-4",
            showTypeUseField ? "@sm:grid-cols-4" : "@sm:grid-cols-3",
          )}
        >
          {showTypeUseField && (
            <DropdownField
              field={`${parentFieldFullName}type`}
              label="Use"
              className="grow"
              options={validAddressTypes ?? addressTypes}
              disabled={disabled}
            />
          )}
          <InputField
            field={`${parentFieldFullName}city`}
            label="City"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            spellcheck={false}
            type="text"
          />
          <DropdownField
            field={`${parentFieldFullName}state`}
            label="State"
            className="grow"
            options={availableStates as Coding[]}
            optionLabel="display"
            disabled={disabled}
          />
          <InputField
            field={`${parentFieldFullName}postalCode`}
            label="ZIP Code"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            spellcheck={false}
            type="text"
          />
        </div>
      </div>
    </>
  )
}

const getUSContinentalBounds = () => {
  const southwest = { lat: 26.285164489602305, lng: -124.90260564687212 }
  const northeast = { lat: 44.78482115483085, lng: -66.72621694317236 }

  return new google.maps.LatLngBounds(southwest, northeast)
}

type Props = {
  parentFieldName?: string
  showAutoComplete?: boolean
  disabled?: boolean
  limitToContinentalUS?: boolean
  showTypeUseField?: boolean
  validAddressTypes?: {
    code: string
    label: string
  }[]
}

export { AddressField }
