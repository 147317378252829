import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"
import pluralize from "pluralize"
import { FC } from "react"

import { CardSelectionItem } from "commons"

import { MedicationRequestFormData } from "../../types"
import { getDispenseFrequency } from "../../utils/transformers"

const PrescriptionSelectionItem: FC<Props> = ({ selected, item, disabled, onSelect, onShowDetails, edit }) => {
  const title = codeableConceptAsString(item.medication?.CodeableConcept ?? item.medicationKnowledge?.code)
  const ingredient = item.medicationKnowledge?.ingredient?.[0]?.strength?.numerator?.unit
  const dispense = getDispenseFrequency(item.dispenseRequest?.dispenseInterval)
  const packaging =
    item.medicationKnowledge?.packaging?.type?.coding?.[0] &&
    `${item.medicationKnowledge?.packaging?.type?.coding?.[0]?.display}`

  const dosages = item.dosageInstruction?.flatMap(({ text }) => text ?? "")
  const refillsCount = item?.dispenseRequest?.numberOfRepeatsAllowed ?? 0

  return (
    <CardSelectionItem
      selected={selected}
      mainText={title}
      onSelect={() => onSelect(item)}
      clickableArea="card"
      showSelectedMark={false}
      extraDetails={
        <div className="flex gap-x-3 text-gray-400 text-sm flex-wrap mt-1 relative w-full">
          {!!ingredient && <span title="Content">{ingredient}</span>}
          {!!item.medicationKnowledge?.doseForm?.coding?.[0]?.display && (
            <span title="Dose Form" className="space-x-1 inline-flex">
              <p>{item.medicationKnowledge?.packaging?.quantity?.value}</p>
              <p>{item.medicationKnowledge?.doseForm?.coding?.[0]?.display}</p>
            </span>
          )}
          {!!packaging && <span title="Packaging">Pkg: {packaging}</span>}
          {!!item.dispenseRequest && (
            <>
              {!!item.dispenseRequest.quantity?.value && (
                <span title="Dispense">
                  {item.dispenseRequest.quantity?.value} {item.dispenseRequest.quantity?.unit} - {dispense?.label}
                </span>
              )}
              {!!refillsCount && <span title="Refill">{`${refillsCount} ${pluralize("refill", refillsCount)}`}</span>}

              {!!item.dispenseRequest.performer?.display && (
                <span title="Pharmacy">{item.dispenseRequest.performer.display}</span>
              )}
            </>
          )}
          {!item.dispenseRequest?.performer && !!item.medicationKnowledge?.manufacturer?.display && (
            <span title="Manufacturer">{item.medicationKnowledge?.manufacturer?.display}</span>
          )}

          <span title={`Sig: ${dosages?.join(" ")}`} className="flex relative overflow-hidden">
            <span className="gap-x-1 text-ellipsis line-clamp-1">
              {dosages?.flatMap((text, index) => <p key={`${index}-${text}`}>{text}</p>)}
            </span>
          </span>
        </div>
      }
      rightData={
        !!edit &&
        !!selected && (
          <div className="flex mr-1 text-gray-500 hover:text-primary-hover">
            <FontAwesomeIcon
              icon={faPencil}
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                edit(item)
              }}
            />
          </div>
        )
      }
      disabled={disabled}
      onShowDetails={() => onShowDetails?.(item)}
    />
  )
}

type Props = {
  selected?: boolean
  item: MedicationRequestFormData
  disabled?: boolean
  onShowDetails?(item: MedicationRequestFormData): void
  onSelect(item: MedicationRequestFormData): void
  edit?(item: MedicationRequestFormData): void
}

export { PrescriptionSelectionItem }
