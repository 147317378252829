import { faCalendarDays, faFaceTissue } from "@fortawesome/pro-regular-svg-icons"
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationStatement, codeableConceptAsString } from "fhir"

import { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate, strCapitalize } from "utils"

const medicationModel = (
  medicationStatement: MedicationStatement,
  edit?: () => void,
  remove?: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          name: "Medication",
          value: strCapitalize(codeableConceptAsString(medicationStatement.medication?.CodeableConcept)),
        },
      ],
    },
    {
      lineItems: [
        {
          name: "Reason",
          value:
            medicationStatement.reasonCode?.[0].text ?? codeableConceptAsString(medicationStatement.reasonCode?.[0]),
          icon: faFaceTissue,
        },
        ...(medicationStatement.effective?.Period?.start && medicationStatement.effective?.Period?.end
          ? [
              ...(medicationStatement.effective.Period?.start
                ? [
                    {
                      name: "Start",
                      value:
                        "From: " +
                        formatDate(
                          new Date(medicationStatement.effective.Period?.start as string),
                          formatsByTypes.LONG_DATETIME,
                        ),
                      icon: faCalendarDays,
                    },
                  ]
                : []),
              ...(medicationStatement.effective.Period?.end
                ? [
                    {
                      name: "End",

                      value:
                        "Until: " +
                        formatDate(
                          new Date(medicationStatement.effective.Period?.end as string),
                          formatsByTypes.LONG_DATETIME,
                        ),
                      icon: faCalendarDays,
                    },
                  ]
                : []),
            ]
          : []),
        ...(medicationStatement.effective?.dateTime
          ? [
              {
                name: "Effective",
                value:
                  "Taken on: " +
                  formatDate(new Date(medicationStatement.effective.dateTime as string), formatsByTypes.LONG_DATETIME),
                icon: faCalendarDays,
              },
            ]
          : []),
      ],
    },
  ],
  badge: medicationStatement.status === "active" ? { text: "Taken", colorStyle: "green" } : undefined,
  menu: [
    ...(edit
      ? [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
            command: edit,
          },
        ]
      : []),
    ...(remove
      ? [
          {
            label: "Delete",
            icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
            command: remove,
          },
        ]
      : []),
  ],
})

export { medicationModel }
