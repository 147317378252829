import { ResourceObject, SettingItemArray } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { useOrganizationContext } from "organization"

import { settingsQueryKeys } from "../../query-keys"

const useUpdateSetting = (settingUpdatedQueryKey?: string[]) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const { currentOrganizationId } = useOrganizationContext()

  const updatedSettings = async ({ settingId, items }: { settingId: string; items: SettingItemArray[] }) =>
    patch("Setting", settingId, { item: items } as ResourceObject)

  const { mutate: updateSetting, isPending: isUpdating } = useMutation({
    mutationFn: updatedSettings,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { settingId }) => {
      await queryClient.invalidateQueries({
        queryKey: settingUpdatedQueryKey ?? settingsQueryKeys.all(currentOrganizationId, settingId),
        refetchType: "all",
      })

      displayNotificationSuccess("Settings updated successfully")
      datadogLogs.logger.info(`${settingId} updated successfully`)
    },
  })

  return { updateSetting, isUpdating }
}

export { useUpdateSetting }
