import { codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"

import { InputField, NumberField } from "commons"
import { sumPrice } from "utils"

import { MedItem } from "../../types"

const MedicationForm = ({ isEditing }: Props) => {
  const {
    values: { fee, price: basePrice, referencePrices, mk },
  } = useFormikContext<MedItem>()
  const packaging = mk?.packaging?.type?.coding?.[0]?.display
  const doseForm = codeableConceptAsString(mk.doseForm)

  return (
    <div className="divide-gray-200 divide-y pt-1.5">
      <div className="p-fluid grid gap-2">
        <InputField field="display" placeholder="Add a name" disabled={isEditing} />
      </div>
      {!!referencePrices.length && (
        <div className="flex flex-col gap-1 pt-2 space-y-1 text-sm">
          <span className="font-semibold pb-1">{`${packaging} Price:`}</span>
          <div className="flex items-center divide-x-2 flex-wrap space-x-2">
            {referencePrices.map(({ qty, price }, index) => (
              <div key={qty} className={classNames({ "pl-2": !!index })}>
                {[`${qty} ${doseForm}`, `$${price?.toFixed(2)}`].join(" - ")}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-center flex-col flex-1 space-y-3 mt-6">
        <div className="pt-2 flex justify-between items-center text-sm mb-2">
          <span className="font-semibold">Subtotal</span>
          <p className="font-semibold">${basePrice.toFixed(2)}</p>
        </div>
        <div className="divide-gray-200 divide-y flex justify-center flex-col flex-1 space-y-3">
          <div className="pb-1 flex justify-between items-center">
            <span className="font-semibold text-sm">Fee</span>
            <NumberField field="fee" horizontal min={0} mode="currency" />
          </div>
          <div className="pt-2 flex justify-between">
            <span className="font-semibold text-sm">Total</span>
            <span className="font-semibold text-sm">${sumPrice(basePrice, fee ?? 0).sum.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

type Props = {
  isEditing?: boolean
  isLoadingData?: boolean
}

export { MedicationForm }
