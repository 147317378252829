import { faExclamationTriangle, faTimesCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Reference } from "fhir"
import { FormikValues, useFormikContext } from "formik"
import { Button } from "primereact/button"
import { classNames } from "primereact/utils"
import { FC, useState } from "react"

import {
  AddressField,
  BirthdateField,
  CheckBoxField,
  EmailField,
  GenderField,
  InputField,
  PhoneField,
  ReferenceDropdownField,
  useAddressContext,
} from "commons"
import pluralize from "pluralize"

import { PatientConsents } from "./PatientConsents"

enum OPTIONAL_FIELDS {
  ADDRESS = "address",
  GENDER = "gender",
}

const PatientForm: FC<Props> = ({ practitioners, toggleValidateAddress }: Props) => {
  const [optionalFields, setOptionalFields] = useState<Array<OptionalField>>([])
  const { setValues, initialValues, values, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>()

  const addField = (field: OptionalField, fieldPath?: string, initialFieldValue?: unknown) => {
    setOptionalFields([...optionalFields, field])
    if (initialFieldValue && fieldPath) {
      setFieldValue(fieldPath, initialFieldValue)
      setFieldTouched(fieldPath, true)
    }
  }

  const removeField = (fieldName: OPTIONAL_FIELDS) => {
    if (fieldName === OPTIONAL_FIELDS.ADDRESS) {
      toggleValidateAddress()
    }

    setOptionalFields(optionalFields.filter(({ name }) => name !== fieldName))
    setValues({ ...values, [fieldName]: initialValues[fieldName] })
  }

  const getButtonHidden = (field: OPTIONAL_FIELDS) =>
    optionalFields.findIndex((optional) => optional.name === field) !== -1

  const { recommendations } = useAddressContext()

  const address: OptionalField = {
    name: OPTIONAL_FIELDS.ADDRESS,
    component: (_, onRemove) => (
      <div className="flex items-center">
        <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-1 mb-3 flex-1">
          <AddressField parentFieldName="patient.address[0]" />
        </fieldset>
        <Button
          type="button"
          title="Remove field"
          icon={<FontAwesomeIcon icon={faTimesCircle} className="text-[20px]" />}
          className="p-button-text p-button-rounded p-button-sm"
          onClick={onRemove}
        />
      </div>
    ),
  }

  return (
    <>
      <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-3">
        <legend>Contact Information</legend>
        <InputField field="patient.name[0].given[0]" label="First Name" />
        <InputField field="patient.name[0].family" label="Last Name" />
        <EmailField
          field="patient.telecom[0].value"
          label="Email Address"
          className="col-span-2"
          initialValue={initialValues.telecom?.[0].value}
          validateDuplicate={true}
        />
        <PhoneField unmask={true} field="patient.telecom[1].value" label="Phone" className="col-span-2" />
        <BirthdateField field="patient.birthDate" label="Date of Birth" className="col-span-2" />
        <GenderField field="patient.gender" label="Biological Sex" className="col-span-2" />
        <ReferenceDropdownField
          field="patient.generalPractitioner[0]"
          label="Practitioner"
          className="col-span-2"
          options={practitioners}
          validation={(value) => (!value?.id ? "Practitioner is required" : undefined)}
        />
        {initialValues.id && !values.generalPractitioner?.[0].id && (
          <div className="text-sm text-red-400">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span className="ml-1">Missing general practitioner</span>
          </div>
        )}
      </fieldset>

      <div className="px-3 mb-12">
        <div className="mb-3">Additional information</div>
        {optionalFields.map((optional) => {
          const isAddressComponent = optional.name === OPTIONAL_FIELDS.ADDRESS
          return (
            <>
              {!isAddressComponent && optional.component(optional.name, () => removeField(optional.name))}
              {isAddressComponent && (
                <div className="flex flex-col justify-between divide-y divide-gray-300" key={optional.name}>
                  {optional.component(optional.name, () => removeField(optional.name))}
                  {recommendations &&
                    (recommendations.missingTypes.length > 0 ||
                      recommendations.fixableTypes.length > 0 ||
                      recommendations.wrongTypes.length > 0) && (
                      <div className="relative w-full flex flex-col justify-between mt-3 p-3">
                        <h3 className="text-sm font-semibold">Recommendations:</h3>
                        {recommendations.missingTypes.length > 0 && (
                          <p className="text-gray-600">
                            - Missing {pluralize("part", recommendations.missingTypes.length)} in address:
                            <span className="text-red-400"> {recommendations.missingTypes.join(" , ")}</span>
                          </p>
                        )}
                        {recommendations.wrongTypes.length > 0 && (
                          <p className="text-gray-600">
                            - Wrong {pluralize("value", recommendations.wrongTypes.length)} provided in:
                            <span className="text-red-400"> {recommendations.wrongTypes.join(" , ")}</span>
                          </p>
                        )}
                        {recommendations.fixableTypes.length > 0 && (
                          <>
                            <p className="text-gray-600">
                              - Possible {pluralize("fix", recommendations?.fixableTypes.length)} in address:
                            </p>
                            {recommendations.fixableTypes?.map(({ componentType, value }) => (
                              <p key={componentType} className="text-gray-600 ml-4">
                                - {componentType} could be: <span className="text-gray-600 font-semibold">{value}</span>
                              </p>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                </div>
              )}
            </>
          )
        })}

        <Button
          type="button"
          label="+ Address"
          className={classNames("p-button-rounded p-button-outlined p-button-sm max-w-fit h-6 mr-2 mb-3", {
            hidden: getButtonHidden(OPTIONAL_FIELDS.ADDRESS),
          })}
          onClick={() => {
            toggleValidateAddress()
            addField(address, "patient.address[0]", { ...initialValues?.patient?.address?.[0], type: "home" })
          }}
        />
      </div>
      <PatientConsents field="consents" label="Consents" />
      <div className="px-3">
        <CheckBoxField field="invite" label="Invite patient to the platform" />
      </div>
    </>
  )
}

type OptionalField = {
  name: OPTIONAL_FIELDS
  component(key: string, onRemove: () => void): JSX.Element
}

type Props = {
  showBirthDate?: boolean
  practitioners: Reference[]
  toggleValidateAddress(): void
}

export { PatientForm }
