import pluralize from "pluralize"
import { FC, useState } from "react"
import { FormikValues } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"

import { FormField } from "commons"

import { DoseHandlerModal } from "./DoseHandlerModal"

const BodySitesAndDosageField: FC<Props> = ({
  bodySites,
  field,
  dosages,
  modalTitle,
  doseUnit,
  maxDosage,
  validation,
  onUpdateDosage,
}) => {
  const [showModal, setShowModal] = useState(false)
  const onHide = () => setShowModal(false)

  return (
    <>
      <FormField field={field} validation={validation} showInvalidState>
        <div
          className={classNames(
            "text-sm p-2 bg-gray-200 rounded inline-flex flex-1 items-center",
            dosages?.length ? "justify-between" : "justify-center",
          )}
        >
          <p>{`${bodySites.length} selected ${pluralize("point", bodySites.length)}`}</p>
          {!!dosages?.length && (
            <FontAwesomeIcon
              icon={faPencil}
              className="text-primary hover:text-primary-hover cursor-pointer"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
      </FormField>
      {!!dosages?.length && showModal && (
        <DoseHandlerModal
          key={dosages.join("|")}
          modalTitle={modalTitle}
          onHide={onHide}
          dosages={dosages}
          doseUnit={doseUnit}
          maxDosage={maxDosage}
          onUpdateDosage={(newDosage) => {
            onUpdateDosage(newDosage)
            onHide()
          }}
        />
      )}
    </>
  )
}

type Props = {
  bodySites: string[]
  field: string
  validation?(value: string | number | FormikValues): void
  doseUnit?: string
  modalTitle?: string
} & DosageProps

type DosageProps =
  | {
      dosages: string[]
      maxDosage: number
      onUpdateDosage(updatedDosage: number[]): void
    }
  | {
      dosages: undefined
      maxDosage?: number
      onUpdateDosage?(updatedDosage: number[]): void
    }

export { BodySitesAndDosageField }
