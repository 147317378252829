import { createContext, PropsWithChildren } from "react"
import { asReference, Reference, Patient } from "fhir"

const PatientContext = createContext<PatientContextState | undefined>(undefined)
PatientContext.displayName = "PatientContext"

const PatientContextProvider = ({ patient, hasB2CUser, refreshPatientData, children }: PropsWithChildren<Props>) => {
  if (!patient?.id) {
    throw new Error("Invalid patient id")
  }

  const data = {
    patientId: patient.id,
    patient,
    hasB2CUser,
    patientRef: asReference(patient),
    refreshPatientData,
  }

  return <PatientContext.Provider value={data}>{children}</PatientContext.Provider>
}

type Props = {
  patient: Patient
  hasB2CUser?: boolean
  refreshPatientData(): void
}

type PatientContextState = Props & {
  patientId: string
  patientRef: Reference
}

export { PatientContextProvider, PatientContext }
