import {
  Appointment,
  codeableConceptAsString,
  humanNameAsString,
  Invoice,
  isAppointment,
  isInvoice,
  isQuestionnaireResponse,
  isServiceRequest,
  Reference,
  ServiceRequest,
} from "fhir"
import { classNames } from "primereact/utils"
import { useCallback, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { appointmentModelBuilder } from "appointments"
import {
  Badge,
  ConfirmDialog,
  ModulesId,
  QuestionnaireData,
  SimpleBreadCrumb,
  SkeletonLoader,
  StackedListContainer,
  StackedListItem,
  useSendToPatient,
} from "commons"
import { isLabOrder, isMedicationOrder, taskPriorityCodes } from "data"
import { invoiceModelBuilder } from "invoices"
import { medicationModelBuilder } from "medication-requests"
import { useOrganizationContext } from "organization"
import { surveyModelBuilder } from "surveys"

import { useTask } from "../hooks"
import { getTaskDate } from "../utils"
import { orderModelBuilder } from "./orderModelBuilder"
import { taskModelBuilder } from "./taskModelBuilder"

const TaskFocusResources = ["Invoice", "ServiceRequest", "QuestionnaireResponse", "Appointment"]

const TaskDetailView = () => {
  const [params, setParams] = useSearchParams()
  const { currentOrganizationId } = useOrganizationContext()
  const navigate = useNavigate()
  const idTask = params.get("id")
  const { sendOrderToPatient, isSendingToPatient } = useSendToPatient()
  const [invoiceToSend, setInvoiceToSend] = useState("")

  const { taskData, isLoading } = useTask(idTask as string)

  const backToTasks = () => {
    params.delete("id")
    setParams(params)
  }

  const showTask = (taskId: string) => {
    params.set("id", taskId)
    setParams(params)
  }

  const referencedPatientId = useMemo(
    () => taskData.patient?.id ?? taskData.invoice?.subject?.id ?? taskData.task?.for?.id,
    [taskData],
  )

  const showInvoice = useCallback(
    () =>
      navigate(
        `/orgs/${currentOrganizationId}/patients/${referencedPatientId}?view=invoice&invoiceId=${taskData.invoice?.id}`,
      ),
    [currentOrganizationId, navigate, taskData.invoice?.id, referencedPatientId],
  )

  const sendToPatientInvoice = (invoiceId: string) => {
    setInvoiceToSend(invoiceId)
  }

  const showOrder = useCallback(
    (order: ServiceRequest) => {
      if (isMedicationOrder(order))
        navigate(
          `/orgs/${currentOrganizationId}/patients/${referencedPatientId}?view=${ModulesId.MEDICATIONR}&subview=${
            taskData.order?.status === "active" ? "order" : "history"
          }&order=${taskData.order?.id}`,
        )
      else if (isLabOrder(order))
        navigate(`/orgs/${currentOrganizationId}/patients/${referencedPatientId}?view=labs&order=${taskData.order?.id}`)
    },
    [currentOrganizationId, navigate, taskData.order?.id, taskData.order?.status, referencedPatientId],
  )

  const showQR = useCallback(
    () =>
      navigate(
        `/orgs/${currentOrganizationId}/patients/${referencedPatientId}?view=survey&qrId=${taskData.questionnaireData?.qResponse?.id}`,
      ),
    [currentOrganizationId, navigate, taskData.questionnaireData?.qResponse?.id, referencedPatientId],
  )

  const getPriorityColor = (priority: string) =>
    priority === taskPriorityCodes[3].code
      ? "red"
      : priority === taskPriorityCodes[2].code
        ? "yellow"
        : priority === taskPriorityCodes[1].code
          ? "blue"
          : priority === taskPriorityCodes[0].code
            ? "green"
            : "gray"

  const getStatusColor = (status: string) => {
    return {
      "text-blue-400": status === "ready",
      "text-slate-400": status === "requested",
      "text-orange-400": status === "on-hold",
      "text-red-600": status === "entered-in-error",
      "text-green-400": status === "completed",
      "text-red-400": status === "cancelled",
    }
  }

  const asignedTo = useMemo(() => {
    const asigned = taskData?.task?.for?.resourceType === "Practitioner" ? taskData.practitioner : taskData?.patient
    return { name: humanNameAsString(asigned?.name?.[0]), photo: asigned?.photo?.[0].url }
  }, [taskData?.patient, taskData?.practitioner, taskData?.task?.for])

  const getModelData = useCallback(
    (focus: Reference) => {
      switch (true) {
        case isInvoice(focus):
          return invoiceModelBuilder({
            invoice: taskData.invoice as Invoice,
            showInvoice,
            showAsExternal: false,
            sendToPatientInvoice: () => sendToPatientInvoice(taskData.invoice?.id as string),
          })
        case isServiceRequest(focus):
          return orderModelBuilder(taskData.order as ServiceRequest, () => showOrder(taskData.order as ServiceRequest))
        case isQuestionnaireResponse(focus):
          return surveyModelBuilder({
            item: taskData.questionnaireData as QuestionnaireData,
            onClick: showQR,
            showAsExternal: true,
          })
        case isAppointment(focus):
          return appointmentModelBuilder(taskData.appointment as Appointment)
      }

      return {}
    },
    [
      showInvoice,
      showOrder,
      showQR,
      taskData.appointment,
      taskData.invoice,
      taskData.order,
      taskData.questionnaireData,
    ],
  )

  const getFocusLabel = useCallback((focus: Reference) => {
    switch (true) {
      case isInvoice(focus):
        return "Invoice:"
      case isServiceRequest(focus):
        return "Order:"
      case isQuestionnaireResponse(focus):
        return "Questionnaires:"
      case isAppointment(focus):
        return "Appointment:"
    }

    return ""
  }, [])

  return (
    <>
      <div className="px-6 py-3 border-b drop-shadow">
        <h6 className="font-medium text-lg pb-6">Tasks</h6>
      </div>

      <div className="flex flex-col pt-8 pl-8 overflow-hidden">
        <SimpleBreadCrumb items={[{ label: "Task Details" }]} goHome={backToTasks} />
        <div className="flex-1 m-10 text-slate-700 overflow-y-auto">
          {isLoading ? (
            <SkeletonLoader repeats={4} loaderType="one-line" />
          ) : !taskData?.task ? (
            <span>Sorry, the requested task was not found</span>
          ) : (
            <>
              <div className="flex items-center">
                <span>
                  <span className="font-semibold mr-1">Code:</span>
                  {codeableConceptAsString(taskData.task.code)},
                </span>
                <span className="mx-2 font-semibold">Date:</span>
                {getTaskDate(taskData.task)}
                {taskData.task.status && (
                  <span className="font-semibold">
                    <span className="mx-2">Status:</span>
                    <span className={classNames(getStatusColor(taskData.task.status))}>{taskData.task.status}</span>
                  </span>
                )}
                {taskData.task.priority && (
                  <span title="Priority" className="ml-4">
                    <Badge text={taskData.task.priority} colorStyle={getPriorityColor(taskData.task.priority)} />
                  </span>
                )}
              </div>

              <div className="my-6">
                <div className="font-semibold mb-3">
                  {taskData.task.for?.resourceType === "Patient" ? "For:" : "Asigned to:"}
                </div>
                {asignedTo.name && (
                  <span className="text-sm ml-2">
                    <span>{asignedTo.name}</span>
                  </span>
                )}
              </div>

              {taskData.task.performerType && (
                <div className="my-6">
                  <div className="mb-3  w-full font-semibold">Performer type:</div>
                  {taskData.task.performerType?.map((perfType) => (
                    <span
                      key={`${perfType.id}-${idTask}`}
                      className="border text-sm px-3 font-semibold text-slate-500 py-1 mr-2 rounded-full"
                    >
                      {perfType.coding?.[0].display}
                    </span>
                  ))}
                </div>
              )}

              <div className="my-6">
                <div className="mb-3  w-full font-semibold">Description:</div>
                <div className="ml-2 text-sm">{taskData.task.description}</div>
              </div>

              {taskData.dependsOn && taskData.dependsOn.length > 0 && (
                <div className="my-6 w-[40rem]">
                  <div className="font-semibold">Depends On:</div>
                  <StackedListContainer
                    itemPadding
                    data={taskData.dependsOn}
                    itemModelBuilder={(task) => taskModelBuilder(task, () => showTask(task.id as string))}
                  />
                </div>
              )}

              {taskData.task.focus &&
                TaskFocusResources.includes(taskData.task.focus?.resourceType as string) &&
                (taskData.invoice || taskData.order || taskData.questionnaireData) && (
                  <div className="my-6 w-[40rem]">
                    <div className="font-semibold">{getFocusLabel(taskData.task.focus)}</div>
                    <ul className="@container">
                      <StackedListItem itemPadding rowHover modelData={getModelData(taskData.task.focus)} />
                    </ul>
                  </div>
                )}

              {taskData.order &&
                isMedicationOrder(taskData.order as ServiceRequest) &&
                taskData.medicationRequests &&
                taskData.medicationRequests.length > 0 && (
                  <div className="my-6 w-[40rem]">
                    <div className="font-semibold">Based On:</div>
                    <StackedListContainer
                      itemPadding
                      data={taskData.medicationRequests}
                      itemModelBuilder={(mr) => medicationModelBuilder(mr)}
                    />
                  </div>
                )}

              {taskData.order &&
                isLabOrder(taskData.order as ServiceRequest) &&
                taskData.serviceRequests &&
                taskData.serviceRequests.length > 0 && (
                  <div className="my-6 w-[40rem]">
                    <div className="font-semibold">Based On:</div>
                    <StackedListContainer
                      itemPadding
                      data={taskData.serviceRequests}
                      itemModelBuilder={(sr) => ({
                        leftData: [{ lineItems: [{ name: "Test", value: codeableConceptAsString(sr.code) }] }],
                      })}
                    />
                  </div>
                )}

              {taskData.task.note?.[0].text && (
                <div className="my-6">
                  <div className="mb-3  w-full font-semibold">Note:</div>
                  <div className="ml-2 text-sm">{taskData.task.note?.[0].text}</div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ConfirmDialog
        confirmText="Are you sure you want to send this invoice to the patient?"
        actionName="Send"
        visible={!!invoiceToSend || isSendingToPatient}
        isLoading={isSendingToPatient}
        onConfirm={() => {
          sendOrderToPatient(invoiceToSend)
        }}
        hideDialog={() => {
          setInvoiceToSend("")
        }}
      />
    </>
  )
}

export { TaskDetailView }
