import * as Yup from "yup"

import { CodeableConcept, Condition, Reference } from "fhir"

const getInitialValues = (patientRef: Reference, encounter?: Reference): Condition => ({
  clinicalStatus: undefined,
  verificationStatus: undefined,
  category: [{ coding: undefined }],
  severity: undefined,
  code: { coding: undefined },
  recordedDate: new Date().toISOString(),
  subject: patientRef,
  note: [{ text: "" }],
  encounter,
})

const conditionValidationSchema = Yup.object().shape({
  recordedDate: Yup.date().typeError("Recorded date is required").required("Recorded date is required"),
  subject: Yup.object().required("Condition subject is required"),
  code: Yup.object().test(
    "test-codeable-concept",
    "Code is required",
    (value: CodeableConcept) => value?.coding !== undefined,
  ),
  severity: Yup.object().required("Severity is required"),
  category: Yup.array()
    .of(
      Yup.object().test("test-first-item", "Category is required", (value: CodeableConcept | undefined, context) => {
        return context?.path === "category[0]" ? value !== undefined && value?.coding !== undefined : true
      }),
    )
    .defined(),
  clinicalStatus: Yup.object().required("Clinical status is required"),
})

const sanitize = ({ ...condition }: Condition) => {
  if (!condition.note?.[0]?.text) {
    delete condition.note
  }

  if (!condition.category?.[0]?.coding?.[0].code) {
    delete condition.category
  }

  return condition
}

export { conditionValidationSchema, getInitialValues, sanitize }
