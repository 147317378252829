import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AllergyIntolerance } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { allergiesQueryKeys } from "../query-keys"

const useUpdateAllergy = (onSettled: () => void, onSuccess?: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updatedAllergy = async (allergy: AllergyIntolerance) =>
    update("AllergyIntolerance", allergy.id as string, allergy)

  const { mutate: updateAllergy, isPending: isUpdating } = useMutation({
    mutationFn: updatedAllergy,
    onSuccess: async (_, { patient }) => {
      queryClient.refetchQueries({ queryKey: allergiesQueryKeys.list(patient.id as string), type: "all" })
      onSuccess?.()
      displayNotificationSuccess("Allergy updated successfully!")
      datadogLogs.logger.info(`Allergy on ${patient.display} updated successfully!`)
    },
    onSettled,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({
          queryKey: allergiesQueryKeys.list(context.patient.id as string),
          type: "all",
        })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updateAllergy, isUpdating }
}

export { useUpdateAllergy }
