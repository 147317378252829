import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { usePatientContext } from "patients"

import { socialHistoryQueryKeys } from "../query-keys"

const useDeleteSocialHistory = (hideDialog?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const deleteSocialHistory = async (socialHistoryId: string) => remove("Observation", socialHistoryId)

  const { mutate: removeSocialHistory, isPending: isDeleting } = useMutation({
    mutationFn: deleteSocialHistory,
    onError: (error: CustomError, socialHistory) => {
      displayNotificationError(registerErrorTrace(error, { socialHistory }))
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: socialHistoryQueryKeys.withPatientId(patientId),
        refetchType: "all",
      })
      displayNotificationSuccess("Social history deleted successfully!")
      datadogLogs.logger.info("Social history deleted successfully!", { data })
    },
    onSettled: hideDialog,
  })

  return { removeSocialHistory, isDeleting }
}

export { useDeleteSocialHistory }
