import { PropsWithChildren } from "react"
import { Dialog, DialogProps } from "primereact/dialog"

import { Button } from "./Buttons"
import { DialogAppendToType } from "../types"

const ModalDialog = ({
  visible,
  title,
  appendTo,
  showCancel,
  showAccept,
  showButtons,
  showCloseIcon = !showButtons && !showCancel,
  acceptType,
  cancelLabel = "Close",
  acceptLabel = "Accept",
  acceptLoading,
  acceptDisabled,
  cancelDisabled,
  onAccept,
  onCancel,
  children,
  onHide,
  header,
  ...dialogProps
}: PropsWithChildren<Props>) => {
  return (
    <Dialog
      {...dialogProps}
      modal
      visible={visible}
      appendTo={appendTo}
      onHide={onHide ?? onCancel}
      className="paddingless w-full sm:w-4/5 md:w-2/3 lg:w-[36rem]"
      header={header ?? title}
      closable={showCloseIcon}
    >
      <div className="flex flex-col flex-1">
        <div className="bg-white divide-gray-200 divide-y flex flex-col flex-1 overflow-hidden">
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className="overflow-y-auto p-2 pt-0 sm:px-4 sm:pb-4 space-y-4">{children}</div>
          </div>
          {(showCancel || showAccept || showButtons) && (
            <div className="flex flex-shrink-0 justify-end px-2 py-4 sm:px-4 space-x-3">
              {(showButtons || showCancel) && (
                <Button
                  label={cancelLabel}
                  buttonStyle="default"
                  size="xl"
                  disabled={cancelDisabled}
                  onClick={onCancel}
                />
              )}
              {(showButtons || showAccept) && (
                <Button
                  label={acceptLabel}
                  type={acceptType}
                  size="xl"
                  loading={acceptLoading}
                  disabled={acceptDisabled}
                  onClick={onAccept}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

type Props = {
  visible: boolean
  title?: string
  showCloseIcon?: boolean
  appendTo?: DialogAppendToType
  showCancel?: boolean
  showAccept?: boolean
  showButtons?: boolean
  acceptType?: "button" | "submit"
  cancelLabel?: string
  acceptLabel?: string
  acceptLoading?: boolean
  acceptDisabled?: boolean
  cancelDisabled?: boolean
  onAccept?(): void
  onCancel(): void
  onHide?(): void
} & Omit<DialogProps, "onHide">

export { ModalDialog }
