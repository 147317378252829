import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { laboratoryQueryKeys } from "../query-keys"

const useDeleteLab = (hideDialog?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const deleteLab = async (labId: string) => remove("DiagnosticReport", labId)

  const { mutate: removeLab, isPending: isDeleting } = useMutation({
    mutationFn: deleteLab,
    onError: (error: CustomError, labId) => {
      displayNotificationError(registerErrorTrace(error, { labId }))
    },
    onSuccess: async (data) => {
      queryClient.refetchQueries({ queryKey: laboratoryQueryKeys.list(patientId), type: "all" })
      displayNotificationSuccess("Laboratory deleted successfully!")
      datadogLogs.logger.info("Laboratory deleted successfully!", { data })
    },
    onSettled: hideDialog,
  })

  return { removeLab, isDeleting }
}

export { useDeleteLab }
