import { createContext, Dispatch, SetStateAction, useContext } from "react"

const useStepFormContext = () => {
  const context = useContext(StepFormContext)

  if (!context) throw new Error("useStepFormContext must be used withing StepFormContext")

  return context
}

const StepFormContext = createContext<ContextState>({
  hiddenSteps: undefined,
  optionalSteps: undefined,
})

StepFormContext.displayName = "StepFormContext"

type ContextState = {
  activeStep?: number
  hiddenSteps?: string[]
  setHiddenSteps?: Dispatch<SetStateAction<string[] | undefined>>
  isHiddenStep?(stepId: string): boolean
  toogleHideStep?(stepId: string, hidden?: boolean): void
  setActiveStep?: Dispatch<SetStateAction<number>>
  optionalSteps?: string[]
  setOptionalSteps?: Dispatch<SetStateAction<string[]>>
  toogleOptionalStep?({ stepId, optional }: { stepId: string; optional?: boolean }): void
  isOptionalStep?(stepId: string): boolean
}

export { StepFormContext, useStepFormContext }
