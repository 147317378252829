import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address } from "fhir"
import { ErrorMessage, useFormikContext } from "formik"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { useMemo, useState } from "react"
import { AnyObject } from "yup/lib/object"

import {
  AddFieldArrayItemButton,
  AddressField,
  addressTypes,
  CardListItem,
  DialogFormContainer,
  sanitizeCustomAddress,
} from "commons"
import { emptyAddress } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { areAddressesEquals, getAddressSchema, getStringAddress, isPoBoxAddress } from "utils"

import { MedicationRequestFormData } from "../../types"

const PrescriptionShippingAddressField = ({ fieldName, showAdd = true, onChange }: Props) => {
  const { values, setFieldValue } = useFormikContext<MedicationRequestFormData>()
  const { patient } = usePatientContext()
  const { currentOrganizationBillingAddress: organizationAddress } = useOrganizationContext()

  const [showFormDialog, setShowFormDialog] = useState(false)

  const patientPermanentAddress = patient.address && patient.address.filter((address) => address.use !== "temp")

  const shippingAddress = values.dispenseRequest?.shippingAddress
  const isPatientAddress = useMemo(
    () =>
      shippingAddress &&
      patientPermanentAddress &&
      patientPermanentAddress.some((address) => areAddressesEquals(shippingAddress, address as Address)),
    [shippingAddress, patientPermanentAddress],
  )
  const isOrganizationAddress = useMemo(
    () => shippingAddress && organizationAddress && areAddressesEquals(shippingAddress, organizationAddress),
    [shippingAddress, organizationAddress],
  )
  const isCustomAddress = !isPatientAddress && !isOrganizationAddress && !!shippingAddress
  const [customAddress, setCustomAddress] = useState(isCustomAddress ? shippingAddress : undefined)
  const showAddCustomAddressButton = !customAddress && showAdd
  const postalAddressType = addressTypes.find(({ code }) => code === "postal")
  const addressFormInitialValue = isCustomAddress ? shippingAddress : { ...emptyAddress, type: postalAddressType }

  const handleAddressSelection = (address: Address) => {
    if (!areAddressesEquals(shippingAddress ?? {}, address)) {
      setFieldValue(fieldName, address)

      onChange?.(address)
    }
  }

  const showDialog = () => {
    setShowFormDialog(true)
  }

  const hideDialog = () => {
    setShowFormDialog(false)
  }

  const handleSubmit = (address?: Address) => {
    if (address) {
      const sanitizedAddress = sanitizeCustomAddress(address)
      setCustomAddress(sanitizedAddress)
      handleAddressSelection(sanitizedAddress)
      hideDialog()
    }
  }

  const renderAddressItem = (address: Address) => (
    <div className="font-semibold mx-4 w-full items-center">
      {isPoBoxAddress(address) && (
        <>
          <Tooltip
            target=".tooltiped"
            event="hover"
            position="left"
            content="It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be suitable for receiving deliveries"
          />
          <span className="cursor-pointer tooltiped mr-4">
            <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
          </span>
        </>
      )}
      {getStringAddress(address)}
    </div>
  )

  return (
    <div className="relative flex flex-col gap-2 pb-4 grow">
      <label htmlFor={fieldName} className="text-sm font-medium text-gray-700">
        Shipping address
      </label>
      {patientPermanentAddress &&
        patientPermanentAddress.map((patientAddress, index) => (
          <CardListItem
            className={classNames("cursor-pointer border border-gray-100 rounded-md hover:bg-gray-100", {
              "bg-slate-100 border-slate-400": !!shippingAddress && areAddressesEquals(shippingAddress, patientAddress),
            })}
            contentHeader={renderAddressItem(patientAddress)}
            key={patientAddress.id ?? index}
            onContentCliked={() => {
              handleAddressSelection(patientAddress)
            }}
          />
        ))}
      {organizationAddress && (
        <CardListItem
          className={classNames("cursor-pointer border border-gray-100 rounded-md hover:bg-gray-100", {
            "bg-slate-100 border-slate-400": isOrganizationAddress,
          })}
          contentHeader={renderAddressItem(organizationAddress)}
          onContentCliked={() => {
            handleAddressSelection(organizationAddress)
          }}
        />
      )}
      {customAddress && (
        <CardListItem
          className={classNames("cursor-pointer border border-gray-100 rounded-md hover:bg-gray-100", {
            "bg-slate-100 border-slate-400": isCustomAddress,
          })}
          contentHeader={renderAddressItem(customAddress)}
          onContentCliked={() => {
            handleAddressSelection(customAddress)
          }}
          actionItem={{
            icon: <FontAwesomeIcon icon={faPencil} size="sm" />,
            command: () => {
              handleAddressSelection(customAddress)
              showDialog()
            },
          }}
        />
      )}
      {showAddCustomAddressButton && (
        <AddFieldArrayItemButton className="border-none mt-2" label="Add custom address" onClick={showDialog} />
      )}
      <ErrorMessage name={fieldName}>
        {(msg) => (
          <small id={`errorMessage.${fieldName}`} className="p-error absolute -bottom-5">
            {msg}
          </small>
        )}
      </ErrorMessage>
      <DialogFormContainer
        initialValue={addressFormInitialValue}
        title="Custom Address"
        showForm={showFormDialog}
        onCancel={() => {
          hideDialog()
        }}
        useFormik
        onSubmit={handleSubmit}
        validationSchema={getAddressSchema()}
      >
        <AddressFieldWithValidation />
      </DialogFormContainer>
    </div>
  )
}

const AddressFieldWithValidation = () => {
  /* const { recommendations } = useAddressContext() */

  return (
    <div className="flex flex-col justify-between divide-y divide-gray-300">
      <div className="relative p-fluid grid gap-4">
        <fieldset className="relative p-fluid grid grid-cols-2 gap-4">
          <AddressField showTypeUseField={false} />
        </fieldset>
      </div>
      {/* {recommendations &&
        (recommendations.missingTypes.length > 0 ||
          recommendations.fixableTypes.length > 0 ||
          recommendations.wrongTypes.length > 0) && (
          <div className="relative w-full flex flex-col justify-between mt-3 p-3">
            <h3 className="text-sm font-semibold">Recommendations:</h3>
            {recommendations.missingTypes.length > 0 && (
              <p className="text-gray-600">
                - Missing {pluralize("part", recommendations.missingTypes.length)} in address:
                <span className="text-red-400"> {recommendations.missingTypes.join(" , ")}</span>
              </p>
            )}
            {recommendations.wrongTypes.length > 0 && (
              <p className="text-gray-600">
                - Wrong {pluralize("value", recommendations.wrongTypes.length)} provided in:
                <span className="text-red-400"> {recommendations.wrongTypes.join(" , ")}</span>
              </p>
            )}
            {recommendations.fixableTypes.length > 0 && (
              <>
                <p className="text-gray-600">
                  - Possible {pluralize("fix", recommendations?.fixableTypes.length)} in address:
                </p>
                {recommendations.fixableTypes?.map(({ componentType, value }) => (
                  <p key={componentType} className="text-gray-600 ml-4">
                    - {componentType} could be: <span className="text-gray-600 font-semibold">{value}</span>
                  </p>
                ))}
              </>
            )}
          </div>
        )} */}
    </div>
  )
}

type Props = {
  fieldName: string
  showAdd?: boolean
  onChange?(value: AnyObject): void
}

export { PrescriptionShippingAddressField }
