import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { familyHistoryQueryKeys } from "../query-keys"

const useDeleteFamilyHistory = (patientId: string, hideDialog?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const deleteFamilyHistory = async (famHistoryId: string) => remove("FamilyMemberHistory", famHistoryId)

  const { mutate: removeFamilyHistory, isPending: isDeleting } = useMutation({
    mutationFn: deleteFamilyHistory,
    onError: (error: CustomError, famHistoryId) => {
      displayNotificationError(registerErrorTrace(error, { familyHistoryId: famHistoryId }))
    },
    onSuccess: async (data) => {
      queryClient.refetchQueries({ queryKey: familyHistoryQueryKeys.history(patientId), type: "all" })
      displayNotificationSuccess("Family history deleted successfully!")
      datadogLogs.logger.info("Family history deleted successfully!", { data })
    },
    onSettled: hideDialog,
  })

  return { removeFamilyHistory, isDeleting }
}

export { useDeleteFamilyHistory }
