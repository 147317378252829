import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { conditionsQueryKeys } from "../query-keys"

const useDeleteCondition = (hideDialog?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const deleteCondition = async (conditionId: string) => remove("Condition", conditionId)

  const { mutate: removeCondition, isPending: isDeleting } = useMutation({
    mutationFn: deleteCondition,
    onError: (error: CustomError, conditionId) => {
      displayNotificationError(registerErrorTrace(error, { conditionId }))
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: conditionsQueryKeys.all })
      displayNotificationSuccess("Condition deleted successfully!")
      datadogLogs.logger.info("Condition deleted successfully!", { data })
    },
    onSettled: hideDialog,
  })

  return { removeCondition, isDeleting }
}

export { useDeleteCondition }
