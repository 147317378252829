import { FormikErrors, FormikValues } from "formik"

import { Coding, Identifier, ParametersParameterArrayArray, ParametersParameterArrayValue } from "fhir"
import { SYSTEM_VALUES } from "system-values"

import {
  AddressValidationDataAddress,
  AddressValidationDataConfirmationLevels,
  AddressValidationDataGranularityLevels,
  AddressValidationResultData,
  ComponentType,
  EffectiveComponentType,
  FieldErrorType,
  PractitionerInfo,
} from "./types"

const getParamsSkuCodesByQty = (codes: (Coding | ParametersParameterArrayValue)[]) => {
  const skuCodes = codes?.filter((code) =>
    code?.code
      ? (code as Coding)?.system === SYSTEM_VALUES.SKU
      : (code as ParametersParameterArrayValue)?.Coding?.system === SYSTEM_VALUES.SKU,
  )

  const uniqueIdentifiers = new Set<string>(
    skuCodes?.map((code) =>
      code?.code
        ? ((code as Coding).code as string)
        : `${(code as ParametersParameterArrayValue)?.Coding?.code}-${(code as ParametersParameterArrayValue)?.Quantity?.value}`,
    ),
  )

  const { groupedItems, itemsWithoutQty } = Array.from(uniqueIdentifiers).reduce(
    (acc, item) => {
      const effectiveItem = skuCodes.find((code) =>
        code?.code
          ? (code as Coding)?.code === item
          : `${(code as ParametersParameterArrayValue)?.Coding?.code}-${(code as ParametersParameterArrayValue)?.Quantity?.value}` ===
            item,
      )

      if ((effectiveItem as ParametersParameterArrayValue)?.Coding) {
        const currentItem = effectiveItem as ParametersParameterArrayValue
        const key = `${currentItem.Quantity?.value}`

        if (!acc.groupedItems[key]) {
          acc.groupedItems[key] = {
            name: "product-info",
            part: [
              {
                name: "code",
                value: {
                  Coding: currentItem.Coding,
                },
              },
              {
                name: "quantity",
                value: {
                  Quantity: {
                    value: currentItem.Quantity?.value,
                  },
                },
              },
            ],
          }
        } else {
          const currentPart = [...(acc.groupedItems[key]?.part ?? [])]
          acc.groupedItems[key].part = [
            {
              name: "code",
              value: {
                Coding: currentItem.Coding,
              },
            },
            ...currentPart,
          ]
        }
      } else {
        acc.itemsWithoutQty.push({
          name: "code",
          value: {
            Coding: effectiveItem as Coding,
          },
        })
      }
      return acc
    },
    {
      groupedItems: {} as { [key: string]: ParametersParameterArrayArray },
      itemsWithoutQty: [] as ParametersParameterArrayArray[],
    },
  )

  return [
    ...Object.values(groupedItems),
    ...(itemsWithoutQty.length ? [{ name: "product-info", part: itemsWithoutQty }] : []),
  ] as ParametersParameterArrayArray[]
}

const getPDSkuValue = (identifiers: Identifier[] | undefined) =>
  identifiers?.find((id) => id.system === SYSTEM_VALUES.SKU)?.value

const effectiveValidationAddressComponents: Record<ComponentType, string> = {
  route: "route",
  street_number: "street_number",
  locality: "locality",
  administrative_area: "administrative_area",
  postal_code: "postal_code",
  subpremise: "subpremise",
}

const effectivesAddressFormFieldsMapping = {
  line: {
    field: "line",
    message: ["This street number or route is suspicious"],
  },
  subpremise: {
    field: "line",
    message: ["", "This apartment or house number is suspicious"],
  },
  locality: {
    field: "city",
    message: "Wrong city",
  },
  administrative_area: {
    field: "state",
    message: "Wrong state",
  },
  postal_code: {
    field: "postalCode",
    message: "Wrong zip code",
  },
}

const getResultAbstractDataRepresentation = (result: AddressValidationResultData, isAddressWithSubpremise: boolean) => {
  const effectivesAddressComponents = getEffectivesAddressComponents(
    isAddressWithSubpremise
      ? result?.address?.addressComponents
      : result?.address?.addressComponents?.filter(
          ({ componentType }) => componentType !== effectiveValidationAddressComponents.subpremise,
        ),
  )
  const inferredAddressComponents = getInferredAddressComponents(effectivesAddressComponents)

  const effectiveUnconfirmedAddressComponentsTypes = effectivesAddressComponents
    ?.filter((component) => component.confirmationLevel.includes("UNCONFIRMED"))
    .flatMap((component) => component.componentType)

  const hasReplacedComponents = effectivesAddressComponents?.some((component) => component.replaced)

  const effectiveMissingAddressComponentsTypes = getEffectiveMissingAddressComponentsTypes(
    isAddressWithSubpremise
      ? result?.address?.missingComponentTypes
      : result?.address?.missingComponentTypes?.filter(
          (type) => type !== effectiveValidationAddressComponents.subpremise,
        ),
  )

  const isBypassUnconfirmedStreetNumber =
    (result?.address?.unconfirmedComponentTypes?.includes("street_number") ||
      result?.address?.missingComponentTypes?.includes("street_number")) &&
    effectiveUnconfirmedAddressComponentsTypes?.length === 0

  delete result?.verdict?.hasInferredComponents
  delete result?.verdict?.hasReplacedComponents
  delete result?.verdict?.hasUnconfirmedComponents

  const addressComplete =
    effectiveMissingAddressComponentsTypes?.length === undefined || effectiveMissingAddressComponentsTypes?.length === 0

  const effectiveVerdict = {
    ...result?.verdict,
    addressComplete: addressComplete,
    ...(effectiveUnconfirmedAddressComponentsTypes?.length && { hasUnconfirmedComponents: true }),
    ...(inferredAddressComponents?.length && { hasInferredComponents: true }),
    ...(hasReplacedComponents && { hasReplacedComponents: true }),
    validationGranularity:
      !!effectiveMissingAddressComponentsTypes?.length ||
      !!effectiveUnconfirmedAddressComponentsTypes?.length ||
      !!inferredAddressComponents?.length ||
      hasReplacedComponents ||
      !addressComplete ||
      !isBypassUnconfirmedStreetNumber
        ? result?.verdict?.validationGranularity
        : AddressValidationDataGranularityLevels.PREMISE,
  }

  delete result?.address?.missingComponentTypes
  delete result?.address?.unconfirmedComponentTypes

  const postalAddress = result?.address?.postalAddress
  const effectivePostalCode = postalAddress?.postalCode?.split("-")[0]
  const effectiveFormatedAddress = `${postalAddress?.addressLines?.join(" ")}, ${postalAddress?.locality}, ${postalAddress?.administrativeArea}, ${postalAddress?.regionCode}, ${effectivePostalCode}`

  const effectiveResult = {
    ...result,
    verdict: effectiveVerdict,
    address: {
      ...result?.address,
      addressComponents: effectivesAddressComponents,
      ...(effectiveMissingAddressComponentsTypes?.length && {
        missingComponentTypes: effectiveMissingAddressComponentsTypes,
      }),
      ...(effectiveUnconfirmedAddressComponentsTypes?.length && {
        unconfirmedComponentTypes: effectiveUnconfirmedAddressComponentsTypes,
      }),
      formattedAddress: effectiveFormatedAddress,
      postalAddress: {
        ...postalAddress,
        postalCode: effectivePostalCode,
      },
    },
  }

  return effectiveResult
}

const getEffectiveComponentType = (componentType: string) =>
  componentType.includes("administrative_area")
    ? effectiveValidationAddressComponents["administrative_area"]
    : componentType

const getEffectivesAddressComponents = (addressComponents: AddressValidationDataAddress["addressComponents"]) =>
  addressComponents?.reduce(
    (acc, component) => {
      const effectiveComponentType = getEffectiveComponentType(component.componentType)
      if (effectiveValidationAddressComponents[effectiveComponentType as ComponentType]) {
        return [
          ...(acc ?? []),
          {
            ...component,
            componentType: effectiveComponentType,
            confirmationLevel:
              component.confirmationLevel === AddressValidationDataConfirmationLevels.UNCONFIRMED_BUT_PLAUSIBLE &&
              component.componentType === "street_number"
                ? AddressValidationDataConfirmationLevels.CONFIRMED
                : component?.spellCorrected
                  ? AddressValidationDataConfirmationLevels.UNCONFIRMED_AND_SUSPICIOUS
                  : component.confirmationLevel,
          },
        ]
      }
      return acc
    },
    [] as AddressValidationDataAddress["addressComponents"],
  )

const getInferredAddressComponents = (addressComponents: AddressValidationDataAddress["addressComponents"]) =>
  addressComponents?.reduce(
    (inferredAddressComponents, component) => {
      if (component.inferred) {
        return [...(inferredAddressComponents ?? []), component]
      }

      return inferredAddressComponents
    },
    [] as AddressValidationDataAddress["addressComponents"],
  )

const getEffectiveMissingAddressComponentsTypes = (missingAddressComponentsTypes: string[] | undefined) =>
  missingAddressComponentsTypes?.reduce((missingAddressComponentsTypes, componentType) => {
    const effectiveComponentType = getEffectiveComponentType(componentType)

    if (effectiveValidationAddressComponents[effectiveComponentType as ComponentType]) {
      missingAddressComponentsTypes?.push(effectiveComponentType)
    }

    return missingAddressComponentsTypes
  }, [] as string[])

const isNeededToFixAddress = (verdict: AddressValidationResultData["verdict"]) => {
  if (
    verdict?.validationGranularity === AddressValidationDataGranularityLevels.OTHER ||
    !verdict?.addressComplete ||
    !!verdict?.hasReplacedComponents ||
    !!verdict?.hasUnconfirmedComponents
  ) {
    return true
  }

  return false
}

const isNeededToConfirmAddress = (verdict: AddressValidationResultData["verdict"]) => {
  if (
    verdict?.validationGranularity !== AddressValidationDataGranularityLevels.OTHER &&
    !!verdict?.addressComplete &&
    !!verdict?.hasInferredComponents
  ) {
    return true
  }

  return false
}

const isAcceptedAddress = (verdict: AddressValidationResultData["verdict"]) => {
  if (
    (verdict?.validationGranularity?.includes(AddressValidationDataGranularityLevels.PREMISE) ||
      verdict?.validationGranularity === AddressValidationDataGranularityLevels.GRANULARITY_UNSPECIFIED) &&
    ((!!verdict.addressComplete && !!verdict.hasReplacedComponents) ||
      ((verdict?.addressComplete === undefined || verdict.hasReplacedComponents === undefined) &&
        !verdict?.hasInferredComponents))
  ) {
    return true
  }

  return false
}

const getErrorField = ({
  effectiveComponentType,
  isMissing,
  missingComponentTypes,
  addressLines,
}: {
  effectiveComponentType: string
  isMissing: boolean
  missingComponentTypes?: string[]
  addressLines?: string[]
}) => {
  if (effectiveComponentType === effectiveValidationAddressComponents.subpremise) {
    const line = addressLines

    if (isMissing) {
      if (line?.[1] !== undefined && line[1] !== "") {
        return effectivesAddressFormFieldsMapping["subpremise"]
      }

      if (
        line?.[0] !== undefined &&
        line[0] !== "" &&
        missingComponentTypes?.filter(
          (type) =>
            type === effectiveValidationAddressComponents.route ||
            type === effectiveValidationAddressComponents.street_number,
        ).length
      ) {
        return effectivesAddressFormFieldsMapping["line"]
      }
    }
  }

  return effectiveComponentType === "route" || effectiveComponentType === "street_number"
    ? effectivesAddressFormFieldsMapping["line"]
    : effectivesAddressFormFieldsMapping[effectiveComponentType as EffectiveComponentType]
}

const normalizeComponentType = (componentType: string) => {
  const standardComponentType =
    componentType === effectiveValidationAddressComponents.postal_code
      ? "zip code"
      : componentType === effectiveValidationAddressComponents.locality
        ? "city"
        : componentType === effectiveValidationAddressComponents.administrative_area
          ? "state"
          : componentType === effectiveValidationAddressComponents.subpremise
            ? "line 2"
            : componentType
  const noUnderscores = standardComponentType.replace(/_/g, " ")

  const separatedCamelCase = noUnderscores.replace(/([a-z])([A-Z])/g, "$1 $2")

  return separatedCamelCase.toLowerCase()
}

const getNeededToConfirmedFields = (
  addressComponents: AddressValidationDataAddress["addressComponents"],
  missingComponentTypes?: string[],
  currentAddressLines?: string[],
) => {
  let neededToFixTypes: {
    fixable: {
      componentType: string
      value: string
    }[]
    wrong: string[]
    missing: string[]
  } = { fixable: [], wrong: [], missing: [] }

  const neededToConfirmMissingAddressComponents =
    missingComponentTypes?.reduce((prev, componentType) => {
      const { field, message } = getErrorField({
        effectiveComponentType: componentType,
        isMissing: true,
        missingComponentTypes,
        addressLines: currentAddressLines,
      })
      neededToFixTypes = {
        ...neededToFixTypes,
        missing: [...neededToFixTypes.missing, normalizeComponentType(componentType)],
      }
      return { ...prev, [field]: message }
    }, {} as FormikErrors<FormikValues>) ?? {}

  const neededToConfirmAddressComponents =
    addressComponents?.reduce((prev, component) => {
      const fixableButIncorrect = component?.inferred || component?.replaced || component?.spellCorrected
      if (fixableButIncorrect || component?.confirmationLevel.includes("UNCONFIRMED")) {
        const { field, message } = getErrorField({
          effectiveComponentType: component.componentType,
          isMissing: false,
          missingComponentTypes,
          addressLines: currentAddressLines,
        })
        neededToFixTypes = {
          ...neededToFixTypes,
          ...(fixableButIncorrect && {
            fixable: [
              ...(neededToFixTypes?.fixable ?? []),
              {
                componentType: normalizeComponentType(component.componentType),
                value: component?.componentName?.text as string,
              },
            ],
          }),
          wrong: [...neededToFixTypes.wrong, normalizeComponentType(component.componentType)],
        }
        return { ...prev, [field]: message }
      }

      return prev
    }, {} as FormikErrors<FormikValues>) ?? {}

  return {
    errors: { ...neededToConfirmAddressComponents, ...neededToConfirmMissingAddressComponents },
    neededToFixTypes: neededToFixTypes,
  }
}

const getFieldError = (fields: FieldErrorType[]): string => {
  const [key, value] = fields[0]
  if (typeof value === "string") return key

  if (Array.isArray(value)) {
    const index = value.findIndex((d) => d)
    return `${key}[${index}].${getFieldError(Object.entries(value[index]))}`
  }

  return `${key}.${getFieldError(Object.entries(value))}`
}

const isValidPractitioner = (practitionersInfo: PractitionerInfo[], practId?: string) =>
  practitionersInfo.some(
    ({ practitioner, practitionerRole }) =>
      practitioner.id === practId && practitioner.active !== false && practitionerRole?.active !== false,
  )

export {
  getFieldError,
  getNeededToConfirmedFields,
  getParamsSkuCodesByQty,
  getPDSkuValue,
  getResultAbstractDataRepresentation,
  isAcceptedAddress,
  isNeededToConfirmAddress,
  isNeededToFixAddress,
  isValidPractitioner,
}
