import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { DocumentReference } from "fhir"
import { patientsQueryKeys } from "../query-keys"

const useDeletePatientDocument = (onSettled?: () => void) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const deleteDocument = async (docId: string) =>
    patch("DocumentReference", docId, { status: "entered-in-error" } as Partial<DocumentReference>)

  const { mutate: removeDocument, isPending: isDeleting } = useMutation({
    mutationFn: deleteDocument,
    onError: (error: CustomError, docId) => {
      displayNotificationError(registerErrorTrace(error, { docId }))
    },
    onSuccess: async (data) => {
      queryClient.refetchQueries({ queryKey: patientsQueryKeys.documents.list(patientId) })
      displayNotificationSuccess("Document deleted successfully!")
      datadogLogs.logger.info("Document deleted successfully!", { data })
    },
    onSettled,
  })

  return { removeDocument, isDeleting }
}

export { useDeletePatientDocument }
