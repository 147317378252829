import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SettingItemArray } from "fhir"
import isEqual from "lodash/isEqual"
import { Button } from "primereact/button"
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

import { SkeletonLoader } from "commons"
import { organizationSettingId } from "data"
import { useOrganizationContext } from "organization"

import { useOverwriteSetting, useSettings, useUpdateSetting } from "../hooks"
import { settingsQueryKeys } from "../query-keys"
import { BooleanSetting } from "./BooleanSetting"
import { ConsentsSetting } from "./ConsentsSetting"
import { DefaultOrgData } from "./DefaultOrgData"
import { MedicationCatalogSetting } from "./meds/MedicationCatalogSetting"
import { OrderLabsWithLocationSetting } from "./OrderLabsSetting"
import { ThemeSettings } from "./ThemeSettings"

const SettingDetails: FC<Props> = ({ settingDefinitionId, label }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const [settings, setSettings] = useState<SettingItemArray[]>([])
  const [settingModifiedCount, setSettingModifiedCount] = useState(0)

  const { settingId, isLoading, items, isOverwritten, settingDescription, readOnly } = useSettings(
    currentOrganizationId,
    settingDefinitionId,
  )

  const getSettingUpdatedQueryKey = useMemo(() => {
    switch (settingDefinitionId) {
      case organizationSettingId.ehrModules:
        return settingsQueryKeys.modulesVisibility(currentOrganizationId)
      case organizationSettingId.branding:
        return settingsQueryKeys.currentBranding(currentOrganizationId)
      case organizationSettingId.paymentMethods:
        return settingsQueryKeys.paymentMethods(currentOrganizationId)
      case organizationSettingId.labFacilities:
        return settingsQueryKeys.labFacilities(currentOrganizationId)
      default:
        return undefined
    }
  }, [settingDefinitionId, currentOrganizationId])

  const { updateSetting, isUpdating } = useUpdateSetting(getSettingUpdatedQueryKey)
  const { overwriteSetting, isOverwriting } = useOverwriteSetting()

  useEffect(() => {
    if (!isEqual(settings, items)) {
      setSettings(items)
      setSettingModifiedCount(0)
    }
  }, [items])

  const onChangeSetting = useCallback(
    (code: string, item: SettingItemArray) => {
      const index = settings.findIndex((setting) => setting.code?.code === code)
      const changedSettings = [
        ...settings.slice(0, index),
        { ...settings[index], value: item.value, flag: item.flag },
        ...settings.slice(index + 1),
      ]
      setSettings(changedSettings)

      if (!isEqual(items[index], item)) {
        setSettingModifiedCount(settingModifiedCount + 1)
      } else {
        setSettingModifiedCount(settingModifiedCount ? settingModifiedCount - 1 : 0)
      }
    },
    [items, settingModifiedCount, settings],
  )

  const onUpdate = () => {
    if (!isOverwritten) {
      confirmDialog({
        message: "Are you sure you want to overwrite default settings?",
        header: "Confirmation",
        acceptLabel: "Accept",
        rejectLabel: "Cancel",
        rejectClassName: "button-default p-button-sm",
        acceptClassName: "button-primary p-button-sm",
        accept: () => overwriteSetting({ settingDefinitionId, organizationId: currentOrganizationId, items: settings }),
      })
    } else {
      updateSetting({ settingId: settingId as string, items: settings })
    }
    setSettingModifiedCount(0)
  }

  const renderSettingDetails = useCallback(() => {
    switch (settingDefinitionId) {
      case organizationSettingId.branding:
        return <ThemeSettings settings={settings} readOnly={readOnly} />
      case organizationSettingId.defaultOrgdata:
        return <DefaultOrgData settings={settings} />
      case organizationSettingId.medicationsCatalogs:
        return settings.map((setting) => (
          <MedicationCatalogSetting
            key={setting.code?.code}
            setting={setting}
            isDisabled={!isOverwritten}
            onChange={onChangeSetting}
          />
        ))
      case organizationSettingId.consents:
        return settings.map((setting) => (
          <ConsentsSetting
            key={setting.code?.code}
            setting={setting}
            isDisabled={!isOverwritten}
            onChange={onChangeSetting}
          />
        ))
      case organizationSettingId.labFacilities:
        return settings.map((setting) => (
          <OrderLabsWithLocationSetting
            key={setting.code?.code}
            setting={setting}
            isDisabled={!isOverwritten}
            onChange={onChangeSetting}
          />
        ))
      default:
        return settings.map((setting) => (
          <BooleanSetting
            key={setting.code?.code}
            setting={setting}
            isDisabled={!isOverwritten}
            onChange={onChangeSetting}
          />
        ))
    }
  }, [isOverwritten, onChangeSetting, readOnly, settingDefinitionId, settings])

  return (
    <div className="flex flex-col h-full relative">
      <div className="flex items-start mb-4 sticky top-0 z-50 bg-white">
        <div className="flex-1">
          <div className="text-lg w-full px-5 pb-1 ">{label}</div>
          <div className="text-sm w-full px-5 text-gray-400 ">{!isLoading && settingDescription} </div>
        </div>
        {!readOnly && (
          <Button
            label={!isLoading && !isOverwritten ? "Overwrite" : "Save"}
            className="mr-3 p-button-sm button-primary"
            onClick={onUpdate}
            disabled={isLoading || !items || items.length === 0 || (!!isOverwritten && settingModifiedCount === 0)}
            loading={isUpdating || isOverwriting}
          />
        )}
      </div>
      <div className="pl-6 mb-16">
        {isLoading ? (
          <SkeletonLoader repeats={4} loaderType="one-line" />
        ) : items.length > 0 ? (
          <div className="overflow-auto">{renderSettingDetails()}</div>
        ) : (
          <div className="flex flex-col items-center justify-center pt-20">
            <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
            <p className="text-slate-400 pt-3">No settings found</p>
          </div>
        )}
      </div>
      <ConfirmDialog />
    </div>
  )
}

type Props = {
  settingDefinitionId: string
  label: string
}

export { SettingDetails }
