import { addDays } from "date-fns/addDays"
import { startOfDay } from "date-fns/startOfDay"
import { Reference } from "fhir"
import { useFormikContext } from "formik"
import { FC, useMemo } from "react"

import { HEALTH_GORILLA_VALUE_CHECK } from "data"
import { SYSTEM_VALUES } from "system-values"

import { PractitionerRoleDropdownField } from "../../../../forms"
import { DateField } from "../../../../forms/DateField"
import { PractitionerInfo } from "../../../../types"
import { PlanData } from "../../../types"
import { validatePlanRequester } from "../../validations"

const Appointment: FC<Props> = ({ practitionersInfo, configs: { requiresLabs, requiresRXs } }) => {
  const {
    values: { mailTasks, appointment, daysBeforeToMail },
    setFieldValue,
  } = useFormikContext<Partial<PlanData>>()

  const allowedPractitioners = useMemo(
    () =>
      practitionersInfo.filter(
        ({ practitioner: { identifier }, practitionerRole }) =>
          (requiresLabs ? identifier?.some(({ value }) => value?.includes(HEALTH_GORILLA_VALUE_CHECK)) : true) &&
          (requiresRXs
            ? practitionerRole?.identifier?.some(({ system }) => system === SYSTEM_VALUES.LIFEFILE_PRACTITIONER)
            : true),
      ),
    [practitionersInfo, requiresLabs, requiresRXs],
  )

  const onAppointmentChange = (value?: Date) => {
    if (value && mailTasks)
      Object.values(mailTasks)
        ?.filter(({ showable, editable }) => !!showable && !!editable)
        .forEach((task) => {
          let date = value && addDays(startOfDay(new Date(value)), -(task.daysBeforeToMail ?? daysBeforeToMail ?? 0))
          date = date >= new Date() ? date : new Date()
          setFieldValue(`mailTasks.${task.taskId}.restriction.period.start`, date.toISOString())
        })
  }

  return (
    <>
      <PractitionerRoleDropdownField
        field="requester"
        label="Practitioner"
        useFilter={false}
        options={allowedPractitioners}
        validate={(req: Reference | undefined) =>
          validatePlanRequester({
            value: req,
            practitionersInfo: allowedPractitioners,
            requiresLabs,
            requiresRX: requiresRXs,
            isRequired: true,
          })
        }
        horizontal
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium"
      />
      {appointment?.id && (
        <DateField
          label="Appointment date"
          field="appointment.start"
          validation={(value) => !value && "Appointment date is required"}
          minDate={new Date()}
          showTime
          onChange={onAppointmentChange}
          className="pb-2"
          horizontal
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium"
        />
      )}
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  configs: {
    requiresAlgorithm: boolean
    requiresLabs: boolean
    requiresEmails: boolean
    requiresNutras: boolean
    requiresRXs: boolean
  }
}

export { Appointment }
